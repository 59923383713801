import {Box, Checkbox as MatCheckbox, FormControlLabel, FormGroup, Typography, useTheme} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Checkbox, Panes, TextField} from '@raise.digital/management-ui';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function Fields(
  {
    product = {},
    prefix = '',
    multiplePanes = true,
    helpText,
    onHelpTextChange,
    categories,
    onCategoryChange,
    children
  }
) {
  const theme = useTheme();
  const [helpTextState, setHelpTextState] = useState(EditorState.createEmpty());
  const helpTextRef = useRef('{}');

  useEffect(() => {
    if (helpText && helpText !== helpTextRef.current) {
      helpTextRef.current = helpText;
      let newState;
      try {
        newState = EditorState.createWithContent(convertFromRaw(JSON.parse(helpText)));
      } catch (e) {
        newState = EditorState.createEmpty();
      }
      if (helpTextState.getCurrentContent() !== newState.getCurrentContent()) {
        setHelpTextState(newState);
      }
    }
  }, [helpText, helpTextState]);

  const handleChange = useCallback((state) => {
    const newContent = state.getCurrentContent();
    if (newContent !== helpTextState.getCurrentContent()) {
      const raw = convertToRaw(newContent);
      const serialisedRaw = JSON.stringify(raw);
      helpTextRef.current = serialisedRaw;
      onHelpTextChange(serialisedRaw);
    }
    setHelpTextState(state);
  }, [helpTextState, onHelpTextChange]);

  return (
    <Panes
      entity={product}
      prefix={prefix}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="description"
              prefix={prefix}
              label="Description"
              fieldProps={{multiline: true, rows: 3}}
            />,
            <Box>
              <Box padding={1}>
                <Typography variant="body2">Help Text</Typography>
              </Box>
              <Editor
                editorState={helpTextState}
                onEditorStateChange={handleChange}
                toolbar={{options: ['fontSize', 'inline', 'list', 'textAlign', 'remove', 'history']}}
                wrapperStyle={{
                  border: `1px solid ${theme.palette.action.disabled}`,
                  borderRadius: theme.shape.borderRadius,
                }}
                toolbarStyle={{
                  border: 0,
                }}
                editorStyle={{
                  padding: `0 ${theme.spacing(2)}px`,
                }}
              />
            </Box>,
          ]
        },
        {
          title: 'Settings',
          fields: [
            <Box padding={1} marginTop={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Categories</FormLabel>
                <FormGroup>
                  {(categories ?? []).map((category, index) => (
                    <FormControlLabel
                      key={index}
                      control={<MatCheckbox checked={category.selected} onChange={(event) => onCategoryChange(category, event.target.checked)} name={`category${category.value}`} />}
                      label={category.title}
                    />))}
                </FormGroup>
              </FormControl>
            </Box>,
            <Checkbox
              name="unavailable"
              prefix={prefix}
              label="Should this product be marked as unavailable?"
            />,
            <Checkbox
              name="requiresApproval"
              prefix={prefix}
              label="Requires Approval?"
            />,
            <Checkbox
              name="onePerOrder"
              prefix={prefix}
              label="One Per Order?"
            />,
            <Checkbox
              name="canReOrder"
              prefix={prefix}
              label="Can Re-order?"
            />,
            <Checkbox
              name="isHeld"
              prefix={prefix}
              label="Is this product held before being produced?"
            />,
            <Checkbox
              name="exportTab"
              prefix={prefix}
              label="Should this product have its own tab in the order export?"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Product Details'}
    >{children}</Panes>
  );
}
