import Paper from '@material-ui/core/Paper';
import {Breadcrumbs} from '@raise.digital/management-ui';
import * as React from 'react';
import Table from './Table';

const Users = () => (
  <>
    <Breadcrumbs crumbs={[{title: 'Administrators'}]}/>
    <Paper>
      <Table title="Administrators" administrators={true}/>
    </Paper>
  </>
);

export default Users;
