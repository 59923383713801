import {Box, Checkbox as MatCheckbox, FormControlLabel, FormGroup} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Panes, TextField} from '@raise.digital/management-ui';
import React from 'react';

export default function Fields(
  {
    location = {},
    prefix = '',
    multiplePanes = true,
    categories,
    onCategoryChange,
    children
  }
) {
  return (
    <Panes
      entity={location}
      prefix={prefix}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Phone"
            />,
            <Box padding={1} marginTop={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Categories</FormLabel>
                <FormGroup>
                  {(categories ?? []).map((category, index) => (
                    <FormControlLabel
                      key={index}
                      control={<MatCheckbox checked={category.selected} onChange={(event) => onCategoryChange(category, event.target.checked)} name={`category${category.value}`} />}
                      label={category.title}
                    />))}
                </FormGroup>
              </FormControl>
            </Box>,
          ]
        },
        {
          title: 'Address',
          fields: [
            <TextField
              name="address"
              prefix={prefix}
              label="Address"
              fieldProps={{multiline: true, rows: 5}}
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Location Details'}
    >{children}</Panes>
  );
}
