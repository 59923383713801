import React, {useCallback, useContext, useEffect, useState} from 'react';
import routes from '../../routes';
import zebraLogo from '../../assets/logo.svg'
import {Access as UIAccess} from '@raise.digital/management-ui';
import {ServiceContext} from '../../components/Services';
import {useDispatch, useSelector} from 'react-redux';
import {login, resetPassword} from '../../store/actions/auth';
import PortalAccess from './PortalAccess';

export default function Access(props) {
  const services = useContext(ServiceContext);
  const dispatch = useDispatch();
  const config = useSelector(state => state['general'].config);
  const [accessProps, setAccessProps] = useState({});

  const handleLogin = useCallback((email, password) => {
    return dispatch(login(email, password));
  }, [dispatch])

  const handleForgotPassword = useCallback((email) => {
    return services.auth.forgotPassword(email, `${window.location.origin}${routes.resetPassword}?token={TOKEN}`)
  }, [services]);

  const handleResetPassword = useCallback((email, password, token) => {
    return dispatch(resetPassword(email, password, token))
  }, [dispatch]);

  useEffect(() => {
    setAccessProps({
      title: config.name,
      logo: !!config.logo ? `data:image/svg+xml;base64,${Buffer.from(
        config.logo,
        'binary',
      ).toString('base64')}` : zebraLogo,
      company: config.name,
      routes: {login: routes.login, forgotPassword: routes.forgotPassword, resetPassword: routes.resetPassword},
      actions: {
        onLogin: handleLogin,
        onForgotPassword: handleForgotPassword,
        onResetPassword: handleResetPassword
      }
    });
  }, [config, handleLogin, handleForgotPassword, handleResetPassword]);

  return Object.keys(accessProps).length > 0 ? (
    config.default ? <UIAccess {...accessProps} {...props} /> : <PortalAccess {...accessProps} {...props} />
  ) : null;
}
