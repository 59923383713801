import React, {useCallback, useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import * as moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ProductService} from '../../../services/ProductService';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {reverse} from 'named-urls';
import routes from '../../../routes';
import {useHistory} from 'react-router-dom';
import Reference from './Reference';

const useStyles = makeStyles(theme => ({
  activity: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  loading: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,

    '&::before': {
      backgroundColor: theme.palette.background.paper,
      bottom: 0,
      content: '" "',
      left: 0,
      opacity: 0.8,
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  created: {
    marginLeft: theme.spacing(1)
  },
  status: {
    alignItems: 'center',
    display: 'flex',
  },
  correction: {
    color: theme.palette.secondary.main,
  },
  received: {
    color: theme.palette.success.main,
  },
  sent: {
    color: theme.palette.warning.main,
  },
}));

export default function ProductActivity({product, loading}) {
  const classes = useStyles();
  const [errorOpen, setErrorOpen] = useState(false);
  /** @type {({current: MaterialTable})} */
  let activityTableRef = useRef();
  const history = useHistory();
  const initialised = useRef(false);

  useEffect(() => {
    if (product) {
      if (initialised.current) {
        activityTableRef.current && activityTableRef.current.onQueryChange(null)
      } else {
        initialised.current = true;
      }
    }
  }, [product]);

  const selectEntry = useCallback((selected) => {
    if (selected.orderItem) {
      history.push(reverse(routes.admin.orders.detail, { id: selected.orderItem.orderId }))
    }
  }, [history]);

  let loadingOverlay = null
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress/></div>;
  }

  return (
    <div>
      <Paper className={classes.activity}>
        <MaterialTable
          title="Stock Activity"
          tableRef={activityTableRef}
          options={{
            debounceInterval: 500,
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [50, 100, 250],
            search: false,
          }}
          components={{Container: props => <Paper elevation={0} {...props} />}}
          columns={[
            {
              title: 'Date/Time',
              field: 'created',
              render: data => {
                return <div className={classes.status}>
                  {data.quantity > 0 ? <CallReceivedIcon className={data.correction ? classes.correction : classes.received} /> : <CallMadeIcon className={data.correction ? classes.correction : classes.sent} />}
                  <span className={classes.created}>{moment(data.created).format('DD/MM/YYYY HH:mm')}</span>
                </div>
              },
              sorting: false
            },
            {title: 'Version', field: 'version', render: data => data.productVersion.code, sorting: false},
            {title: 'User', field: 'user', render: data => `${data.user.firstName} ${data.user.lastName}`, sorting: false},
            {title: 'Quantity', field: 'amount', render: data => Math.abs(data.quantity), sorting: false},
            {title: 'Comments', field: 'comments', sorting: false},
            {title: 'Job Number', field: 'order', render: data => data.orderItem && data.orderItem.reference ? <Reference reference={data.orderItem.reference}/> : '-', sorting: false}
          ]}
          data={query => new Promise(resolve => {
            ProductService.getInstance().getTableActivity(product, query).then(response => {
              resolve({
                data: response.items, page: response.page - 1, totalCount: response.total
              });
            }).catch(() => {
              setErrorOpen(true);
              resolve({data: [], page: 0, totalCount: 0});
            })
          })}
          onRowClick={(event, rowData) => selectEntry(rowData)}
        />
        {loadingOverlay}
      </Paper>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>
    </div>
  );
}
