import {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ServiceContext} from './components/Services';
import {setOrder} from './store/actions/orders';
import {OrderService} from './services/OrderService';

export function usePermissions(permissions = []) {
  const [permitted, setPermitted] = useState(false);
  const user = useSelector((state) => state['auth'].user);
  useEffect(() => {
    if (user) {
      if (
        user.role.permissions
          .map((l) => l.permission.code)
          .filter((code) => permissions.indexOf(code) >= 0).length ===
        permissions.length
      ) {
        setPermitted(true);
      } else {
        setPermitted(false);
      }
    } else {
      setPermitted(false);
    }
  }, [user, permissions]);
  return permitted;
}

export function useOrder(match, loading, onLoading, onError) {
  const order = useSelector((state) => state['orders'].selected);
  const dispatch = useDispatch();

  const loadOrder = useCallback(() => {
    if (match.params && match.params.id) {
      const id = parseInt(match.params.id);
      if (!loading && (!order || order.id !== id)) {
        dispatch(setOrder(null));
        onLoading(true);
        OrderService.getInstance()
          .getOrder(parseInt(match.params.id))
          .then((retrieved) => {
            dispatch(setOrder(retrieved));
            onLoading(false);
          })
          .catch(() => {
            onLoading(false);
            onError(true);
          });
      }
    }
  }, [order, loading, match.params, dispatch, onLoading, onError]);

  useEffect(loadOrder, [order]);

  return order;
}

export function useCustomerCategories(customer, entity) {
  const services = useContext(ServiceContext);
  const [customerCategories, setCustomerCategories] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    services.customer.getCategories({filter: {customerId: customer.id}})
      .then((response) => setCustomerCategories(response.items))
      .catch(() => setCustomerCategories(null));
  }, [services, customer]);

  useEffect(() => {
    if (customerCategories !== null) {
      setCategories(customerCategories.map(c => ({
        title: c.name,
        value: c.id,
        selected: (entity?.categories ?? []).findIndex(link => link.id === c.id) > -1
      })));
    }
  }, [customerCategories, entity]);

  const handleCategorySelect = useCallback((category, selected) => {
    const updated = [...categories];
    updated.find(c => c.value === category.value).selected = selected;
    setCategories(updated)
  }, [categories]);

  return {categories, onSelectCategory: handleCategorySelect};
}