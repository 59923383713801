import {Checkbox, Panes, prefixWithSeparator, Select, TextField} from '@raise.digital/management-ui';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import Conditions from './Conditions';
import Options from './Options';

export default function Fields(
  {
    field = {},
    product,
    prefix = '',
    multiplePanes = true,
    options,
    onUpdateOptions,
    conditions,
    onUpdateConditions,
    children
  }
) {
  const {/** @var {function(string): string} */watch} = useFormContext();
  const type = watch(`${prefixWithSeparator(prefix)}fieldType`);

  return (
    <Panes
      entity={field}
      prefix={prefix}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <Select
              name="fieldType"
              prefix={prefix}
              label="Type"
              id="fieldType"
              rules={{required: 'Please select a type'}}
              options={[
                {title: 'Short Text', value: 'Short Text'},
                {title: 'Long Text', value: 'Long Text'},
                {title: 'Dropdown', value: 'Dropdown'},
                {title: 'Address', value: 'Address'},
                {title: 'UK Telephone Number', value: 'UK Telephone Number'}
              ]}
            />,
            ...(type === 'Dropdown' ? [<Options options={options} onUpdate={onUpdateOptions}/>] : [])
          ]
        },
        {
          title: 'Settings',
          fields: [
            <Select
              name="formatter"
              prefix={prefix}
              label="Formatter"
              id="formatter"
              options={[
                {title: 'Name', value: 'Name'},
                {title: 'Email Address', value: 'Email Address'},
                {title: 'UK Telephone Number', value: 'UK Telephone Number'},
              ]}
            />,
            <TextField
              name="defaultValue"
              prefix={prefix}
              label="Default Value"
            />,
            <TextField
              name="suffix"
              prefix={prefix}
              label="Suffix"
            />,
            <Checkbox
              name="restricted"
              prefix={prefix}
              label="Restricted Field?"
            />,
            <Conditions
              product={product}
              field={field}
              conditions={conditions}
              onUpdate={onUpdateConditions}
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Field Details'}
    >{children}</Panes>
  );
}
