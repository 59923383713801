export default {
  // Access
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',

  // Admin
  admin: {
    dashboard: '/',
    customers: {
      index: '/customers',
      detail: '/customers/:id',
      portal: {
        index: '/customers/:id/portal',
        location: '/customers/:customerID/portal/locations/:id',
        product: '/customers/:customerID/portal/products/:id',
        category: '/customers/:customerID/portal/categories/:id',
        orders: {
          index: '/customers/:id/portal/orders',
          dispatch: '/customers/:id/portal/orders/dispatch',
          detail: '/customers/:customerID/portal/orders/:id',
        },
        messages: '/customers/:id/portal/messages',
        passwordReminders: '/customers/:id/portal/password-reminders',
      },
    },
    locations: {
      index: '/locations',
      detail: '/locations/:id',
    },
    products: {
      index: '/products',
      archive: '/products/archive',
      detail: '/products/:id',
      orders: '/products/:id/orders',
    },
    orders: {
      index: '/orders',
      new: '/orders/new',
      detail: '/orders/:id',
      update: '/orders/:id/update',
      changes: '/orders/:id/changes',
    },
    administrators: {
      index: '/administrators',
      roles: '/administrators/roles',
    },
    settings: '/settings',
  },

  // Customer
  customer: {
    home: '/',
    order: {
      index: '/order',
      product: '/order/product/:id',
      line: '/order/product/:id/:index',
    },
    history: {
      index: '/orders',
      detail: '/orders/:id',
    },
    support: '/support',
  },
};
