import {DataTable, dateTime} from '@raise.digital/management-ui';
import {MTableToolbar} from 'material-table';
import moment from 'moment';
import {reverse} from 'named-urls';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Status from '../../../../components/Status';
import routes from '../../../../routes';
import Filters from './Filters';

const Table = (
  {
    title = '',
    onGoToDetail = () => {
    },
    status = null
  }
) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const filters = useRef({
    from: moment().subtract(1, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    status: ''
  });
  const user = useSelector(state => state['auth'].user);
  const locations = useSelector(state => state['customer'].locations);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    services.portal.getPersonalisedProducts().then(setProducts).catch(() => setProducts([]));
  }, [services]);

  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const goToDetail = useCallback((order) => {
    onGoToDetail();
    history.push(reverse(routes.customer.history.detail, {id: order.id}));
  }, [history, onGoToDetail]);

  const handleFilters = useCallback((updated) => {
    filters.current = updated;
    tableRef.current.refresh();
  }, []);

  return (
    <DataTable
      ref={tableRef}
      title={title}
      options={{search: false, draggable: false}}
      actions={{
        before: status || user.location ? [] : [
          {
            icon: 'download',
            tooltip: 'Export Orders',
            onClick: () => {
              services.portal.export(filters.current).then(() => {
              }).catch(() => {
              });
            },
            isFreeAction: true,
          }
        ]
      }}
      components={{
        Toolbar: props => (
          <div>
            <MTableToolbar {...props} />
            {status || user.location != null ? null : (
              <Filters
                initial={filters.current}
                onRefresh={handleFilters}
                locations={locations}
                products={products}
              />
            )}
          </div>
        )
      }}
      columns={[
        {title: 'Reference', field: 'reference', sorting: false},
        {title: 'Status', field: 'status', sorting: false, render: data => <Status status={data.status}/>},
        {
          title: 'Ordered by', field: 'user', render: data => (
            <>
              <strong>{data.order.orderedBy.name} - {data.order.orderedBy.email}</strong><br/>
              {`${data.order.user.firstName} ${data.order.user.lastName}`}
            </>
          ), sorting: false
        },
        {title: 'Item', field: 'item', render: data => data.name, sorting: false},
        {
          title: 'Location',
          field: 'location',
          render: data => data.order.location ? data.order.location.name : '-',
          sorting: false
        },
        {title: 'Date Ordered', field: 'created', render: data => dateTime(data.created), sorting: false}
      ]}
      onRowClick={goToDetail}
      loadData={query => new Promise((resolve, reject) => {
        let additional = {...filters.current};
        if (user.location != null) {
          additional = {
            ...additional,
            from: moment().subtract(4, 'weeks').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          }
        }
        if (status === 'Active Orders') {
          additional = {
            status,
            from: moment().subtract(2, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          }
        }
        services.portal.getTableOrders(query, additional)
          .then(response => {
            resolve({
              data: response.items, page: response.page - 1, totalCount: response.total
            });
          }).catch(() => {
          reject();
        });
      })}
    />
  );
};

Table.propTypes = {
  title: PropTypes.string,
  status: PropTypes.object
};

export default Table;
