import {Panes, TextField} from '@raise.digital/management-ui';
import validator from 'email-validator';
import React from 'react';

const Fields = ({message = {}, prefix = '', multiplePanes = true, children}) => (
  <Panes
    entity={message}
    prefix={prefix}
    panes={[
      {
        title: 'Your Details',
        fields: [
          <TextField
            name="name"
            prefix={prefix}
            label="Your Name"
            rules={{required: 'Please enter your name'}}
          />,
          <TextField
            name="phone"
            prefix={prefix}
            label="Your Telephone Number"
          />,
          <TextField
            name="email"
            prefix={prefix}
            label="Your Email Address"
            rules={{
              validate: value => {
                if (value) {
                  return validator.validate(value) ? true : 'Please enter a valid email address';
                }
                return true;
              }
            }}
            fieldProps={{autoComplete: 'email'}}
          />
        ]
      }, {
        title: 'Your Message',
        fields: [
          <TextField
            name="message"
            prefix={prefix}
            label="What are you having problems with or are unsure about?"
            rules={{required: 'Please provide detail of your query'}}
            fieldProps={{multiline: true, rows: 6}}
          />,
        ]
      }
    ]}
    title={multiplePanes ? null : 'Help & Support'}
  >{children}</Panes>
);

export default Fields;