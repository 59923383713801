import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {ProductService} from '../../../services/ProductService';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {CircularProgress} from '@material-ui/core';
import useFormStyles from './styles';
import ProductField from './ProductField';

const useStyles = makeStyles(theme => ({
  labels: {
    display: 'flex',
    flexDirection: 'column',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  row: {
    backgroundColor: theme.palette.grey['100'],
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(),
    padding: theme.spacing(),
  },
  name: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  remove: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flex: '0 0 30px',
    height: 30,
    justifyContent: 'center',
    width: 30,
  },
}));

const ProductLabelsForm = forwardRef(({onGenerated}, ref) => {
  const classes = useFormStyles();
  const labelClasses = useStyles();
  const [products, setProducts] = useState([]);
  /** @type {({current: RelationAutocomplete})} */
  const productRef = useRef();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const handleSelect = selected => {
    setProducts([...products, selected]);
    if (productRef.current) {
      productRef.current.clear();
    }
  };

  const handleRemove = index => {
    const updated = [...products];
    updated.splice(index, 1);
    setProducts(updated);
  }

  useImperativeHandle(ref, () => ({
    generateLabels(all = false) {
      setLoading(true);
      ProductService.getInstance().generateLabels(all ? [] : products.map(p => p.value)).then(() => {
        setLoading(false);
        onGenerated();
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
      });
    }
  }));

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress/></div>;
  }

  return (
    <div className={labelClasses.labels}>
      {loadingOverlay}
      <div className={labelClasses.rows}>
        {products.map((product, index) => (
          <div className={labelClasses.row} key={index}>
            <span className={labelClasses.name}>{product.name}</span>
            <span className={labelClasses.remove} onClick={() => handleRemove(index)}><ClearIcon/></span>
          </div>
        ))}
      </div>
      <ProductField
        id="product"
        fieldRef={productRef}
        required={true}
        label="Product"
        onChange={handleSelect}
      />
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem generating the labels
        </Alert>
      </Snackbar>
    </div>
  );
});

export default ProductLabelsForm;
