import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import {
  useErrors,
  useDetail,
  DetailPane,
  FormDialog,
  DetailColumns,
  useDialogs,
  dateTime
} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useMemo, useContext, useCallback} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import CustomerLocationForm from '../../../forms/CustomerLocationForm';
import Table from '../../Users/Table';
import Wrapper from '../Wrapper';

export default function Location() {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({location: 'Sorry the location could not be accessed'}), []));

  const {
    entity: location,
    setEntity: setLocation,
    loadEntity: loadLocation,
    crumbs,
    loading
  } = useDetail(
    services.customer.getLocation,
    false,
    useCallback((location) => [
      {
        title: location.customer.name,
        link: reverse(routes.admin.customers.detail, {id: location.customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: location.customer.id})
      },
      {title: location.name}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('location', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setLocation(updated);
    } else if (location?.id) {
      loadLocation(location.id);
    }
  }, [toggleDialog, setLocation, loadLocation, location]);

  return location ? (
    <Wrapper title={location.name} loading={loading} customer={location.customer} crumbs={crumbs} errors={errors}>
      <DetailColumns columns={[

        <DetailPane
          title="Location Details"
          actions={[
            {title: 'Edit Location', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)}
          ]}
          details={[
            {title: 'Name', value: location.name},
            {title: 'Phone', value: location.phone ?? '-'},
            {
              title: 'Address',
              value: location.address ? location.address.split('\n').map((part, index) => (
                <span key={index}>{index !== 0 ? <br/> : null}{part}</span>
              )) : '-'
            },
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Edit Location"
                open={openDialogs.edit ?? false}
                onClose={() => toggleDialog('edit', false)}
                render={(props) => (
                  <CustomerLocationForm
                    {...props}
                    customer={location.customer}
                    location={location}
                    onSaved={handleSaved}/>
                )}
              />
            )
          ]}
        />,

        <DetailPane
          title="Admin Details"
          details={[
            {title: 'Categories', value: location.categories?.length ? location.categories.map(c => c.name).join(', ') : '-'},
            {title: 'Created', value: dateTime(location.created)},
            {title: 'Last Updated', value: dateTime(location.updated)}
          ]}
        />
      ]}/>

      <Paper marginTop={2} component={Box}>
        <Table title="Users" location={location}/>
      </Paper>

    </Wrapper>
  ) : null;
}
