import {Toolbar} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import routes from '../../../routes';
import {LocationService} from '../../../services/LocationService';
import {setLocation} from '../../../store/actions/locations';
import ProductsTable from '../components/ProductsTable';
import LocationForm from '../forms/LocationForm';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  details: {
    marginTop: theme.spacing(1)
  },
  detailsColumn: {
    display: 'flex',
  },
  detailsPane: {
    flexGrow: 1,
  },
  locations: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
  imagesHeader: {
    color: theme.palette.action.active,
    margin: theme.spacing(2),
  },
  images: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  imageUpload: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    width: '25%',
  },
}));

export default function Location({match}) {
  const classes = useStyles();
  /** @type {({current: LocationForm})} */
  let formRef = useRef();

  /** @type {ILocation} */
  const location = useSelector(state => state.locations.selected);

  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const dispatch = useDispatch();

  const loadLocation = useCallback(() => {
    const id = parseInt(match.params.id);
    if (!loading && (!location || location.id !== id)) {
      dispatch(setLocation(null));
      setLoading(true);
      LocationService.getInstance().getLocation(parseInt(match.params.id)).then(retrieved => {
        dispatch(setLocation(retrieved));
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
      });
    }
  }, [location, loading, match.params, dispatch]);

  useEffect(loadLocation, [location]);

  const handleSave = () => {
    formRef.current.saveLocation();
  };
  const handleSaved = updated => {
    setEditOpen(false);
    dispatch(setLocation(updated));
  };

  let controls = null;
  if (loading) {
    controls = <CircularProgress/>;
  } else if (location) {
    controls = (
      <div>
        <IconButton color="inherit" edge="end" onClick={() => setEditOpen(true)}>
          <EditIcon/>
        </IconButton>
      </div>
    );
  }

  let details = null;
  let crumb = null;

  if (location) {
    crumb = <Typography color="textPrimary" variant="body2">{location.name}</Typography>;
    details = (
      <div>
        <Grid className={classes.details} container spacing={2}>
          <Grid className={classes.detailsColumn} item xs={6}>
            <Paper className={classes.detailsPane}>
              <List dense subheader={<ListSubheader>Location Details</ListSubheader>}>
                <ListItem>
                  <ListItemText
                    primary={location.name}
                    secondary="Name"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid className={classes.detailsColumn} item xs={6}>
            <Paper className={classes.detailsPane}>
              <List dense subheader={<ListSubheader>Admin Details</ListSubheader>}>
                <ListItem>
                  <ListItemText
                    primary={moment(location.created).format('DD/MM/YYYY HH:mm')}
                    secondary="Created"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={moment(location.updated).format('DD/MM/YYYY HH:mm')}
                    secondary="Last Updated"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
        <ProductsTable
          loadProducts={(query, resolve) => {
            LocationService.getInstance().getTableProducts(location, query)
              .then(response => {
                resolve({
                  data: response.items, page: response.page - 1, totalCount: response.total
                });
              }).catch(() => {
              setErrorOpen(true);
              resolve({data: [], page: 0, totalCount: 0});
            });
          }}
          loading={loading}
          setLoading={setLoading}
        />
        <Dialog open={editOpen} onClose={() => setEditOpen(false)} fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Edit Location</DialogTitle>
          <DialogContent>
            <LocationForm
              ref={formRef}
              new={false}
              onSaved={handleSaved}
              location={location}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="textPrimary" variant="body2"
                to={`${routes.admin.locations.index}`}>Locations</Link>
          {crumb}
        </Breadcrumbs>
      </Box>
      <Paper>
        <Toolbar>
          <Typography className={classes.title} component="h2" variant="h5">Location</Typography>
          {controls}
        </Toolbar>
      </Paper>
      {details}
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Sorry the location could not be accessed
        </Alert>
      </Snackbar>
    </div>
  );
}
