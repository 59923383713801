import {SET_CONFIG, SET_LOADING} from '../reducers/general';

export function setConfig(config) {
  return {
    type: SET_CONFIG,
    config,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading,
  };
}
