import {API} from './API';
import * as qs from 'qs';

export class CustomerService {
  static getInstance() {
    if (!CustomerService.instance) {
      CustomerService.instance = new CustomerService();
    }
    return CustomerService.instance;
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableCustomers(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getCustomers(query, page, pageSize);
  }

  async getCustomers(query, page, pageSize) {
    return (await API.getConnection())
      .get('customers', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getCustomer(id) {
    return (await API.getConnection())
      .get(`customers/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {ICustomer} customer
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableProducts(customer, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getProducts(customer, query, page, pageSize);
  }

  async getProducts(customer, query, page, pageSize) {
    return (await API.getConnection())
      .get(`customers/${customer.id}/products`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveCustomer(data) {
    let method = 'post';
    let url = 'customers';
    if (data.id) {
      method = 'put';
      url = `customers/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async logo(customer) {
    return (await API.getConnection())
      .get(`customers/${customer.id}/logo`, {responseType: 'arraybuffer'})
      .then((response) => {
        return `data:image/svg+xml;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  async icon(customer) {
    return (await API.getConnection())
      .get(`customers/${customer.id}/icon`, {responseType: 'arraybuffer'})
      .then((response) => {
        return `data:image/x-icon;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  async saveBranding(data) {
    const body = new FormData();
    Object.keys(data).forEach((field) => {
      if (data[field]) {
        body.append(field, data[field]);
      }
    });
    return (await API.getConnection())
      .post(`customers/${data.id}/branding`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableLocations(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getLocations(query, page, pageSize);
  }

  async getLocations(query, page, pageSize) {
    return (await API.getConnection())
      .get('customer-locations', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getLocation(id) {
    return (await API.getConnection())
      .get(`customer-locations/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveLocation(customer, data) {
    let method = 'post';
    let url = 'customer-locations';
    if (data.id) {
      method = 'put';
      url = `customer-locations/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, {...data, customerId: customer.id})
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async getPersonalisedProducts(customer) {
    return (await API.getConnection())
      .get('personalised', {
        params: {customerID: customer.id},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getPersonalisedProduct(id) {
    return (await API.getConnection())
      .get(`personalised/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async savePersonalisedProduct(customer, data) {
    let method = 'post';
    let url = 'personalised';
    if (data.id) {
      method = 'put';
      url = `personalised/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, {...data, customerId: customer.id})
      .then((response) => response.data)
      .catch(API.handleError);
  }

  async reorderPersonalisedProducts(customer, products) {
    return (await API.getConnection())
      .put(
        `personalised/reorder`,
        products.map((product, index) => ({id: product.id, index})),
        {
          params: {customerID: customer.id},
          paramsSerializer: (params) => qs.stringify(params),
        }
      )
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async deletePersonalisedProduct(product) {
    return (await API.getConnection())
      .delete(`personalised/${product.id}`)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async personalisedProductSample(product) {
    return (await API.getConnection())
      .get(`personalised/${product.id}/sample`, {responseType: 'arraybuffer'})
      .then((response) => {
        return `data:image/png;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  async uploadPersonalisedProductSample(product, file) {
    const body = new FormData();
    body.append('file', file);
    return (await API.getConnection())
      .post(`personalised/${product.id}/sample`, body, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return `data:image/jpeg;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  async savePersonalisedProductField(product, data) {
    let method = 'post';
    let url = `personalised/${product.id}`;
    let index = -1;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    } else {
      const ordered = product.fields.sort((a, b) =>
        a.index > b.index ? -1 : 1,
      );
      if (ordered.length > 0) {
        index = ordered[0].index + 1;
      } else {
        index = 0;
      }
    }
    return (await API.getConnection())
      [method](url, {
      ...data,
      productId: product.id,
      ...(index > -1 ? {index} : {}),
    })
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async reorderPersonalisedProductFields(product, fields) {
    return (await API.getConnection())
      .put(
        `personalised/${product.id}/reorder`,
        fields.map((field, index) => ({id: field.id, index})),
      )
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async deletePersonalisedProductField(product, field) {
    return (await API.getConnection())
      .delete(`personalised/${product.id}/${field.id}`)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableCategories(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getCategories(query, page, pageSize);
  }

  async getCategories(query, page, pageSize) {
    return (await API.getConnection())
      .get('customer-categories', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getCategory(id) {
    return (await API.getConnection())
      .get(`customer-categories/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveCategory(customer, data) {
    let method = 'post';
    let url = 'customer-categories';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, {...data, customerId: customer.id})
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {ICustomer} customer
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableMessages(customer, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getMessages(customer, query, page, pageSize);
  }

  async getMessages(customer, query, page, pageSize) {
    return (await API.getConnection())
      .get(`customers/${customer.id}/messages`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {ICustomer} customer
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableReminders(customer, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getReminders(customer, query, page, pageSize);
  }

  async getReminders(customer, query, page, pageSize) {
    return (await API.getConnection())
      .get(`customers/${customer.id}/password-reminders`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async toggleReminder(customer, reminder, approve = true) {
    return (await API.getConnection())
      .put(`customers/${customer.id}/password-reminders/${reminder.id}/${approve ? 'approve' : 'reject'}`)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }
}
