import MomentUtils from '@date-io/moment';
import {IconButton, InputLabel, ListSubheader, MenuItem, Select} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {makeStyles} from '@material-ui/core/styles';
import {Clear} from '@material-ui/icons';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

const Picker = ({classes, label, value, onChange}) => (
  <KeyboardDatePicker
    className={classes}
    fullWidth={true}
    autoOk
    variant="inline"
    inputVariant="outlined"
    format="DD/MM/YYYY"
    margin="normal"
    label={label}
    value={value}
    onChange={onChange}
    InputLabelProps={{shrink: true}}
  />
);

const Dropdown = ({id, classes, emptyLabel = '-------', label, value, onChange, options}) => (
  <FormControl variant="outlined" className={classes}>
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
    <Select
      labelId={`${id}-label`}
      id={id}
      value={value}
      onChange={e => onChange(e.target.value)}
      label={label}
    >
      <MenuItem value=""><strong>{emptyLabel}</strong></MenuItem>
      {options.map((option, index) => {
        if (option.value) {
          return <MenuItem key={index} value={option.value}>{option.title}</MenuItem>;
        } else {
          return <ListSubheader key={index}>{option.title}</ListSubheader>;
        }
      })}
    </Select>
  </FormControl>
);

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.default,
    display: 'flex',
    padding: `0 ${theme.spacing(1)}px`,
    width: '100%',
  },

  field: {
    flex: 1,
    padding: `${theme.spacing(1)}px`,
  },

  button: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(1)}px`,
  },

  picker: {
    backgroundColor: theme.palette.background.paper,
  },

  dropdown: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    width: '100%',
  }
}));

const Filters = ({initial, onRefresh, showAll, locations, products}) => {
  const classes = useStyles();

  const initialised = useRef(false);

  const [filters, setFilters] = useState({
    from: moment(initial.from),
    to: moment(initial.to),
    status: initial.status,
    location: '',
    product: ''
  });
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    const others = products.filter(p => !p.exportTab);
    setProductOptions([
      ...products.filter(p => !!p.exportTab),
      ...(others.length > 0 ? [{name: 'All Other Products'}] : [])
    ]);
  }, [products]);

  const statuses = useMemo(() => [
    {value: 'Active Orders'},
    {value: 'Complete Orders'},
    {title: 'Statuses'},
    {value: 'Pending'},
    {value: 'Processing'},
    {value: 'In Production'},
    {value: 'Dispatched'}
  ].map(s => {
    if (s.title) {
      return {title: s.title};
    } else {
      return {value: s.value, title: s.value};
    }
  }), []);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      return;
    }
    const formatted = {};
    if (filters.from) {
      formatted.from = filters.from.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    if (filters.to) {
      formatted.to = filters.to.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    if (filters.status) {
      formatted.status = filters.status;
    }
    if (filters.location) {
      formatted.location = filters.location;
    }
    if (filters.product) {
      formatted.product = filters.product;
    }
    onRefresh(formatted);
  }, [onRefresh, filters]);

  const handleUpdate = useCallback((updates) => {
    setFilters({...filters, ...updates});
  }, [filters]);

  const handleReset = useCallback(() => {
    setFilters({
      from: moment(initial.from),
      to: moment(initial.to),
      status: 'Active Orders',
      location: '',
      product: ''
    });
  }, [initial]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.container}>
        <div className={classes.field}>
          <Picker classes={classes.picker} label="From" value={filters.from} onChange={from => handleUpdate({from})}/>
        </div>
        <div className={classes.field}>
          <Picker classes={classes.picker} label="To" value={filters.to} onChange={to => handleUpdate({to})}/>
        </div>
        {!showAll ? null : (
          <>
            <div className={classes.field}>
              <Dropdown
                id="status"
                classes={classes.dropdown}
                emptyLabel="All Orders"
                label="Status"
                value={filters.status}
                onChange={status => handleUpdate({status})}
                options={statuses}
              />
            </div>
            {locations ? (
              <div className={classes.field}>
                <Dropdown
                  id="location"
                  classes={classes.dropdown}
                  emptyLabel="All Locations"
                  label="Location"
                  value={filters.location}
                  onChange={location => handleUpdate({location})}
                  options={locations.map(({name, id}) => ({title: name, value: `${id}`}))}
                />
              </div>
            ) : null}
            {products.length > 0 ? (
              <div className={classes.field}>
                <Dropdown
                  id="product"
                  classes={classes.dropdown}
                  emptyLabel="All Products"
                  label="Product"
                  value={filters.product}
                  onChange={product => handleUpdate({product})}
                  options={productOptions.map(({name}) => ({title: name, value: name}))}
                />
              </div>
            ) : null}
          </>
        )}
        <div className={classes.button}>
          <IconButton onClick={handleReset}><Clear/></IconButton>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Filters;
