export const SET_ORDER = 'SET_ORDER';

export default (
  state = {
    selected: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        selected: action.order,
      };
    default:
  }
  return state;
};
