import {Panes, Select, TextField} from '@raise.digital/management-ui';
import validator from 'email-validator';
import React from 'react';
import {useSelector} from 'react-redux';

export default function Fields({order = {}, prefix = '', multiplePanes = true, children}) {
  const user = useSelector(state => state['auth'].user);
  const locations = useSelector(state => state['customer'].locations);
  return (
    <Panes
      entity={order}
      prefix={prefix}
      panes={[
        {
          title: 'Contact Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Your Name"
              rules={{required: 'Please enter your name'}}
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Your Email Address"
              rules={{
                required: 'Please enter your email address',
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              fieldProps={{autoComplete: 'email'}}
            />,
            ...(user.location ? [] : [
              <Select
                name="location"
                prefix={prefix}
                label="Select a location"
                rules={{
                  required: 'Please select a location for this order'
                }}
                options={locations.map(({name, id}) => ({title: name, value: id}))}
              />
            ])
          ]
        }, {
          title: 'Comments',
          fields: [
            <TextField
              name="comments"
              prefix={prefix}
              label=""
              fieldProps={{multiline: true, rows: 4}}
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Place Order'}
    >{children}</Panes>
  );
}
