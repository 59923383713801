import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  reference: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto Mono',
    fontWeight: 600,
  }
}));

export default function Reference({reference}) {
  const classes = useStyles();
  return <span className={classes.reference}>{reference}</span>;
}
