import React, {createContext} from 'react';
import {AuthService} from '../services/AuthService';
import {CustomerService} from '../services/CustomerService';
import {LocationService} from '../services/LocationService';
import {OrderService} from '../services/OrderService';
import {PortalOrderService} from '../services/PortalOrderService';
import {PortalService} from '../services/PortalService';
import {ProductService} from '../services/ProductService';
import {RoleService} from '../services/RoleService';
import {ServiceProvider} from '../services/ServiceProvider';
import {SettingsService} from '../services/SettingsService';
import {UserService} from '../services/UserService';

export const ServiceContext = createContext({});

export default function Services({children}) {
  const services = {
    auth: new AuthService(),
    customer: new CustomerService(),
    location: new LocationService(),
    order: new OrderService(),
    portal: new PortalService(),
    portalOrder: new PortalOrderService(),
    product: new ProductService(),
    role: new RoleService(),
    settings: new SettingsService(),
    user: new UserService(),
  };
  ServiceProvider.setServices(services);
  return (
    <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
  );
}
