import {Panes, prefixWithSeparator, Select, TextField} from '@raise.digital/management-ui';
import validator from 'email-validator';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({user = {}, prefix = '', multiplePanes = true, administrators = false, children}) => {
  const services = useContext(ServiceContext);
  const {/** @var {function(string): string} */getValues} = useFormContext();
  const [roles, setRoles] = useState([]);
  const loadedRoles = useRef(false);

  useEffect(() => {
    if (!loadedRoles.current) {
      loadedRoles.current = true;
      services.role.getRoles({}, 1, 50).then(list => setRoles(list.items.map((r) => ({
        title: r.title,
        value: r.id
      })))).catch(() => null)
    }
  }, [services]);

  const handleReset = useCallback((subject) => {
    if (subject.role) {
      subject.roleId = subject.role.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={user}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Personal Details',
          fields: [
            <TextField
              name="firstName"
              prefix={prefix}
              label="First Name"
              rules={{required: 'Please enter a first name'}}
            />,
            <TextField
              name="lastName"
              prefix={prefix}
              label="Surname"
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                required: 'Please enter a email address',
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              fieldProps={{autoComplete: 'email'}}
            />,
            ...(administrators ? [
              <Select
                name="roleId"
                prefix={prefix}
                label="Role"
                options={roles}
              />
            ] : [])
          ]
        }, {
          title: 'Access Details',
          fields: [
            <TextField
              name="password"
              prefix={prefix}
              label="Password"
              rules={{
                ...(!user.id ? {required: 'Please enter a password'} : {}),
                validate: value => {
                  if (value) {
                    return value === getValues(`${prefixWithSeparator(prefix)}confirmPassword`) ? true : 'Please make sure the passwords match';
                  }
                  return true;
                }
              }}
              fieldProps={{type: 'password', autoComplete: 'password'}}
            />,
            <TextField
              name="confirmPassword"
              prefix={prefix}
              label="Confirm Password"
              rules={{
                validate: value => {
                  const password = getValues(`${prefixWithSeparator(prefix)}password`);
                  if (password) {
                    return value ? true : 'Please confirm the password';
                  }
                  return true;
                }
              }}
              fieldProps={{type: 'password', autoComplete: 'password'}}
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'User Details'}
    >{children}</Panes>
  );
};

export default Fields;
