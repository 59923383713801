import {combineReducers} from 'redux';

import auth from './auth';
import customer from './customer';
import customers from './customers';
import general from './general';
import locations from './locations';
import orders from './orders';
import products from './products';
import roles from './roles';

export default combineReducers({
  auth,
  customer,
  customers,
  general,
  locations,
  orders,
  products,
  roles,
});
