import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {useDetail, useErrors} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import Wrapper from '../Wrapper';
import OrderTable from './OrderTable';

export default function Orders() {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({customer: 'Sorry the customer could not be accessed'}), []));

  const {
    entity: customer,
    crumbs,
    loading
  } = useDetail(
    useMemo(() => services.customer.getCustomer, [services]),
    false,
    useCallback((customer) => [
      {
        title: customer.name,
        link: reverse(routes.admin.customers.detail, {id: customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: customer.id})
      },
      {title: 'Orders'}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('customer', displayError), [toggleError])
  );

  return (
    <Wrapper title="Orders" loading={loading} customer={customer} crumbs={crumbs} errors={errors}>
      {customer ? (
        <>
          <Paper marginTop={2} component={Box}>
            <OrderTable customer={customer}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
}
