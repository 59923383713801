import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const withOrderItemsAndProducts = (ListComponent) => {
  return (props) => {
    const {products} = props;
    const items = useSelector(state => state['customer'].items);
    const [itemsWithProducts, setItemsWithProducts] = useState([]);

    useEffect(() => {
      if (products.length > 0) {
        setItemsWithProducts(items.map((item) => ({
          ...item,
          product: products.find(p => p.id === item.ProductId)
        })));
      }
    }, [items, products]);

    return <ListComponent items={items} itemsWithProducts={itemsWithProducts} {...props}/>;
  };
};

export default withOrderItemsAndProducts;