import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import routes from '../../../routes';
import {ProductService} from '../../../services/ProductService';
import {setProduct} from '../../../store/actions/products';
import OrderTable from '../components/OrderTable';

const useStyles = makeStyles(theme => ({
  editPane: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  saveRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  },
  complete: {
    color: theme.palette.success.main
  },
  partial: {
    color: theme.palette.warning.main
  },
  pending: {
    color: theme.palette.error.main
  }
}));

export default function ProductOrders({match}) {
  const classes = useStyles();

  /** @type {IProduct} */
  const product = useSelector(state => state.products.selected);

  const [errorOpen, setErrorOpen] = useState(false);

  const dispatch = useDispatch();

  const loadProduct = useCallback(() => {
    const id = parseInt(match.params.id);
    if (!product || product.id !== id) {
      ProductService.getInstance().getProduct(parseInt(match.params.id)).then(retrieved => {
        dispatch(setProduct(retrieved));
      }).catch(() => {
        setErrorOpen(true);
      });
    }
  }, [product, match.params, dispatch]);

  useEffect(loadProduct, [product]);

  let crumbs = [];
  if (product) {
    crumbs = [
      props => <Link {...props} component={RouterLink} color="textPrimary" variant="body2"
                     to={reverse(routes.admin.products.detail, {id: product.id})}>{product.name}</Link>,
      props => <Typography {...props} color="textPrimary" variant="body2">Orders</Typography>
    ];
  }

  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="textPrimary" variant="body2"
                to={`${routes.admin.products.index}`}>Products</Link>
          {crumbs.map((crumb, index) => crumb({key: index}))}
        </Breadcrumbs>
      </Box>
      <Paper className={classes.container}>
        {product ? <OrderTable
          loadOrders={(query, additional) => ProductService.getInstance().getTableItems(product, query, additional)}
        /> : null}
      </Paper>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>
    </div>
  );
}
