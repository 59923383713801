import {SET_PERMISSIONS} from '../reducers/roles';
import {RoleService} from '../../services/RoleService';

export function setPermissions(permissions) {
  return {
    type: SET_PERMISSIONS,
    permissions,
  };
}

export function loadPermissions() {
  return (dispatch) => {
    RoleService.getInstance()
      .getPermissions()
      .then((permissions) => {
        dispatch(setPermissions(permissions));
      })
      .catch(() => {});
  };
}
