import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import Popper from '@material-ui/core/Popper';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Spinner from '../components/Spinner';

const useStyles = makeStyles(theme => ({
  noResults: {
    padding: theme.spacing(2),
  },

  adornment: {
    cursor: 'pointer',
  },
}));

const RelationAutocomplete = forwardRef(
  ({
     id,
     label: initialLabel,
     value: inputValue,
     onQuery,
     onChange,
     onAdd,
     loadOptions,
     required,
     error
   }, ref) => {
    const classes = useStyles();

    const subject$ = useRef(new BehaviorSubject(''));

    const [label, setLabel] = useState('');
    const [query, setQuery] = useState('');
    const [selected, setSelected] = useState(false);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const anchorRef = useRef();
    /** @type {({current: Object})} */
    const value = useRef();
    const queried = useRef('');

    useEffect(() => {
      const subscription = subject$.current.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
        const trimmed = value.trim();
        if (onQuery) {
          onQuery(trimmed);
        }
        if (trimmed.length >= 3 && queried.current !== trimmed) {
          queried.current = trimmed;
          setLoading(true);
          loadOptions(trimmed).then(loaded => {
            setLoading(false);
            setOptions(loaded);
          }).catch(() => {
            setLoading(false);
            setOptions([]);
          })
        }
      });

      return () => {
        subscription.unsubscribe();
      }
    }, [loadOptions, onQuery]);

    useEffect(() => {
      if (!label) {
        setLabel(initialLabel);
      }
    }, [initialLabel, label]);

    useEffect(() => {
      if (inputValue == null) {
        value.current = null;
        setLabel(initialLabel);
        setSelected(false);
      } else if (value.current == null || inputValue.value !== value.current.value) {
        value.current = inputValue;
        setLabel(inputValue.name);
        setQuery('');
        setOptions([]);
        setSelected(true);
      }
    }, [inputValue, initialLabel, options]);

    useEffect(() => {
      setOpen(options.length > 0);
    }, [options]);

    useEffect(() => {
      subject$.current.next(query ? query : '');
    }, [query]);

    useImperativeHandle(ref, () => ({
      clear() {
        value.current = '';
        setLabel(initialLabel);
        setQuery('');
        setOpen(false);
        setOptions([]);
      }
    }));

    let results = null;
    if (options && options.length > 0) {
      results = <List>
        {options.map((option, index) => (
          <ListItem
            key={index}
            button
            component={Link}
            onClick={() => onChange(option)}
          >
            {option.name}
          </ListItem>
        ))}
      </List>;
    }

    return (
      <>
        <TextField
          id={id}
          ref={anchorRef}
          fullWidth
          value={query}
          onChange={e => setQuery(e.target.value)}
          required={required}
          error={!!error}
          helperText={!!error ? error : ''}
          variant="outlined"
          margin="normal"
          label={label}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading ? <Spinner/> : <SearchIcon/>}
                {selected ? (
                  <div className={classes.adornment} onClick={() => onChange(null)}><CloseIcon/></div>
                ) : (
                  onAdd == null ? null : <div className={classes.adornment} onClick={() => onAdd()}><AddIcon/></div>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Popper open={open} style={{zIndex: 2000}} anchorEl={anchorRef.current} transition disablePortal
                placement="bottom-start">
          <Paper>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              {results ? results :
                <Typography className={classes.noResults}>Sorry no results were found for your query</Typography>}
            </ClickAwayListener>
          </Paper>
        </Popper>
      </>
    )
  });

export default RelationAutocomplete;
