import {Panes, TextField, UploadField} from '@raise.digital/management-ui';
import React from 'react';

export default function Fields({customer = {}, prefix = '', multiplePanes = true, logoRef, emailLogoRef, iconRef, children}) {
  return (
    <Panes
      entity={customer}
      prefix={prefix}
      panes={[
        {
          title: 'Identity',
          fields: [
            <TextField
              name="domain"
              prefix={prefix}
              label="Domain"
            />,
            <TextField
              name="emailSuffix"
              prefix={prefix}
              label="Email Suffix"
            />,
            <TextField
              name="emailSender"
              prefix={prefix}
              label="Sender Email Address"
            />,
            <UploadField
              ref={logoRef}
              name="logo"
              prefix={prefix}
              label="Drop an SVG (svg) logo here or click to select one from your computer"
              types=".svg"
            />,
            <UploadField
              ref={emailLogoRef}
              name="emailLogo"
              prefix={prefix}
              label="Drop an PNG (png) logo here or click to select one from your computer"
              types=".png"
            />,
            <UploadField
              ref={iconRef}
              name="icon"
              prefix={prefix}
              label="Drop an icon (ico) here or click to select one from your computer"
              types=".ico"
            />
          ]
        },
        {
          title: 'Colours',
          fields: [
            <TextField
              name="primaryColourMain"
              prefix={prefix}
              label="Primary Colour - Main"
            />,
            <TextField
              name="primaryColourContrast"
              prefix={prefix}
              label="Primary Colour - Contrast"
            />,
            <TextField
              name="secondaryColourMain"
              prefix={prefix}
              label="Secondary Colour - Main"
            />,
            <TextField
              name="secondaryColourContrast"
              prefix={prefix}
              label="Secondary Colour - Contrast"
            />,
          ]
        },
      ]}
      title={multiplePanes ? null : 'Customer Branding'}
    >{children}</Panes>
  );
}
