import {CircularProgress} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useForm} from 'react-hook-form';
import {CustomerService} from '../../../services/CustomerService';
import {LocationService} from '../../../services/LocationService';
import {ProductService} from '../../../services/ProductService';
import RelationAutocomplete from './RelationAutocomplete';
import useFormStyles from './styles';

const ProductForm = forwardRef(({product, onSaved}, ref) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerError, setCustomerError] = useState('');
  const [location, setLocation] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm(/** @type {Partial} */ {defaultValues: {buffer: 0, packedIn: 1}});

  const formRelationOption = (relation) => ({
    name: relation.name,
    value: relation.id
  });

  const onSubmit = data => {
    setCustomerError('');
    let valid = true;
    if (customer == null) {
      setCustomerError('Please select a customer');
      valid = false;
    }
    if (valid) {
      let update = {...data, customerId: customer.value, locationId: location ? location.value : null};
      if (product) {
        update = {...product, ...update};
      }
      update = {
        ...update,
        buffer: parseInt(/** @type {String} */ update.buffer),
        packedIn: parseInt(/** @type {String} */ update.packedIn)
      };
      setLoading(true);
      ProductService.getInstance().saveProduct(update).then(updated => {
        setLoading(false);
        onSaved(updated);
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
      })
    }
  };

  useEffect(() => {
    reset(product);
    if (product) {
      if (product.customer) {
        setCustomer(formRelationOption(product.customer));
      }
      if (product.location) {
        setLocation(formRelationOption(product.location));
      }
    } else {
      setCustomer(null);
      setLocation(null);
    }
  }, [product, reset]);

  useImperativeHandle(ref, () => ({
    saveProduct() {
      if (!loading) {
        handleSubmit(onSubmit)();
      }
    }
  }));

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress/></div>;
  }

  const {ref: nameRef, ...nameProps} = register('name', {
    required: 'Please enter a name'
  });

  let versionRef = null;
  let versionProps = null;
  if (product == null) {
    const {ref, ...props} = register('versionCode', {
      required: 'Please enter a code to identify the first version'
    });
    versionRef = ref;
    versionProps = props;
  }

  const {ref: bufferRef, ...bufferProps} = register('buffer');
  const {ref: packedInRef, ...packedInProps} = register('packedIn', {
    required: 'Please enter a packed in amount'
  });
  const {ref: qrRef, ...qrProps} = register('qr');
  const {ref: descriptionRef, ...descriptionProps} = register('description');

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      {loadingOverlay}
      <div className={classes.pane}>
        <TextField
          fullWidth
          inputRef={nameRef}
          {...nameProps}
          error={!!errors.name}
          helperText={!!errors.name ? errors.name.message : ''}
          variant="outlined"
          margin="normal"
          required
          id="name"
          label="Name"
        />
        {versionRef ? <TextField
          fullWidth
          inputRef={versionRef}
          {...versionProps}
          error={!!errors.versionCode}
          helperText={!!errors.versionCode ? errors.versionCode.message : ''}
          variant="outlined"
          margin="normal"
          required
          id="versionCode"
          label="Version Code"
        /> : null}
        <RelationAutocomplete
          id="customerId"
          required={true}
          label="Customer"
          loadOptions={query => (
            CustomerService.getInstance().getCustomers({filter: {search: query}}, 1, 50)
              .then(results => results.items.map(c => formRelationOption(c)))
              .catch(() => [])
          )}
          value={customer}
          onChange={selected => {
            setCustomerError('');
            setCustomer(selected);
          }}
          error={customerError}
        />
        <TextField
          fullWidth
          inputRef={bufferRef}
          {...bufferProps}
          error={!!errors.buffer}
          helperText={!!errors.buffer ? errors.buffer.message : ''}
          variant="outlined"
          margin="normal"
          id="buffer"
          label="Buffer"
          type="number"
        />
        <TextField
          fullWidth
          required={true}
          inputRef={packedInRef}
          {...packedInProps}
          error={!!errors.packedIn}
          helperText={!!errors.packedIn ? errors.packedIn.message : ''}
          variant="outlined"
          margin="normal"
          id="packedIn"
          label="Packed In Quantity"
          type="number"
        />
        <TextField
          fullWidth
          inputRef={qrRef}
          {...qrProps}
          error={!!errors.qr}
          helperText={!!errors.qr ? errors.qr.message : ''}
          variant="outlined"
          margin="normal"
          id="qr"
          label="QR Code ID"
        />
      </div>
      <div className={classes.pane}>
        <RelationAutocomplete
          id="locationId"
          label="Location"
          loadOptions={query => (
            LocationService.getInstance().getLocations({filter: {search: query}}, 1, 50)
              .then(results => results.items.map(l => formRelationOption(l)))
              .catch(() => [])
          )}
          value={location}
          onChange={setLocation}
        />
        <TextField
          fullWidth
          inputRef={descriptionRef}
          {...descriptionProps}
          variant="outlined"
          multiline
          rows={6}
          margin="normal"
          id="description"
          label="Description"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Save
        </Button>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem saving the product
        </Alert>
      </Snackbar>
    </form>
  );
});

export default ProductForm;
