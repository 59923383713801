import {Box, Paper} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import {Breadcrumbs, Title} from '@raise.digital/management-ui';
import React, {useRef, useState} from 'react';
import SupportForm from '../forms/SupportForm';

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
  },
  form: {
    padding: theme.spacing(2),
    width: '100%',
  },
  helpText: {
    padding: theme.spacing(2),
    width: '100%',
  },
  floating: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),

    '& > *': {
      marginLeft: theme.spacing(2)
    },

    '& :first-child': {
      marginLeft: 0
    },
  },
  floatingButtonIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Support = () => {
  const classes = useStyles();
  const [sent, setSent] = useState(false);
  /** @type {({current: SupportForm})} */
  const formRef = useRef();

  return (
    <>
      <Breadcrumbs crumbs={[{title: 'Help & Support'}]}/>
      <Title title="Help & Support"/>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid className={classes.column} item xs={6}>
            <Paper className={classes.form}>
              {sent ? <p>Thank you for sending us a message.</p> : (
                <SupportForm ref={formRef} message={{}} onSaved={() => setSent(true)}/>
              )}
            </Paper>
          </Grid>
          <Grid className={classes.column} item xs={6}>
            <Paper className={classes.helpText}>
              <p>Please use the form here to request any support you may require using the portal.</p>
              <p>We will get back to you with assistance as soon as possible.</p>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {sent ? null : <div className={classes.floating}>
        <Fab color="primary" variant="extended" onClick={() => formRef.current.save()}>
          <SendIcon className={classes.floatingButtonIcon}/>Submit
        </Fab>
      </div>}
    </>
  );
};

export default Support;