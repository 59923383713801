export const SET_LOCATION = 'SET_LOCATION';

export default (
  state = {
    selected: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        selected: action.location,
      };
    default:
  }
  return state;
};
