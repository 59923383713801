import {RecentActors} from '@material-ui/icons';
import {DataTable} from '@raise.digital/management-ui';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import UserForm from '../../forms/UserForm';
import Password from './Password';

const Table = ({title = '', customer = null, location = null, administrators = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  return (
    <DataTable
      title={title}
      actions={administrators ? {
        before: [
          {
            icon: RecentActors,
            tooltip: 'Roles',
            isFreeAction: true,
            onClick: () => history.push(routes.admin.administrators.roles),
          }
        ]
      } : {}}
      columns={[
        {title: 'Name', field: 'name', render: data => `${data.firstName} ${data.lastName}`},
        {title: 'Email', field: 'email'},
        ...(administrators ? [
          {title: 'Role', field: 'role', render: data => data.role ? data.role.title : '-'},
        ] : []),
        ...(customer || location ? [
          {title: 'Password', field: 'password', render: data => <Password user={data}/>},
        ] : [])
      ]}
      loadData={query => new Promise((resolve, reject) => {
        const filters = {};
        if (customer) {
          filters['customer'] = customer.id
        } else if (location) {
          filters['location'] = location.id
        } else {
          filters['admin'] = true;
        }
        services.user.getTableUsers(query, filters)
          .then(response => {
            resolve({
              data: response.items, page: response.page - 1, totalCount: response.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New User',
        render: (props) => {
          const newUser = {};
          if (customer) {
            newUser.customerId = customer.id;
          }
          if (location) {
            newUser.locationId = location.id;
          }
          return <UserForm user={newUser} administrators={administrators} {...props}/>;
        }
      }}
      editForm={{
        render: (user, props) => <UserForm user={user} administrators={administrators} {...props}/>
      }}
    />
  )
}

Table.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object
};

export default Table;
