import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {DataTable, dateTime, FormDialog, useDialogs} from '@raise.digital/management-ui';
import {MTableToolbar} from 'material-table';
import moment from 'moment';
import {reverse} from 'named-urls';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import Status from '../../../../../components/Status';
import routes from '../../../../../routes';
import Filters from '../../../../customer/screens/History/Filters';
import PODForm from '../../../forms/PODForm';

const OrderTable = ({customer, status}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const filters = useRef({
    from: moment().subtract(1, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    status: 'Active Orders'
  });

  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    services.customer.getPersonalisedProducts(customer)
      .then(setProducts)
      .catch(() => setProducts([]));

    services.customer.getLocations({filter: {customerId: customer.id}}, 1, 500)
      .then(({items}) => setLocations(items))
      .catch(() => setLocations([]));
  }, [services, customer]);

  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const {openDialogs, toggleDialog} = useDialogs(['pod']);

  const handlePOD = useCallback(() => {
    toggleDialog('pod', false);
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, [toggleDialog]);

  const handleFilters = useCallback((updated) => {
    filters.current = updated;
    tableRef.current.refresh();
  }, []);

  return (
    <>
      <DataTable
        ref={tableRef}
        title=""
        options={{search: false, draggable: false}}
        actions={{
          before: [
            {
              icon: LocalShippingIcon,
              tooltip: 'Dispatch Orders',
              onClick: () => history.push(reverse(routes.admin.customers.portal.orders.dispatch, {id: customer.id})),
              isFreeAction: true,
            },
            {
              icon: 'download',
              tooltip: 'Export Orders',
              onClick: () => {
                const additionalFilters = {customer: customer.id};
                if (status) {
                  additionalFilters.status = status;
                }
                services.portalOrder.export({...additionalFilters, ...filters.current}).then(() => {
                  if (tableRef.current) {
                    tableRef.current.refresh();
                  }
                }).catch(() => {
                });
              },
              isFreeAction: true,
            }
          ]
        }}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              {status ? null : (
                <Filters
                  initial={filters.current}
                  onRefresh={handleFilters}
                  showAll={true}
                  locations={locations}
                  products={products}
                />
              )}
            </div>
          )
        }}
        columns={[
          {title: 'Reference', field: 'reference', sorting: false},
          {title: 'Status', field: 'status', sorting: false, render: data => <Status status={data.status}/>},
          {
            title: 'Ordered by', field: 'user', render: data => (
              <>
                <strong>{data.order.orderedBy.name} - {data.order.orderedBy.email}</strong><br/>
                {`${data.order.user.firstName} ${data.order.user.lastName}`}
              </>
            ), sorting: false
          },
          {title: 'Item', field: 'item', render: data => data.name, sorting: false},
          {
            title: 'Location',
            field: 'location',
            render: data => data.order.location ? data.order.location.name : '-',
            sorting: false
          },
          {title: 'Date Ordered', field: 'created', render: data => dateTime(data.created), sorting: false}
        ]}
        onRowClick={(item) => {
          history.push(reverse(routes.admin.customers.portal.orders.detail, {
            customerID: item.order.location.customer.id,
            id: item.id
          }));
        }}
        loadData={query => new Promise((resolve, reject) => {
          const additionalFilters = {customer: customer.id};
          if (status) {
            additionalFilters.status = status;
          }
          services.portalOrder.getTableOrders(query, {...additionalFilters, ...filters.current})
            .then(response => {
              resolve({
                data: response.items, page: response.page - 1, totalCount: response.total
              });
            }).catch(() => {
            reject();
          });
        })}
      />
      <FormDialog
        title="Proof of Delivery"
        open={openDialogs['pod'] ?? false}
        onClose={() => toggleDialog('pod', false)}
        render={(props) => (
          <PODForm
            {...props}
            pod={{}}
            onSaved={handlePOD}/>
        )}
      />
    </>
  );
};

OrderTable.propTypes = {
  title: PropTypes.string,
  status: PropTypes.object
};

export default OrderTable;
