import React, {useCallback, useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {reverse} from 'named-urls';
import routes from '../../../routes';
import MaterialTable, {MTableToolbar} from 'material-table';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Reference from './Reference';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import moment from 'moment';
import {useTheme} from '@material-ui/styles';
import {usePermissions} from '../../../hooks';

const useStyles = makeStyles(theme => ({
  tabs: {
    backgroundColor: theme.palette.background.default,
  },
  bold: {
    fontWeight: 'bold',
  },
  complete: {
    color: theme.palette.success.main
  },
  partial: {
    color: theme.palette.warning.main
  },
  pending: {
    color: theme.palette.error.main
  },
  archived: {
    color: theme.palette.grey['500']
  },
  today: {
    backgroundColor: theme.palette.text.hint,
    color: theme.palette.background.paper,
  },
}));

export default function OrderTable({loadOrders}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [errorOpen, setErrorOpen] = useState(false);
  const statusRef = useRef('');
  const [status, setStatus] = useState('pending');
  /** @type {({current: MaterialTable})} */
  let tableRef = useRef();
  const canCreateOrders = usePermissions(['ordering::create-orders']);

  const selectOrder = useCallback(selected => {
    history.push(reverse(routes.admin.orders.detail, { id: selected.orderId }))
  }, [history]);

  useEffect(() => {
    if (status !== statusRef.current) {
      statusRef.current = status;
      tableRef.current && tableRef.current.onQueryChange(null);
    }
  }, [status]);

  return (
    <>
      <MaterialTable
        title="Orders"
        tableRef={tableRef}
        options={{
          debounceInterval: 500,
          detailPanelType: 'single',
          emptyRowsWhenPaging: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 250],
        }}
        components={{
          Container: props => <Paper elevation={0} {...props} />,
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <div className={classes.tabs}>
                <Tabs
                  value={status}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, selected) => setStatus(selected)}
                  centered
                >
                  <Tab style={{color: theme.palette.error.main}} label="Pending" value="pending"/>
                  <Tab style={{color: theme.palette.warning.main}} label="Partial" value="partial"/>
                  <Tab style={{color: theme.palette.success.main}} label="Complete" value="complete"/>
                  <Tab style={{backgroundColor: theme.palette.background.paper}} label="Today" value="today"/>
                  <Tab label="Archived" value="archived"/>
                  <Tab label="All" value=""/>
                </Tabs>
              </div>
            </div>
          )
        }}
        columns={[
          {title: 'Customer', field: 'customer', render: data => data.productVersion.product.customer.name, sorting: false},
          {title: 'Job Number', field: 'reference', render: data => <Reference reference={data.reference}/>, sorting: false},
          {title: 'Title', field: 'title', render: data => data.productVersion.product.name, sorting: false},
          {title: 'Due Date', field: 'due', render: data => data.order.due ? <span className={moment(data.order.due).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? classes.bold : ''}>{moment(data.order.due).format('DD/MM/YYYY')}</span> : '-', sorting: false},
          {title: 'Status', field: 'status', render: data => <strong className={classes[data.status.toLowerCase()]}>{data.status}</strong>, sorting: false},
        ]}
        data={query => new Promise(resolve => {
          loadOrders(query, {status: statusRef.current}).then(response => {
            resolve({
              data: response.items, page: response.page - 1, totalCount: response.total
            });
          }).catch(() => {
            setErrorOpen(true);
            resolve({data: [], page: 0, totalCount: 0});
          })
        })}
        onRowClick={(event, rowData) => selectOrder(rowData)}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => tableRef.current && tableRef.current.onQueryChange(null),
          },
          {
            icon: 'add',
            tooltip: 'Create New Order',
            isFreeAction: true,
            onClick: () => history.push(reverse(`${routes.admin.orders.new}`)),
            disabled: !canCreateOrders
          }
        ]}
      />
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>
    </>
  );
}
