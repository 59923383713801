import React from 'react';
import MatBreadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Breadcrumbs({crumbs}) {
  return crumbs && crumbs.length ? (
    <Box paddingLeft={2} paddingBottom={2}>
      <MatBreadcrumbs aria-label="breadcrumb">
        {crumbs.map((crumb, index) => {
          if (crumb.link) {
            return <Link key={index} component={RouterLink} color="textPrimary" variant="body2"
                         to={crumb.link}>{crumb.title}</Link>
          } else {
            return <Typography key={index} color="textPrimary" variant="body2">{crumb.title}</Typography>
          }
        })}
      </MatBreadcrumbs>
    </Box>
  ) : null;
}
