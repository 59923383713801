import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import routes from '../../../routes';
import {OrderService} from '../../../services/OrderService';
import {setOrder} from '../../../store/actions/orders';

const useStyles = makeStyles(theme => ({
  editPane: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  saveRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  },
  changes: {
    display: 'flex',
    flexDirection: 'column',
  }
}));

export default function OrderChanges({match}) {
  const classes = useStyles();

  /** @type {IOrder} */
  const order = useSelector(state => state.orders.selected);

  const [errorOpen, setErrorOpen] = useState(false);

  /** @type {({current: MaterialTable})} */
  let tableRef = useRef();

  const dispatch = useDispatch();

  const loadOrder = useCallback(() => {
    const id = parseInt(match.params.id);
    if (!order || order.id !== id) {
      OrderService.getInstance().getOrder(parseInt(match.params.id)).then(retrieved => {
        dispatch(setOrder(retrieved));
      }).catch(() => {
        setErrorOpen(true);
      });
    }
  }, [order, match.params, dispatch]);

  useEffect(loadOrder, [order]);

  let crumbs = [];
  if (order) {
    crumbs = [
      props => <Link {...props} component={RouterLink} color="textPrimary" variant="body2"
                     to={reverse(routes.admin.orders.detail, {id: order.id})}>{order.reference}</Link>,
      props =>
        <Typography {...props} color="textPrimary" variant="body2">Changes</Typography>
    ];
  }

  const renderChanges = log => {
    return (
      <div className={classes.changes}>
        {Object.keys(log.changes).map((k, i) => {
          if (k === 'Due') {
            const change = log.changes[k];
            return (
              <span key={i}>
                <strong>Due Date</strong><br/>
                {`${moment(change.From).format('DD/MM/YYYY')} > ${moment(change.To).format('DD/MM/YYYY')}`}
              </span>
            )
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="textPrimary" variant="body2" to={`${routes.admin.orders.index}`}>Orders</Link>
          {crumbs.map((crumb, index) => crumb({key: index}))}
        </Breadcrumbs>
      </Box>
      <Paper className={classes.container}>
        {order ? <MaterialTable
          title="Changes"
          options={{
            debounceInterval: 500,
            detailPanelType: 'single',
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [50, 100, 250],
            search: false,
          }}
          tableRef={tableRef}
          components={{
            Container: props => <Paper elevation={0} {...props} />
          }}
          columns={[
            {
              title: 'Date / Time',
              field: 'created',
              render: data => moment(data.created).format('DD/MM/YYYY HH:mm'),
              sorting: false
            },
            {
              title: 'User',
              field: 'user',
              render: data => `${data.user.firstName} ${data.user.lastName}`,
              sorting: false
            },
            {title: 'Changes', field: 'changes', render: renderChanges, sorting: false},
          ]}
          data={query => new Promise(resolve => {
            OrderService.getInstance().getTableChanges(order, query)
              .then(response => {
                resolve({
                  data: response.items, page: response.page - 1, totalCount: response.total
                });
              }).catch(() => {
              setErrorOpen(true);
              resolve({data: [], page: 0, totalCount: 0});
            });
          })}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(null),
            }
          ]}
        /> : null}
      </Paper>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>
    </div>
  );
}
