import qs from 'qs';
import {setItems} from '../store/actions/customer';
import {API} from './API';

export class PortalService {
  static getParams(additionalParams = {}, additionalConfig = {}) {
    return {
      params: {domain: window.location.host, ...additionalParams},
      paramsSerializer: (params) => qs.stringify(params),
      ...additionalConfig
    };
  }

  async getConfig() {
    return (await API.getConnection())
      .get('portal/config', PortalService.getParams())
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async forgotPassword(account, name, phone, email) {
    return (await API.getConnection())
      .post('portal/forgot-password', {account, name, phone, email}, PortalService.getParams())
      .then(() => true)
      .catch((error) => API.handleError(error));
  }

  async getPersonalisedProducts() {
    return (await API.getConnection())
      .get('portal/personalised', PortalService.getParams())
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async getPersonalisedProduct(id) {
    return (await API.getConnection())
      .get(`portal/personalised/${id}`, PortalService.getParams())
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getPersonalisedProductSample(product) {
    return (await API.getConnection())
      .get(`portal/personalised/${product.id}/sample`, PortalService.getParams({}, {responseType: 'arraybuffer'}))
      .then((response) => {
        return `data:image/png;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  async getItems() {
    return (await API.getConnection())
      .get('portal/items', PortalService.getParams())
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async setItems(items) {
    const body = new FormData();
    body.append('items', JSON.stringify(items));
    return (await API.getConnection())
      .put('portal/items', body, PortalService.getParams())
      .then((response) => {
        const updated = response.data;
        API.store.dispatch(setItems(updated));
        return updated;
      })
      .catch((error) => API.handleError(error));
  }

  async placeOrder(source) {
    return (await API.getConnection())
      .post('portal/orders', source, PortalService.getParams())
      .then((response) => {
        const updated = response.data;
        API.store.dispatch(setItems(updated));
        return updated;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableOrders(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getOrders(query, page, pageSize);
  }

  async getOrders(query, page, pageSize) {
    return (await API.getConnection())
      .get('portal/orders', PortalService.getParams({...query, ...{page, pageSize}}))
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async export(filters) {
    return (await API.getConnection())
      .get('portal/orders', PortalService.getParams(
        {export: 'true', filter: {...filters}},
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        }))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Orders.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => API.handleError(error));
  }

  async getOrder(id) {
    return (await API.getConnection())
      .get(`portal/order/${id}`, PortalService.getParams())
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async approveOrder(id) {
    return (await API.getConnection())
      .put(`portal/order/${id}`, {}, PortalService.getParams())
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async getLocations() {
    return (await API.getConnection())
      .get('portal/locations', PortalService.getParams())
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async sendMessage(message) {
    return (await API.getConnection())
      .post('portal/message', message, PortalService.getParams())
      .then(() => true)
      .catch((error) => API.handleError(error));
  }
}
