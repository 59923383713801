import {BaseForm} from '@raise.digital/management-ui';
import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const OrderItemForm = forwardRef(({product, item, onSaved, onUpdateItem}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={item}
      type="item"
      fieldsComponent={props => <Fields {...props} product={product} onUpdateItem={onUpdateItem}/>}
      onSave={(data) => new Promise(resolve => resolve(data))}
      onSaved={onSaved}
    />
  );
});

export default OrderItemForm;
