import {DataTable} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import CustomerForm from '../../forms/CustomerForm';

export default function Table() {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((customer) => {
    history.push(reverse(routes.admin.customers.detail, {id: customer.id}));
  }, [history]);

  return (
    <DataTable
      title="Customers"
      columns={[
        {title: 'Name', field: 'name', sorting: false}
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.customer.getTableCustomers(query)
          .then(response => {
            resolve({
              data: response.items, page: response.page - 1, totalCount: response.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={{
        title: 'Add New Customer',
        render: (props) => {
          return <CustomerForm customer={{}} {...props}/>
        },
        onSaved: goToDetail
      }}
    />
  );
}
