import {makeStyles} from '@material-ui/core/styles';
import {convertToHTML} from 'draft-convert';
import {convertFromRaw, EditorState} from 'draft-js';
import React, {useEffect, useState} from 'react';

const useStyles = makeStyles((theme) => ({
  holder: {
    display: 'inline-block',
    padding: `0 0 ${theme.spacing(1)}px 0`,
    width: '100%',

    '& :first-child': {
      marginTop: 0,
      paddingTop: 0,
    },

    '& :last-child': {
      marginBottom: 0,
      paddingBottom: 0,
    }
  }
}));

const DraftHTML = ({raw}) => {
  const classes = useStyles();
  const [html, setHTML] = useState('');

  useEffect(() => {
    let markup = '';
    try {
      markup = convertToHTML({
        styleToHTML: (style) => {
          if (style && style.startsWith('fontsize-')) {
            return <span style={{fontSize: parseInt(style.replace('fontsize-', ''))}}/>;
          }
        },
      })(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(raw ?? '{}'))
        ).getCurrentContent());
    } catch (e) {

    }
    setHTML(markup);
  }, [raw]);

  return (
    <div className={classes.holder} dangerouslySetInnerHTML={{__html: html}}/>
  );
};

export default DraftHTML;