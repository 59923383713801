import * as React from 'react';
import {forwardRef, useImperativeHandle, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {CircularProgress} from '@material-ui/core';
import useFormStyles from './styles';

const NoteForm = forwardRef(({onSave, onSaved}, ref) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState('');

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!note) {
      setNoteError('Please enter a comment')
    } else {
      setLoading(true);
      onSave(note).then(() => {
        setLoading(false);
        onSaved();
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
      })
    }
  };

  useImperativeHandle(ref, () => ({
    saveNote() {
      if (!loading) {
        onSubmit();
      }
    }
  }));

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress /></div>;
  }

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      {loadingOverlay}
      <div className={classes.pane}>
        <TextField
          fullWidth
          name="note"
          value={note}
          onChange={event => setNote(event.target.value)}
          error={!!noteError}
          helperText={noteError}
          variant="outlined"
          margin="normal"
          required
          id="note"
          label="Comments"
          multiline
          rows={6}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Save
        </Button>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem saving the comment
        </Alert>
      </Snackbar>
    </form>
  );
});

export default NoteForm;
