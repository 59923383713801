import {IconButton} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {HourglassEmpty, Visibility, VisibilityOff} from '@material-ui/icons';
import React, {useCallback, useContext, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

const DEFAULT = '••••••••••';
const RETRIEVING = 'Retrieving...';
const FAILED = '';

const Password = ({user}) => {
  const services = useContext(ServiceContext);
  const [password, setPassword] = useState(DEFAULT);

  const handlePassword = useCallback(() => {
    if ([DEFAULT, RETRIEVING, FAILED].indexOf(password) < 0) {
      setPassword(DEFAULT);
    } else {
      setPassword(RETRIEVING);
      services.user.getPassword(user).then(setPassword).catch(() => setPassword(FAILED))
    }
  }, [services, user, password]);

  return (
    <Box onClick={e => e.stopPropagation()} display="flex" alignItems="center" width="240px">
      <Box flex={1}>{password}</Box>
      <IconButton onClick={handlePassword}>
        {(() => {
          switch (password) {
            case DEFAULT:
              return <Visibility/>;
            case RETRIEVING:
              return <HourglassEmpty/>;
            default:
              return <VisibilityOff/>;
          }
        })()}
      </IconButton>
    </Box>
  )
};

export default Password;
