import * as React from 'react';
import Table from './Table';
import {Paper} from '@material-ui/core';
import {Breadcrumbs} from '@raise.digital/management-ui';

export default function Customers() {
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Customers'}]}/>
      <Paper>
        <Table/>
      </Paper>
    </div>
  );
}
