import {DataTable} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import CustomerCategoryForm from '../../../forms/CustomerCategoryForm';


const Categories = ({customer}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToCategory = useCallback((category) => {
    history.push(reverse(routes.admin.customers.portal.category, {customerID: customer.id, id: category.id}));
  }, [history, customer]);

  return (
    <DataTable
      title="Categories"
      columns={[
        {title: 'Name', field: 'name', sorting: false},
        {title: 'Products', field: 'products', render: (data) => data?.products?.length ?? 0},
        {title: 'Locations', field: 'locations', render: (data) => data?.locations?.length ?? 0},
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.customer.getTableCategories(query, {customerId: customer.id})
          .then(response => {
            resolve({
              data: response.items, page: response.page - 1, totalCount: response.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToCategory}
      newForm={{
        title: 'Add New Category',
        render: (props) => {
          return <CustomerCategoryForm customer={customer} category={{}} {...props}/>
        },
        onSaved: goToCategory
      }}
    />
  );
};

export default Categories;
