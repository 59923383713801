import {BaseForm} from '@raise.digital/management-ui';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const PersonalisedProductFieldForm = forwardRef(({product, field, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [conditions, setConditions] = useState([]);

  useEffect(() => {
    if (field && field.options) {
      setOptions(field.options.map(({value}) => value));
    } else {
      setOptions([]);
    }
    if (field && field.conditions) {
      setConditions([...field.conditions]);
    } else {
      setConditions([]);
    }
  }, [field]);

  const handleSave = useCallback((update) => services.customer.savePersonalisedProductField(product, {
    ...update,
    options: options.map((value, index) => ({value, index})),
    conditions: conditions.map((condition, index) => ({...condition, index}))
  }), [services, product, options, conditions]);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={field}
      type="field"
      fieldsComponent={(props) => (
        <Fields
          {...props}
          product={product}
          options={options}
          onUpdateOptions={setOptions}
          conditions={conditions}
          onUpdateConditions={setConditions}
        />
      )}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default PersonalisedProductFieldForm;
