import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {CircularProgress} from '@material-ui/core';
import useFormStyles from './styles';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  row: {
    borderColor: theme.palette.grey['200'],
    borderStyle: 'solid',
    borderWidth: 0,
    borderTopWidth: 1,
    display: 'flex',

    '&:first-child': {
      borderTopWidth: 0,
    },
  },
  check: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
  }
}));

const LabelsForm = forwardRef(({order, onGenerate, onGenerated}, ref) => {
  const classes = useFormStyles();
  const labelClasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (order) {
      setItems(order.items.map(item => ({
        id: item.id,
        selected: true,
        product: item.productVersion.product.name,
        version: item.productVersion.code,
        maxQuantity: item.quantity,
        packedIn: item.packedIn,
      })));
    }
  }, [order]);

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let valid = true;
    const labels = items.filter(i => i.selected).map(item => {
      if (!item.packedIn) {
        valid = false;
      }
      return {
        itemId: item.id,
        packedIn: item.packedIn,
      };
    });
    if (valid) {
      setLoading(true);
      onGenerate(labels).then(() => {
        setLoading(false);
        onGenerated();
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
      });
    } else {
      setErrorOpen(true);
    }
  };

  useImperativeHandle(ref, () => ({
    generateLabels() {
      if (!loading) {
        onSubmit();
      }
    }
  }));

  const toggleChecked = (index, checked) => {
    const updated = [...items];
    updated[index].selected = checked;
    setItems(updated);
  }

  const setValue = (field, index, value) => {
    if (value) {
      const updated = [...items];
      const item = updated[index];
      const parsed = parseInt(value);
      if (parsed) {
        updated[index][field] = ((parsed > item.maxQuantity) ? item.maxQuantity : parsed);
        setItems(updated);
      }
    }
  }

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress /></div>;
  }

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      {loadingOverlay}
      <div className={classes.pane}>
        {items.map((item, index) => <div className={labelClasses.row} key={index}>
          <div className={labelClasses.check}>
            <Checkbox
              checked={item.selected}
              onChange={e => toggleChecked(index, e.target.checked)}
              color="primary"/>
          </div>
          <div className={labelClasses.column}>{item.product}<br />({item.version})</div>
          <div className={labelClasses.column}>
            <TextField
              fullWidth
              name={`packedIn-${index}`}
              value={item.packedIn}
              onChange={e => setValue('packedIn', index, e.target.value)}
              variant="outlined"
              margin="normal"
              id={`packedIn-${index}`}
              label="Packed In"
              type="number"
            />
          </div>
        </div>)}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Save
        </Button>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Please ensure you have selected valid quantities and packed-in quantities
        </Alert>
      </Snackbar>
    </form>
  );
});

export default LabelsForm;
