export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export default (
  state = {
    permissions: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    default:
  }
  return state;
};
