import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {dateTime} from '@raise.digital/management-ui';
import React from 'react';

const useStyles = makeStyles(theme => ({
  details: {
    backgroundColor: theme.palette.background.default,
    margin: 0,
    padding: theme.spacing(1),
    width: '100%',
  },
  detailsColumn: {
    display: 'flex',
  },
  detailsPane: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden'
  },
}));

const Message = ({message}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.details} container spacing={2}>
      <Grid className={classes.detailsColumn} item xs={6}>
        <Paper className={classes.detailsPane}>
          <List dense subheader={<ListSubheader>Sender Details</ListSubheader>}>
            <ListItem>
              <ListItemText
                primary={message.name}
                secondary="Name"
              />
            </ListItem>
            {message.email ? <ListItem>
              <ListItemText
                primary={message.email}
                secondary="Email Address"
              />
            </ListItem> : null}
            {message.phone ? <ListItem>
              <ListItemText
                primary={message.phone}
                secondary="Telephone Number"
              />
            </ListItem> : null}
            <ListItem>
              <ListItemText
                primary={`${message.user.firstName} ${message.user.lastName} (${message.user.email})`}
                secondary="User"
              />
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid className={classes.detailsColumn} item xs={6}>
        <Paper className={classes.detailsPane}>
          <List dense subheader={<ListSubheader>Message Details</ListSubheader>}>
            <ListItem>
              <ListItemText
                primary={message.message}
                secondary="Message"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={dateTime(message.created)}
                secondary="Date/Time Sent"
              />
            </ListItem>
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Message;
