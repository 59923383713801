import {Box, Paper} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {Breadcrumbs, Title} from '@raise.digital/management-ui';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import DraftHTML from '../../../components/DraftHTML';
import {ServiceContext} from '../../../components/Services';
import routes from '../../../routes';
import {setLoading} from '../../../store/actions/general';
import OrderItemForm from '../forms/OrderItemForm';

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
  },
  form: {
    padding: theme.spacing(2),
    width: '100%',
  },
  helpText: {
    padding: theme.spacing(2),
    width: '100%',
  },
  sample: {
    marginTop: theme.spacing(2),
    maxWidth: '100%',
    width: 400,
  },
  floating: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),

    '& > *': {
      marginLeft: theme.spacing(2)
    },

    '& :first-child': {
      marginLeft: 0
    },
  },
  floatingButtonIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Product = ({match}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector(state => state['customer'].items);
  const [product, setProduct] = useState(null);
  const [sample, setSample] = useState('');
  const [item, setItem] = useState(null);
  /** @type {({current: OrderItemForm})} */
  const formRef = useRef();
  const saveRedirect = useRef(true);

  useEffect(() => {
    const id = parseInt(match.params.id);
    dispatch(setLoading(true));
    services.portal.getPersonalisedProduct(id).then(retrieved => {
      setProduct(retrieved);
      dispatch(setLoading(false));
    }).catch(() => {
      dispatch(setLoading(false));
    });
  }, [services, dispatch, match.params.id]);

  useEffect(() => {
    if (product && product.hasSample) {
      services.portal.getPersonalisedProductSample(product).then(setSample).catch(() => setSample(''));
    } else {
      setSample('');
    }
    if (product) {
      if (product.unavailable) {
        history.push(routes.customer.home);
      }
    }
  }, [services, history, product]);

  useEffect(() => {
    if (product) {
      const currentItem = {fields: {}};
      if (items.length && match.params.index) {
        const index = parseInt(match.params.index);
        let existing = null;
        if (index < items.length) {
          existing = items[index];
        }
        if (existing) {
          currentItem.index = index;
          existing.Fields.forEach(data => {
            const field = product.fields.find(f => f.id === data.FieldId);
            let value = data.Value;
            if (field.suffix) {
              value = value.replace(field.suffix, '');
            }
            currentItem.fields[`field_${data.FieldId}`] = value;
          });
        }
      }
      setItem(currentItem);
    }
  }, [product, items, match.params.index]);

  const handleSave = useCallback((data) => {
    const amended = {
      Name: product.name,
      ProductId: product.id,
      Fields: []
    };
    Object.keys(data).forEach(key => {
      const match = key.match(/[0-9]*$/g);
      if (match) {
        const id = parseInt(match[0]);
        const field = product.fields.find(f => f.id === id);
        if (field) {
          let value = `${data[key] ?? ''}`;
          if (field.conditions && field.conditions.length > 0) {
            let populate = false;
            for (let condition of field.conditions) {
              if (data[`field_${condition.conditionFieldId}`] === condition.value) {
                populate = true;
                break;
              }
            }
            if (!populate) {
              value = '';
            }
          }
          if (field.formatter && value) {
            switch (field.formatter) {
              case 'Name':
                value = `${value}`
                  .trim()
                  .split(' ')
                  .filter(n => !!n)
                  .map(n => (
                    n
                      .split('-')
                      .filter(b => !!b)
                      .map(b => `${b.substr(0, 1).toUpperCase()}${b.substr(1).toLowerCase()}`)
                      .join('-')
                  ))
                  .join(' ');
                break;
              case 'Email Address':
                value = `${value}`.replace(/@.*?$/, '').trim().toLowerCase();
                break;
              case 'UK Telephone Number':
                const numbers = `${value}`.replace(/[^0-9]*/, '');
                const parts = [];
                if (numbers.length > 0) {
                  parts.push(numbers.substr(0, 5));
                }
                if (numbers.length >= 5) {
                  parts.push(numbers.substr(5, 3));
                }
                if (numbers.length >= 8) {
                  parts.push(numbers.substr(8, 3));
                }
                value = parts.join(' ');
                break;
              default:
                break;
            }
          }
          amended.Fields.push({
            Name: field.name,
            FieldId: field.id,
            Index: field.index,
            Value: `${value}${field.suffix ? field.suffix : ''}`
          });
        }
      }
    });
    amended.Fields.sort((f1, f2) => f1.Index > f2.Index ? 1 : -1);
    dispatch(setLoading(true));
    const list = [...items];
    if (item.hasOwnProperty('index')) {
      list[item.index] = amended;
    } else {
      list.push(amended);
    }
    services.portal.setItems(list).then(() => {
      dispatch(setLoading(false));
      if (saveRedirect.current) {
        history.push(routes.customer.order.index);
      }
    }).catch(() => {
      dispatch(setLoading(false));
    });
  }, [services, dispatch, history, product, items, item]);

  return (
    <>
      <Breadcrumbs crumbs={[
        {title: 'Order', link: routes.customer.order.index},
        ...(product ? [{title: product.name}] : [])
      ]}/>
      {product ? (
        <>
          <Title title={product.name}/>
          <Box marginTop={2}>
            <Grid container spacing={2}>
              <Grid className={classes.column} item xs={6}>
                <Paper className={classes.form}>
                  {item ?
                    <OrderItemForm ref={formRef} product={product} item={item.fields} onUpdateItem={setItem}
                                   onSaved={handleSave}/> : null}
                </Paper>
              </Grid>
              <Grid className={classes.column} item xs={6}>
                <Paper className={classes.helpText}>
                  <DraftHTML raw={product.helpText}/>
                  {sample ? <img src={sample} alt={product.name} className={classes.sample}/> : null}
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <div className={classes.floating}>
            {!product.onePerOrder ? (
              <Fab color="secondary" variant="extended" onClick={() => {
                saveRedirect.current = false;
                formRef.current.save();
              }}>
                <AddIcon className={classes.floatingButtonIcon}/>Save and Add Another
              </Fab>
            ) : null}
            <Fab color="primary" variant="extended" onClick={() => {
              saveRedirect.current = true;
              formRef.current.save();
            }}>
              <AddIcon className={classes.floatingButtonIcon}/>Save
            </Fab>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Product;
