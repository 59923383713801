import {SET_ITEMS, SET_LOCATIONS, SET_ORDER_LIST_URL} from '../reducers/customer';

export function setItems(items) {
  return {
    type: SET_ITEMS,
    items,
  };
}

export function setLocations(locations) {
  return {
    type: SET_LOCATIONS,
    locations
  };
}

export function setOrderListURL(url) {
  return {
    type: SET_ORDER_LIST_URL,
    url
  };
}
