import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {CircularProgress} from '@material-ui/core';
import useFormStyles from './styles';
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {OrderService} from '../../../services/OrderService';
import moment from 'moment';

const OrderForm = forwardRef(({order, onSaved}, ref) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [due, setDue] = useState(moment().startOf('day'));
  const [dueError, setDueError] = useState('');

  useEffect(() => {
    setDue(moment(order.due));
  }, [order]);

  const validateDue = (value) => {
    setDueError('');
    if (!value) {
      setDueError('Please select a due date');
      return false;
    }
    return true;
  }

  const handleDue = value => {
    setDue(value);
    validateDue(value);
  }

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!due) {
      setDueError('Please enter a due date')
    } else {
      setLoading(true);
      OrderService.getInstance().saveOrder({id: order.id, due: moment(due).format('YYYY-MM-DD')})
        .then(updated => {
          setLoading(false);
          onSaved(updated);
        })
        .catch(() => {
          setLoading(false);
          setErrorOpen(true);
        });
    }
  };

  useImperativeHandle(ref, () => ({
    saveOrder() {
      if (!loading) {
        onSubmit();
      }
    }
  }));

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress/></div>;
  }

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      {loadingOverlay}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.pane}>
          <KeyboardDatePicker
            fullWidth
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            margin="normal"
            id="due"
            label="Due Date"
            value={due}
            onChange={handleDue}
            error={!!dueError}
            helperText={!!dueError ? dueError : ''}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Save
          </Button>
        </div>
      </MuiPickersUtilsProvider>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem saving the order
        </Alert>
      </Snackbar>
    </form>
  );
});

export default OrderForm;
