import {API} from './API';
import * as qs from 'qs';

export class RoleService {
  static getInstance() {
    if (!RoleService.instance) {
      RoleService.instance = new RoleService();
    }
    return RoleService.instance;
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableRoles(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getRoles(query, page, pageSize);
  }

  async getRoles(query, page, pageSize) {
    return (await API.getConnection())
      .get('roles', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveRole(data) {
    let method = 'post';
    let url = 'roles';
    if (data.id) {
      method = 'put';
      url = `roles/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async getPermissions() {
    return (await API.getConnection())
      .get('roles/permissions')
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }
}
