import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    marginTop: theme.spacing(2),
    transition: '0.25s opacity ease-in-out',
    width: '100%',
  },
  img: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    flex: '0 0 260px',
    height: '260px',
    width: '260px',
  },

  body: {
    alignItems: 'flex-start',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`,

    '& h3': {
      margin: `0 0 ${theme.spacing(2)}px`,
      padding: 0,
    },

    '& p': {
      flex: 1,
      margin: 0,
      padding: 0,
      textAlign: 'left',
    }
  },

  controls: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    justifyContent: 'center',
    margin: `0 ${theme.spacing(2)}px 0 0`,

    '& li': {
      alignItems: 'center',
      backgroundColor: theme.palette.grey['200'],
      borderRadius: 20,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-start',
      margin: `${theme.spacing(0.5)}px 0`,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      transition: '0.25s background-color ease-in-out',

      '&:hover': {
        backgroundColor: theme.palette.grey['300'],
      }
    },
  },

  buttonIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const OrderItem = ({name, sample, fields, onEdit, onDelete}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.item}>
      {sample ? <div className={classes.img} style={{backgroundImage: `url(${sample})`}}/> : null}
      <div className={classes.body}>
        <h3>{name}</h3>
        {fields.map((field, index) => <p key={index}><strong>{field.name}:</strong> {field.value ?? '-'}</p>)}
      </div>
      {onEdit || onDelete ? (
        <ul className={classes.controls}>
          {onEdit ? (<li onClick={onEdit}>
            <EditIcon className={classes.buttonIcon}/>
            Edit
          </li>) : null}
          {onDelete ? (<li onClick={onDelete}>
            <DeleteIcon className={classes.buttonIcon}/>
            Delete
          </li>) : null}
        </ul>
      ) : null}
    </Paper>
  );
};

export default OrderItem;
