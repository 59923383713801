import React, {useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {OrderService} from '../../../services/OrderService';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import NoteForm from '../forms/NoteForm';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
  },
  notes: {
    flex: 1,
    position: 'relative',
  },
  note: {
    display: 'flex',
    flexDirection: 'column',
  },
  comments: {
    margin: 0,
    padding: 0,
  },
  user: {
    color: theme.palette.secondary.main,
    fontStyle: 'italic',
    fontWeight: '700',
    margin: `${theme.spacing(1)}px 0 0`,
    padding: 0,

    '& span': {
      color: theme.palette.text.primary,
    },
  },
}));

export default function OrderNotes({order}) {
  const classes = useStyles();
  const [noteOpen, setNoteOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  /** @type {({current: NoteForm})} */
  let noteFormRef = useRef();

  /** @type {({current: MaterialTable})} */
  let tableRef = useRef();

  const handleSaveNote = () => {
    noteFormRef.current.saveNote();
  };

  const handleSave = (note) => OrderService.getInstance().saveNote(order, note);

  const handleSaved = () => {
    setNoteOpen(false);
    tableRef.current && tableRef.current.onQueryChange(null);
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.notes}>
        <MaterialTable
          title="Notes"
          tableRef={tableRef}
          options={{
            debounceInterval: 500,
            emptyRowsWhenPaging: false,
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            search: false,
          }}
          components={{Container: props => <Paper elevation={0} {...props} />}}
          columns={[
            {title: 'Comments', field: 'comments', render: data => {
              return (
                <div className={classes.note}>
                  <p className={classes.comments}>{data.comments}</p>
                  <p className={classes.user}>{data.user.firstName} {data.user.lastName} <span>({moment(data.created).format('DD/MM/YYYY HH:mm')})</span></p>
                </div>
              )
            }, sorting: false},
          ]}
          data={query => new Promise(resolve => {
            OrderService.getInstance().getTableNotes(order, query).then(response => {
              resolve({
                data: response.items, page: response.page - 1, totalCount: response.total
              });
            }).catch(() => {
              setErrorOpen(true);
              resolve({data: [], page: 0, totalCount: 0});
            })
          })}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(null),
            },
            {
              icon: 'add',
              tooltip: 'Add New Comment',
              isFreeAction: true,
              onClick: () => setNoteOpen(true)
            }
          ]}
        />
      </Paper>
      <Dialog open={noteOpen} onClose={() => setNoteOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle id="form-dialog-title">Add New Comment</DialogTitle>
        <DialogContent>
          <NoteForm
            ref={noteFormRef}
            onSave={handleSave}
            onSaved={handleSaved} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNoteOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveNote} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>
    </div>
  );
}
