import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import {FormDialog} from '@raise.digital/management-ui';
import React, {useCallback, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useHistory} from 'react-router-dom';
import {reorder} from '../../../../utils';
import ConfirmationDialog from '../ConfirmationDialog';
import Spinner from '../Spinner';
import Item from './Item';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },

  overlay: {
    alignItems: 'flex-start',
    background: theme.palette.background.paper,
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    left: 0,
    opacity: 0,
    paddingRight: theme.spacing(2),
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    transition: '0.25s opacity ease-in-out'
  },

  loading: {
    opacity: 0.75,
    pointerEvents: 'all'
  },

  title: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`
  },

  items: {
    display: 'flex',
    flexDirection: 'column',
  },

  item: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    display: 'flex',
    flexDirection: 'column',
  },

  new: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    transition: '0.25s background-color ease-in-out',

    '& span': {
      paddingLeft: theme.spacing(1),
      textTransform: 'uppercase'
    },

    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  }
}));

const Orderable = (
  {
    title,
    items,
    onRenderNewForm,
    onRenderEditForm,
    editRoute,
    onReorder,
    onDelete,
    onSaved
  }
) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleSelect = useCallback((item) => {
    if (editRoute) {
      history.push(editRoute(item));
    } else {
      setSelectedItem(item);
      setShowEdit(true);
    }
  }, [history, editRoute]);

  const handleSaved = useCallback((saved) => {
    setShowNew(false);
    setShowEdit(false);
    onSaved(saved);
  }, [onSaved]);

  const handleShouldDelete = useCallback((field) => {
    setSelectedItem(field);
    setShowDelete(true);
  }, []);

  const handleDelete = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      onDelete(selectedItem).then((updated) => {
        setShowDelete(false);
        setLoading(false);
        setSelectedItem(null);
        onSaved(updated);
      }).catch(() => {
        setShowDelete(false);
        setLoading(false);
      });
    } else {
      setShowDelete(false);
    }
  }, [onDelete, selectedItem, onSaved]);

  const handleDragEnd = useCallback((result) => {
    const reordered = reorder([...items], result.source.index, result.destination.index);
    setLoading(true);
    onReorder(reordered.map(item => item.entity)).then((updated) => {
      setLoading(false);
      onSaved(updated);
    }).catch(() => {
      setLoading(false);
    });
  }, [items, onReorder, onSaved]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={classes.container}>
        <Box className={classes.title} padding={2}><Typography variant="h6">{title}</Typography></Box>
        <Droppable droppableId="droppable">
          {(provided) => (
            <>
              <div className={classes.items} {...provided.droppableProps} ref={provided.innerRef}>
                {items.length ? items.map((item, index) => (
                  <Draggable key={item.entity.id} draggableId={`${item.entity.id}`} index={index}>
                    {(provided) => (
                      <div
                        className={classes.item}
                        style={provided.draggableProps.style}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <Item
                          entity={item.entity}
                          attributes={item.attributes}
                          onSelect={handleSelect}
                          onDelete={handleShouldDelete}
                          handleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                )) : null}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
        <div className={classes.new} onClick={() => setShowNew(true)}>
          <AddIcon/><span>Add New</span>
        </div>
        <div className={[classes.overlay, loading ? classes.loading : ''].join(' ')}><Spinner/></div>
        {onRenderNewForm ? <FormDialog
          title="Add New"
          open={showNew}
          onClose={() => setShowNew(false)}
          render={(props) => onRenderNewForm({...props, onSaved: handleSaved})}
        /> : null}
        {onRenderEditForm ? <FormDialog
          title="Edit Field"
          open={showEdit}
          onClose={() => setShowEdit(false)}
          render={(props) => onRenderEditForm({...props, onSaved: handleSaved}, selectedItem)}
        /> : null}
        <ConfirmationDialog
          open={showDelete}
          title="Delete"
          message={selectedItem ? 'Are you sure you want to delete?' : ''}
          onClose={handleDelete}
        />
      </div>
    </DragDropContext>
  );
};

export default Orderable;
