import {Chip, IconButton} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/styles';
import React, {useCallback, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import BorderedField from '../../../../components/BorderedField';
import {reorder} from '../../../../utils';

const useStyles = makeStyles(((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,

    '& li': {
      margin: `${theme.spacing(1)}px 0 0 ${theme.spacing(1)}px`,
      padding: 0,
    }
  },

  add: {
    display: 'flex',

    '& > div': {
      flex: 1,
      paddingRight: theme.spacing(2),
    },

    '& textarea': {
      border: 'none',
      float: 'left',
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(2),
      width: '100%',

      '&:focus': {
        outline: 'none',
      }
    }
  },

  button: {
    flex: 0,
  }
})));

const Options = ({title = 'Options', options, onUpdate}) => {
  const classes = useStyles();
  const [add, setAdd] = useState('');

  const handleAdd = useCallback(() => {
    if (add) {
      const list = [...options];
      list.push(add);
      onUpdate([...list]);
      setAdd('');
    }
  }, [add, options, onUpdate]);

  const handleRemove = useCallback((index) => {
    const list = [...options];
    list.splice(index, 1);
    onUpdate([...list]);
  }, [options, onUpdate]);

  const handleDragEnd = useCallback((result) => {
    onUpdate(reorder([...options], result.source.index, result.destination.index));
  }, [options, onUpdate]);

  return (
    <BorderedField label={title}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul className={classes.chips} {...provided.droppableProps} ref={provided.innerRef}>
              {options.map((option, index) => (
                <Draggable key={option} draggableId={`${option}`} index={index}>
                  {(provided) => (
                    <li
                      key={index}
                      style={provided.draggableProps.style}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    ><Chip label={option} onDelete={() => handleRemove(index)}/></li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className={classes.add}>
        <div>
          <textarea value={add} onChange={e => setAdd(e.target.value)} rows={1}/>
        </div>
        <IconButton className={classes.button} onClick={handleAdd}><AddIcon/></IconButton>
      </div>
    </BorderedField>
  );
};

export default Options;
