import {Toolbar} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Alert from '@material-ui/lab/Alert';
import * as moment from 'moment';
import {reverse} from 'named-urls';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import routes from '../../../routes';
import {ProductService} from '../../../services/ProductService';
import {setProduct} from '../../../store/actions/products';
import ConfirmationDialog from '../components/ConfirmationDialog';
import ImageUpload from '../components/ImageUpload';
import ProductActivity from '../components/ProductActivity';
import ProductVersions from '../components/ProductVersions';
import QRCode from '../components/QRCode';
import ProductForm from '../forms/ProductForm';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  details: {
    marginTop: theme.spacing(1)
  },
  detailsColumn: {
    display: 'flex',
  },
  detailsPane: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  products: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
  imagesHeader: {
    color: theme.palette.action.active,
    margin: theme.spacing(2),
  },
  images: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  imageUpload: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    width: '25%',
  },
  summary: {
    display: 'flex',
    flex: 1,
  },
  summaryItem: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(2)}px 0`,
    textAlign: 'center',

    '& *': {
      color: theme.palette.text.secondary,
    },

    '& h3': {
      fontSize: '1em',
      margin: 0,
      padding: 0,
    },

    '& p': {
      fontFamily: 'Roboto Mono',
      fontSize: '2em',
      margin: `${theme.spacing(2)}px 0 0 0`,
      padding: 0,
    }
  },
  ok: {
    color: theme.palette.success.main,
  },
  under: {
    color: theme.palette.warning.main,
  },
  legacyCode: {
    wordBreak: 'break-all'
  },
  archived: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: '1.2em',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
  }
}));

export default function Product({history, match}) {
  const classes = useStyles();
  /** @type {({current: ProductForm})} */
  let formRef = useRef();

  /** @type {IProduct} */
  const product = useSelector(state => state.products.selected);

  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const dispatch = useDispatch();

  const loadProduct = useCallback(() => {
    const id = parseInt(match.params.id);
    if (!loading && (!product || product.id !== id)) {
      dispatch(setProduct(null));
      setLoading(true);
      ProductService.getInstance().getProduct(parseInt(match.params.id)).then(retrieved => {
        dispatch(setProduct(retrieved));
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
      });
    }
  }, [product, loading, match.params, dispatch]);

  useEffect(loadProduct, [product]);

  const handleSave = () => {
    formRef.current.saveProduct();
  };
  const handleSaved = updated => {
    setEditOpen(false);
    dispatch(setProduct(updated));
  };

  const handleArchive = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      ProductService.getInstance().archiveProduct(product).then(retrieved => {
        dispatch(setProduct(retrieved));
        setLoading(false);
        setArchiveOpen(false);
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
        setArchiveOpen(false);
      });
    } else {
      setArchiveOpen(false);
    }
  }, [product, dispatch]);

  const loadImage = async (image, resolve) => {
    if (product.hasSample) {
      ProductService.getInstance().sample(product)
        .then(data => resolve(data))
        .catch(() => resolve(null));
    } else {
      resolve(null);
    }
  };

  const uploadImage = async (image, file, resolve) => {
    ProductService.getInstance().uploadSample(product, file)
      .then(data => resolve(data))
      .catch(() => resolve(null));
  };

  let controls = null;
  if (loading) {
    controls = <CircularProgress/>;
  } else if (product) {
    controls = (
      <div>
        <IconButton color="inherit" onClick={() => history.push(reverse(routes.admin.products.orders, {id: product.id}))}>
          <LocalShippingIcon/>
        </IconButton>
        <IconButton color="inherit" onClick={() => setEditOpen(true)}>
          <EditIcon/>
        </IconButton>
        <IconButton color="inherit" edge="end" onClick={() => setArchiveOpen(true)}>
          {product.archived ? <UnarchiveIcon/> : <ArchiveIcon/>}
        </IconButton>
      </div>
    );
  }

  let details = null;
  let crumbs = [
    {title: 'Products', link: `${routes.admin.products.index}`}
  ];
  if (product) {
    if (product.archived) {
      crumbs.push({title: 'Archive', link: `${routes.admin.products.archive}`});
    }
    crumbs.push({title: product.name, link: ''});

    let status = classes.ok;
    if (product.buffer && product.quantity < product.buffer) {
      status = classes.under;
    }

    details = (
      <div>
        {product.archived ? (
          <div className={classes.archived}>
            This product has been archived and will therefore not be available for order.
          </div>
        ) : null}
        <Grid className={classes.details} container spacing={2}>
          <Grid className={classes.detailsColumn} item xs={4}>
            <Paper className={classes.detailsPane}>
              <List dense subheader={<ListSubheader>Product Details</ListSubheader>}>
                <ListItem>
                  <ListItemText
                    primary={product.name}
                    secondary="Name"
                  />
                </ListItem>
                {product.versions.length > 0 ? <ListItem>
                  <ListItemText
                    primary={product.versions[0].code}
                    secondary="Latest Version"
                  />
                </ListItem> : null}
                {product.location ? <ListItem
                  className={classes.link}
                  component={RouterLink}
                  to={reverse(`${routes.admin.locations.detail}`, {id: product.location.id})}
                >
                  <ListItemText
                    primary={product.location.name}
                    secondary="Location"
                  />
                </ListItem> : (
                  <ListItem
                    className={classes.link}>
                    <ListItemText
                      primary="-"
                      secondary="Location"
                    />
                  </ListItem>
                )}
                {product.customer ? <ListItem
                  className={classes.link}
                  component={RouterLink}
                  to={reverse(`${routes.admin.customers.detail}`, {id: product.customer.id})}
                >
                  <ListItemText
                    primary={product.customer.name}
                    secondary="Customer"
                  />
                </ListItem> : null}
                <ListItem>
                  <ListItemText
                    primary={product.description}
                    secondary="Description"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={moment(product.created).format('DD/MM/YYYY HH:mm')}
                    secondary="Created"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={moment(product.updated).format('DD/MM/YYYY HH:mm')}
                    secondary="Last Updated"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid className={classes.detailsColumn} item xs={4}>
            <Paper className={classes.detailsPane}>
              <Typography className={classes.imagesHeader} variant="subtitle2">Stock Details</Typography>
              <div className={classes.summary}>
                <div className={classes.summaryItem}>
                  <h3>Quantity in Stock</h3>
                  <p className={status}>{product.quantity}</p>
                </div>
                <div className={classes.summaryItem}>
                  <h3>Buffer</h3>
                  <p>{product.buffer ? product.buffer : '-'}</p>
                </div>
              </div>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary={product.packedIn}
                    secondary="Packed In Quantity"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid className={classes.detailsColumn} item xs={4}>
            <Paper className={classes.detailsPane}>
              <List dense subheader={<ListSubheader>QR Code</ListSubheader>}>
                <ListItem>
                  <ListItemText
                    primary={<QRCode/>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className={classes.legacyCode}
                    primary={product.qr ? product.qr : '-'}
                    secondary="Legacy Code ID"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid className={classes.details} container spacing={2}>
          <Grid className={classes.detailsColumn} item xs={12}>
            <Paper className={classes.detailsPane}>
              <Typography className={classes.imagesHeader} variant="subtitle2">Images</Typography>
              <div className={classes.images}>
                {['Sample'].map(i =>
                  <div key={i} className={classes.imageUpload}>
                    <ImageUpload
                      title={i}
                      prompt="Drop an image here or click to select one from your computer"
                      load={async resolve => loadImage(i.toLowerCase(), resolve)}
                      onUpload={async (file, resolve) => uploadImage(i.toLowerCase(), file, resolve)}
                    />
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Dialog open={editOpen} onClose={() => setEditOpen(false)} fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Edit Product</DialogTitle>
          <DialogContent>
            <ProductForm
              ref={formRef}
              new={false}
              onSaved={handleSaved}
              product={product}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmationDialog
          open={archiveOpen}
          title={product.archived ? 'Unarchive Product' : 'Archive Product'}
          message={`Are you sure you want to ${product.archived ? 'unarchive' : 'archive'} this product?`}
          onClose={handleArchive}
        />
        <ProductVersions product={product} loading={loading} setLoading={setLoading}
                         onUpdate={updated => dispatch(setProduct(updated))}/>
        <ProductActivity product={product} loading={loading} setLoading={setLoading}
                         onUpdate={updated => dispatch(setProduct(updated))}/>
      </div>
    );
  }

  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          {crumbs.map((crumb, index) => (
            crumb.link ? (
              <Link key={index} component={RouterLink} color="textPrimary" variant="body2"
                    to={crumb.link}>{crumb.title}</Link>
            ) : (
              <Typography key={index} color="textPrimary" variant="body2">{crumb.title}</Typography>
            )
          ))}
        </Breadcrumbs>
      </Box>
      <Paper>
        <Toolbar>
          <Typography className={classes.title} component="h2" variant="h5">Product</Typography>
          {controls}
        </Toolbar>
      </Paper>
      {details}
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Sorry the product could not be accessed
        </Alert>
      </Snackbar>
    </div>
  );
}
