import {BaseForm} from '@raise.digital/management-ui';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const UserForm = forwardRef(({user, onSaved, administrators = false}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={user}
      type="user"
      fieldsComponent={props => <Fields administrators={administrators} {...props}/>}
      onSave={({roleId, ...update}) => services.user.saveUser({roleId: roleId ? parseInt(roleId) : null, ...update})}
      onSaved={onSaved}
    />
  );
});

export default UserForm;
