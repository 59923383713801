import {makeStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },

  cell: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },

  handle: {
    cursor: 'pointer'
  },

  attribute: {
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
    flex: 1,
    justifyContent: 'flex-start'
  },

  button: {
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
    cursor: 'pointer'
  }
}));

const Item = ({entity, attributes = [], onSelect, onDelete, handleProps}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={[classes.cell, classes.handle].join(' ')} {...handleProps}><DragIndicatorIcon/></div>
      {attributes.map((attribute, index) => (
        <div key={index} className={[classes.cell, classes.attribute].join(' ')}>{attribute}</div>
      ))}
      <div className={[classes.cell, classes.button].join(' ')} onClick={() => onSelect(entity)}><EditIcon/></div>
      <div className={[classes.cell, classes.button].join(' ')} onClick={() => onDelete(entity)}><DeleteIcon/></div>
    </div>
  );
};

export default Item;
