import React, {useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import {ProductService} from '../../../services/ProductService';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ProductVersionForm from '../forms/ProductVersionForm';
import StockMovementForm from '../forms/StockMovementForm';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  items: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  obsolete: {
    color: theme.palette.error.main,
  },
  correction: {
    color: theme.palette.secondary.main,
  },
  received: {
    color: theme.palette.success.main,
  },
  sent: {
    color: theme.palette.warning.main,
  },
  actions: {
    display: 'flex',
  }
}));

export default function ProductVersions({product, loading, setLoading, onUpdate}) {
  const classes = useStyles();

  const [versions, setVersions] = useState([]);

  const [newOpen, setNewOpen] = useState(false);
  /** @type {({current: ProductVersionForm})} */
  let versionFormRef = useRef();
  /** @type {({current: StockMovementForm})} */
  let activityFormRef = useRef();

  const [action, setAction] = useState({direction: '', version: /** @type {IProductVersion} */ null, correction: false});

  const clearAction = () => {
    setAction({direction: '', version: /** @type {IProductVersion} */ null, correction: false});
  }

  useEffect(() => {
    setVersions(product.versions);
  }, [product]);

  const handleNewVersion = () => {
    versionFormRef.current.saveVersion();
  };
  const handleNewVersionCreated = updated => {
    setNewOpen(false);
    onUpdate(updated);
  };

  const handleLogActivity = () => {
    activityFormRef.current.saveActivity();
  };
  const handleActivityLogged = updated => {
    clearAction();
    onUpdate(updated);
  };

  const handleObsolete = v => {
    setLoading(true);
    ProductService.getInstance().obsoleteVersion(product, v).then(updated => {
      setLoading(false);
      onUpdate(updated);
    }).catch(() => {
      setLoading(false);
    })
  }

  let loadingOverlay = null
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress/></div>;
  }

  return (
    <div>
      <Paper className={classes.items}>
        <MaterialTable
          title="Versions"
          options={{
            debounceInterval: 500,
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [50, 100, 250],
            search: false,
          }}
          components={{Container: props => <Paper elevation={0} {...props} />}}
          columns={[
            {title: 'Code', field: 'product', render: data => data.code, sorting: false},
            {title: 'Quantity', field: 'quantity', render: data => Math.abs(data.quantity), sorting: false},
            {title: 'Created', field: 'due', render: data => moment(data.created).format('DD/MM/YYYY HH:mm'), sorting: false},
            {title: 'Obsolete', field: 'obsoleteAt', render: data => data.obsoleteAt ? moment(data.obsoleteAt).format('DD/MM/YYYY HH:mm') : '-', sorting: false},
            {
              title: '', field: 'actions', render: v => {
                const actions = [
                  <IconButton onClick={() => setAction({direction: 'in', version: v, correction: true})}><EditIcon className={classes.correction} /></IconButton>,
                  <IconButton onClick={() => setAction({direction: 'in', version: v, correction: false})}><CallReceivedIcon className={classes.received} /></IconButton>
                ];
                if (v.quantity > 0) {
                  actions.push(<IconButton onClick={() => setAction({direction: 'out', version: v, correction: false})}><CallMadeIcon className={classes.sent} /></IconButton>);
                }
                if (!v.obsoleteAt) {
                  actions.push(<IconButton onClick={() => handleObsolete(v)}><EventBusyIcon className={classes.obsolete}/></IconButton>);
                }
                return (
                  <div className={classes.actions}>{actions.map((a, i) => <div key={i}>{a}</div>)}</div>
                );
              }
            }
          ]}
          data={versions}
          actions={[{
            icon: 'add',
            tooltip: 'Add New Version',
            isFreeAction: true,
            onClick: () => setNewOpen(true)
          }]}
        />
      </Paper>
      {loadingOverlay}
      <Dialog open={newOpen} onClose={() => setNewOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle id="form-dialog-title">Create New Version</DialogTitle>
        <DialogContent>
          <ProductVersionForm
            ref={versionFormRef}
            onSaved={handleNewVersionCreated}
            product={product} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNewVersion} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!action.direction} onClose={() => clearAction()} fullWidth maxWidth="xs">
        <DialogTitle id="form-dialog-title">{action.correction ? 'Stock Correction' : (action.direction === 'out' ? 'Check-out' : 'Check-in')}</DialogTitle>
        <DialogContent>
          {action.direction ? <StockMovementForm
            ref={activityFormRef}
            onSaved={handleActivityLogged}
            product={product}
            version={action.version}
            checkIn={action.direction === 'in'}
            correction={action.correction} /> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => clearAction()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogActivity} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
