import {Checkbox, Paper} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import {makeStyles} from '@material-ui/styles';
import {ConfirmationDialog, dateTime, useDetail, useDialogs, useErrors} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import Wrapper from '../Wrapper';

const useStyles = makeStyles((theme) => ({

  nothing: {
    fontSize: '1.6em',
    padding: theme.spacing(4),
    textAlign: 'center',
    width: '100%',
  },

  items: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    margin: `${theme.spacing(2)}px 0 0`,
    overflow: 'hidden',
  },

  item: {
    borderTop: `2px solid ${theme.palette.background.paper}`,
    cursor: 'pointer',
    display: 'flex',
    padding: `${theme.spacing(1)}px 0`,

    '&:first-of-type': {
      borderTopWidth: 0,
    },

    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& i': {
        fontSize: '0.8em',
        opacity: 0.75,
      }
    },
  },

  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
  },

  checkbox: {
    flex: '0 0 50px',
    width: 50,
  },

  reference: {
    flex: '0 0 120px',
    width: 120
  },

  name: {
    flex: 1,
  },

  orderedBy: {
    flex: 2,

    '& > strong:first-of-type': {
      padding: theme.spacing(0, 0, 0.5),
    },
  },

  when: {
    flex: 1
  },

  details: {
    flex: 2,
  },

  floating: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),

    '& > *': {
      marginLeft: theme.spacing(2)
    },

    '& :first-child': {
      marginLeft: 0
    },
  },
}));

const Dispatch = () => {
  const classes = useStyles();
  const services = useContext(ServiceContext);

  const {openDialogs, toggleDialog} = useDialogs(['confirm']);
  const {errors, toggleError} = useErrors(useMemo(() => ({customer: 'Sorry the customer could not be accessed'}), []));

  const {
    entity: customer,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    useMemo(() => services.customer.getCustomer, [services]),
    false,
    useCallback((customer) => [
      {
        title: customer.name,
        link: reverse(routes.admin.customers.detail, {id: customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: customer.id})
      },
      {
        title: 'Orders',
        link: reverse(routes.admin.customers.portal.orders.index, {id: customer.id})
      },
      {title: 'Dispatch'}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('customer', displayError), [toggleError])
  );

  const initialised = useRef(false);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);

  const loadLocations = useCallback(() => {
    setLoading(true);
    services.portalOrder.getOrders({
      filter: {
        customer: customer.id,
        status: 'In Production'
      }
    }, 1, 1000).then(({items}) => {
      setSelected([]);
      const processed = [];
      items.forEach((item) => {
        let details = [];
        if (item.fields) {
          details = JSON.parse(item.fields).map(({Name, Value}) => ({
            name: Name,
            value: Value
          }));
        }
        item.details = details.splice(0, 3);
        item.remaining = details.length;
        processed.push(item);
      });
      setItems(processed);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [services, customer, setLoading]);

  const handleSelect = useCallback((id) => {
    const index = selected.indexOf(id);
    const updated = [...selected];
    if (index >= 0) {
      updated.splice(index, 1);
    } else {
      updated.push(id);
    }
    setSelected(updated);
  }, [selected]);

  useEffect(() => {
    if (customer && !initialised.current) {
      initialised.current = true;
      loadLocations();
    }
  }, [customer, loadLocations]);

  const handleDispatch = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      services.portalOrder.dispatchOrders(selected).then(() => {
        setLoading(false);
        loadLocations();
      }).catch(() => setLoading(false));
    }
    toggleDialog('confirm', false);
  }, [services, setLoading, toggleDialog, selected, loadLocations]);

  return (
    <Wrapper title="Dispatch" loading={loading} customer={customer} crumbs={crumbs} errors={errors}>
      {customer ? (
        <>
          {items.length < 1 && !loading ? (
            <p className={classes.nothing}>Nothing to Dispatch</p>
          ) : (
            <Paper className={classes.items}>
              {items.map((item, itemIndex) => {
                const isSelected = selected.indexOf(item.id) >= 0;
                return (
                  <div key={itemIndex}
                       className={[classes.item, isSelected ? classes.selected : ''].join(' ')}
                       onClick={() => handleSelect(item.id)}>
                    <span className={classes.checkbox}>
                      <span>
                        <Checkbox
                          color="primary"
                          checked={isSelected}
                          onChange={(e) => e.stopPropagation() || handleSelect(item.id)}
                        />
                      </span>
                    </span>
                    <span className={classes.reference}>{item.reference}</span>
                    <span className={classes.name}>{item.name}</span>
                    <span className={classes.when}>{dateTime(item.created)}</span>
                    <span className={classes.orderedBy}>
                      <strong>{item.order?.location?.name}</strong>
                      <strong>{item.order.orderedBy.name} - {item.order.orderedBy.email}</strong>
                      <span>{`${item.order.user.firstName} ${item.order.user.lastName}`}</span>
                    </span>
                    <span className={classes.details}>
                      {item.details.map((field, index) => (
                        <span key={index}><strong>{field.name}:</strong> {field.value ?? '-'}</span>
                      ))}
                      {item.remaining ?
                        <i>{item.remaining} other detail{item.remaining === 1 ? '' : 's'} hidden</i> : null}
                    </span>
                  </div>
                );
              })}
            </Paper>
          )}
          <div className={classes.floating}>
            {selected.length > 0 ? (
              <Fab color="primary" variant="extended" onClick={() => toggleDialog('confirm', true)}>
                Dispatch {selected.length} item{selected.length === 1 ? '' : 's'}
              </Fab>
            ) : null}
          </div>
          <ConfirmationDialog
            title="Dispatch Items"
            message={`Are you sure you want to dispatch this ${selected.length} item${selected.length === 1 ? '' : 's'}`}
            open={openDialogs['confirm']}
            onClose={handleDispatch}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export default Dispatch;
