import * as React from 'react';
import {forwardRef, useImperativeHandle, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {CircularProgress} from '@material-ui/core';
import useFormStyles from './styles';
import {ProductService} from '../../../services/ProductService';

const ProductVersionForm = forwardRef(({product, onSaved}, ref) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (code === '') {
      setCodeError('Please enter a version code')
    } else {
      setLoading(true);
      ProductService.getInstance().newVersion(product, code)
        .then(updated => {
          setLoading(false);
          onSaved(updated);
        })
        .catch(() => {
          setLoading(false);
          setErrorOpen(true);
        });
    }
  };

  useImperativeHandle(ref, () => ({
    saveVersion() {
      if (!loading) {
        onSubmit();
      }
    }
  }));

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress/></div>;
  }

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      {loadingOverlay}
      <div className={classes.pane}>
        <TextField
          fullWidth
          name="code"
          value={code}
          onChange={event => setCode(event.target.value)}
          error={!!codeError}
          helperText={codeError}
          variant="outlined"
          margin="normal"
          required
          id="code"
          label="Version Code"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Save
        </Button>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem saving the version
        </Alert>
      </Snackbar>
    </form>
  );
});

export default ProductVersionForm;
