import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDetail,
  useDialogs,
  useErrors
} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import ProductsTable from '../../components/ProductsTable';
import CustomerForm from '../../forms/CustomerForm';
import Wrapper from './Wrapper';

export default function Customer({archive = false}) {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({
    customer: 'Sorry the customer could not be accessed',
    products: 'Sorry the customer\'s products could not be loaded'
  }), []));

  const {
    entity: customer,
    setEntity: setCustomer,
    loadEntity: loadCustomer,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.customer.getCustomer,
    archive,
    useCallback((customer) => [{title: customer.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('customer', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setCustomer(updated);
    } else if (customer?.id) {
      loadCustomer(customer.id);
    }
  }, [toggleDialog, setCustomer, loadCustomer, customer]);

  return (
    <Wrapper title="Customer" titleControls={customer ? [
      {
        onClick: () => history.push(reverse(routes.admin.customers.portal.index, {id: customer.id})),
        icon: <VpnKeyIcon/>
      }
    ] : []} loading={loading} customer={customer} crumbs={crumbs} errors={errors}>
      {customer?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Customer Details"
              actions={[
                {title: 'Edit Customer', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)}
              ]}
              details={[
                {title: 'Name', value: customer.name}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Customer"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <CustomerForm
                        {...props}
                        customer={customer}
                        onSaved={handleSaved}/>
                    )}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(customer.created)},
                {title: 'Last Updated', value: dateTime(customer.updated)}
              ]}
            />
          ]}/>

          <Box>
            <ProductsTable
              loadProducts={(query, resolve) => {
                services.product.getTableProducts(query, {customer: customer.id})
                  .then(response => {
                    resolve({
                      data: response.items, page: response.page - 1, totalCount: response.total
                    });
                  }).catch(() => {
                  toggleError('products', true);
                  resolve({data: [], page: 0, totalCount: 0});
                });
              }}
              loading={loading}
              setLoading={setLoading}
            />
          </Box>
        </>
      ) : null}
    </Wrapper>
  );
}
