import {Panes, TextField} from '@raise.digital/management-ui';
import React, {useCallback} from 'react';
import Options from '../PersonalisedProductFieldForm/Options';

export default function Fields(
  {
    pod = {},
    multiple = false,
    references,
    onReferenceUpdate,
    prefix = '',
    multiplePanes = true,
    children
  }) {

  const handleReferenceUpdate = useCallback((updated) => {
    const sanitised = [];
    updated.forEach(top => {
      top.split(',').forEach(middle => {
        middle.split('\n').forEach(bottom => {
          bottom.split(' ').forEach(under => {
            sanitised.push(under);
          });
        });
      })
    });
    onReferenceUpdate(sanitised.map(r => r.trim()).filter(r => !!r).filter((r, pos, self) => (
      self.indexOf(r) === pos
    )));
  }, [onReferenceUpdate]);

  return (
    <Panes
      entity={pod}
      prefix={prefix}
      panes={[
        {
          title: 'Recipient Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Recipient's Name"
              rules={{required: 'Please enter a name'}}
            />,
          ]
        }, multiple ? {
          title: 'Orders Delivered',
          fields: [
            <Options title="Order References" options={references} onUpdate={handleReferenceUpdate} />
          ]
        } : {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Proof of Delivery'}
    >{children}</Panes>
  );
}