import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => {
  const core = {
    fontWeight: '700',
    margin: 0,
    padding: 0
  };
  return {
    yes: {
      ...core,
      color: theme.palette.success.main
    },
    no: {
      ...core,
      color: theme.palette.error.main
    }
  };
});

const YesNo = ({value}) => {
  const classes = useStyles();
  return value ? (
    <p className={classes.yes}>Yes</p>
  ) : (
    <p className={classes.no}>No</p>
  );
};

export default YesNo;