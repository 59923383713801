import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import {makeStyles} from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import {Breadcrumbs, FormDialog, Title, useDialogs} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import OrderItem from '../../../components/OrderItem';
import {ServiceContext} from '../../../components/Services';
import routes from '../../../routes';
import {setLoading} from '../../../store/actions/general';
import Products from '../components/Products';
import PlaceOrderForm from '../forms/PlaceOrderForm';
import withOrderItemsAndProducts from '../hoc/withOrderItemsAndProducts';
import withPersonalisedProducts from '../hoc/withPersonalisedProducts';

const useStyles = makeStyles(theme => ({
  floating: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),
    transition: '0.25s opacity ease-in-out',

    '& > *': {
      marginLeft: theme.spacing(2)
    },

    '& :first-child': {
      marginLeft: 0
    },
  },
  floatingButtonIcon: {
    marginRight: theme.spacing(1),
  },

  ordering: {
    opacity: 0.5,
    pointerEvents: 'none',
  }
}));

const Order = ({items, itemsWithProducts, history}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const dispatch = useDispatch();

  const {openDialogs, toggleDialog} = useDialogs(['placeOrder']);
  const loading = useSelector(state => state['general'].loading);

  const handleDelete = useCallback((index) => {
    const amended = [...items];
    amended.splice(index, 1);
    dispatch(setLoading(true));
    services.portal.setItems(amended)
      .then(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  }, [services, dispatch, items]);

  const handleOrder = useCallback(() => {
    toggleDialog('placeOrder', true);
  }, [toggleDialog]);

  const handleOrdered = useCallback(() => {
    history.push(routes.customer.history.index);
  }, [history]);

  return (
    <>
      <Breadcrumbs crumbs={[{title: 'Place an Order'}]}/>
      <Title title="Check and Confirm Your Order" loading={loading}/>
      {itemsWithProducts.length > 0 ? itemsWithProducts.map((item, itemIndex) => (
        <OrderItem
          key={itemIndex}
          name={item.product.name}
          sample={item.product.sample}
          fields={item.Fields.map(f => ({name: f.Name, value: f.Value}))}
          onEdit={() => history.push(reverse(routes.customer.order.line, {id: item.product.id, index: itemIndex}))}
          onDelete={() => handleDelete(itemIndex)}
        />
      )) : loading ? (
        <Box padding={4} marginTop={2} textAlign="center" fontSize="1.4em">
          <p>Please wait, loading...</p>
        </Box>
      ) : (
        <Box padding={4} marginTop={2} textAlign="center" fontSize="1.4em">
          <p>Your order is currently empty, use the buttons below to add products to your order.</p>
        </Box>
      )}
      {loading ? null : (
        <Box marginTop={4} textAlign="left" fontSize="1.2em" fontWeight="bold">
          <p>Add to your order:</p>
        </Box>
      )}
      <Products/>
      {itemsWithProducts.length > 0 ?
        <div className={[classes.floating, openDialogs.placeOrder ? classes.ordering : ''].join(' ')}>
          <Fab color="primary" variant="extended" onClick={handleOrder}>
            <SendIcon className={classes.floatingButtonIcon}/>Submit Order
          </Fab>
        </div> : null}
      <FormDialog
        title="Place Order"
        open={openDialogs.placeOrder}
        onClose={() => toggleDialog('placeOrder', false)}
        render={(props) => <PlaceOrderForm {...props} order={{}} onSaved={handleOrdered}/>}
        buttons={{save: 'Place Order', cancel: 'Back'}}
      />
    </>
  );
};

export default withPersonalisedProducts(withOrderItemsAndProducts(Order));
