import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

export default function Dashboard() {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.container}>
        <Typography component="h2" variant="h5">Dashboard</Typography>
      </Paper>
    </div>
  );
}
