import {Toolbar, Tooltip} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Alert from '@material-ui/lab/Alert';
import * as moment from 'moment';
import {reverse} from 'named-urls';
import React, {useCallback, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {useOrder} from '../../../hooks';
import routes from '../../../routes';
import {OrderService} from '../../../services/OrderService';
import {setOrder} from '../../../store/actions/orders';
import ConfirmationDialog from '../components/ConfirmationDialog';
import OrderItems from '../components/OrderItems';
import OrderNotes from '../components/OrderNotes';
import OrderForm from '../forms/OrderForm';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  detailsHeader: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
  },
  details: {
    marginTop: theme.spacing(1)
  },
  detailsColumn: {
    display: 'flex',
  },
  detailsPane: {
    flexGrow: 1,
  },
  orders: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
  imagesHeader: {
    color: theme.palette.action.active,
    margin: theme.spacing(2),
  },
  images: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  imageUpload: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    width: '25%',
  },
  complete: {
    color: theme.palette.success.main
  },
  partial: {
    color: theme.palette.warning.main
  },
  pending: {
    color: theme.palette.error.main
  },
  archived: {
    color: theme.palette.grey['500']
  },
  orderArchived: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: '1.2em',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
  }
}));

export default function Order({match, history}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  /** @type {IOrder} */
  const order = useOrder(match, loading, setLoading, setErrorOpen);
  const dispatch = useDispatch();

  /** @type {({current: OrderForm})} */
  let formRef = useRef();

  const handleUpdate = updated => {
    dispatch(setOrder(updated));
  }

  const handleSave = () => {
    formRef.current.saveOrder();
  };
  const handleSaved = updated => {
    setEditOpen(false);
    dispatch(setOrder(updated));
  };

  const handleArchive = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      OrderService.getInstance().archiveOrder(order).then(retrieved => {
        dispatch(setOrder(retrieved));
        setLoading(false);
        setArchiveOpen(false);
      }).catch(() => {
        setLoading(false);
        setErrorOpen(true);
        setArchiveOpen(false);
      });
    } else {
      setArchiveOpen(false);
    }
  }, [order, dispatch]);

  let controls = null;
  if (loading) {
    controls = <CircularProgress/>;
  } else if (order) {
    controls = (
      <div>
        <Tooltip title="View Change History">
          <IconButton color="inherit"
                      onClick={() => history.push(reverse(routes.admin.orders.changes, {id: order.id}))}>
            <HistoryIcon/>
          </IconButton>
        </Tooltip>
        {order.status !== 'Complete' && !order.archived ? (
          <Tooltip title="Edit Order">
            <IconButton color="inherit"
                        onClick={() => history.push(reverse(routes.admin.orders.update, {id: order.id}))}>
              <EditIcon/>
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="Archive Order">
          <IconButton color="inherit" edge="end" onClick={() => setArchiveOpen(true)}>
            {order.archived ? <UnarchiveIcon/> : <ArchiveIcon/>}
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  let details = null;
  let crumb = null;
  if (order) {
    crumb = <Typography color="textPrimary" variant="body2">{order.reference}</Typography>;
    details = (
      <div>
        {order.archived ? (
          <div className={classes.orderArchived}>
            This order has been archived.
          </div>
        ) : null}
        <Grid className={classes.details} container spacing={2}>
          <Grid className={classes.detailsColumn} item xs={6}>
            <Paper className={classes.detailsPane}>
              <Typography className={classes.detailsHeader} variant="h6">Order Details</Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary={<strong className={classes[order.status.toLowerCase()]}>{order.status}</strong>}
                    secondary="Status"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={order.due ? moment(order.due).format('DD/MM/YYYY') : '-'}
                    secondary="Due Date"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={moment(order.created).format('DD/MM/YYYY HH:mm')}
                    secondary="Created"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={moment(order.updated).format('DD/MM/YYYY HH:mm')}
                    secondary="Last Updated"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid className={classes.detailsColumn} item xs={6}>
            <OrderNotes order={order}/>
          </Grid>
        </Grid>
        <OrderItems order={order} onUpdate={handleUpdate}/>
        <Dialog open={editOpen} onClose={() => setEditOpen(false)} fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Edit Order</DialogTitle>
          <DialogContent>
            <OrderForm
              ref={formRef}
              new={false}
              onSaved={handleSaved}
              order={order}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmationDialog
          open={archiveOpen}
          title={order.archived ? 'Unarchive Order' : 'Archive Order'}
          message={`Are you sure you want to ${order.archived ? 'unarchive' : 'archive'} this order?`}
          onClose={handleArchive}
        />
      </div>
    );
  }

  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="textPrimary" variant="body2"
                to={`${routes.admin.orders.index}`}>Orders</Link>
          {crumb}
        </Breadcrumbs>
      </Box>
      <Paper>
        <Toolbar>
          <Typography className={classes.title} component="h2" variant="h5">Order</Typography>
          {controls}
        </Toolbar>
      </Paper>
      {details}
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Sorry the order could not be accessed
        </Alert>
      </Snackbar>
    </div>
  );
}
