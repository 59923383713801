import {BaseForm} from '@raise.digital/management-ui';
import * as React from 'react';
import {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {useCustomerCategories} from '../../../../hooks';
import Fields from './Fields';

const PersonalisedProductForm = forwardRef(({customer, product, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  const [helpText, setHelpText] = useState('');
  const {categories, onSelectCategory} = useCustomerCategories(customer, product);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));


  useEffect(() => {
    setHelpText(product?.helpText ? product.helpText : '{}');
  }, [product]);


  return (
    <BaseForm
      ref={formRef}
      entity={product}
      type="product"
      fieldsComponent={(props) => (
        <Fields
          {...props}
          helpText={helpText}
          onHelpTextChange={setHelpText}
          categories={categories}
          onCategoryChange={onSelectCategory}
        />
      )}
      onSave={(update) => (
        services.customer.savePersonalisedProduct(
          customer,
          {...update, helpText, categories: categories.filter(c => c.selected).map(c => c.value)}
        )
      )}
      onSaved={onSaved}
    />
  );
});

export default PersonalisedProductForm;
