import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import {Panes, prefixWithSeparator, TextField} from '@raise.digital/management-ui';
import React, {useCallback, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {loadPermissions} from '../../../../store/actions/roles';

const Fields = ({role = {}, prefix = '', multiplePanes = true, children}) => {
  const {setValue} = useFormContext();
  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState([]);
  const permissions = useSelector(state => state.roles.permissions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (permissions === null) {
      dispatch(loadPermissions());
    } else {
      const permissionGroups = [];
      permissions.sort((p1, p2) => (p1.group > p2.group) ? 1 : -1).forEach(permission => {
        const pair = {id: permission.id, title: permission.title};
        const index = permissionGroups.findIndex(g => g.title === permission.group);
        if (index >= 0) {
          permissionGroups[index].permissions.push(pair);
        } else {
          permissionGroups.push({
            title: permission.group,
            permissions: [pair]
          });
        }
      });
      setGroups(permissionGroups);
    }
  }, [permissions, dispatch]);

  const handleSelected = useCallback(event => {
    const id = parseInt(event.target.value);
    const updated = [...selected];
    const index = updated.indexOf(id);
    if (index >= 0) {
      updated.splice(index, 1);
    } else {
      updated.push(id)
    }
    setSelected(updated);
  }, [selected]);

  const handleReset = useCallback((subject) => {
    if (subject.permissions) {
      setSelected(subject.permissions.map(r => r.permissionId));
    }
    return subject;
  }, []);

  useEffect(() => {
    setValue(`${prefixWithSeparator(prefix)}permissions`, selected);
  }, [prefix, setValue, selected]);

  return (
    <Panes
      entity={role}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Role Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title'}}
            />
          ]
        }, {
          title: 'Permissions',
          fields: [
            <>
              {groups.map((group, groupIndex) => (
                <FormControl key={groupIndex} component="fieldset" fullWidth>
                  <FormLabel component="legend">{group.title}</FormLabel>
                  <FormGroup>
                    {group.permissions.map((permission, permissionIndex) => (
                      <FormControlLabel
                        key={permissionIndex}
                        control={<Checkbox
                          checked={selected.indexOf(permission.id) >= 0}
                          value={permission.id}
                          onChange={handleSelected}
                          name={permission.title}/>
                        }
                        label={permission.title}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              ))}
            </>
          ]
        }
      ]}
      title={multiplePanes ? null : 'Role Details'}
    >{children}</Panes>
  );
};

export default Fields;
