import * as React from 'react';
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {CircularProgress} from '@material-ui/core';
import useFormStyles from './styles';
import {ProductService} from '../../../services/ProductService';
import {OrderService} from '../../../services/OrderService';

const StockMovementForm = forwardRef(({version, /** @type {IOrderItem} */ item = null, onSaved, checkIn = true, correction = false}, ref) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [quantity, setQuantity] = useState('10');
  const [quantityError, setQuantityError] = useState('');
  const [comments, setComments] = useState('');
  const [commentsError, setCommentsError] = useState('');

  useEffect(() => {
    if (correction) {
      setQuantity(`${version.quantity}`);
    }
  }, [version, correction]);

  useEffect(() => {
    const value = parseInt(quantity);
    if (!checkIn && value > version.quantity) {
      setQuantity(`${version.quantity}`);
    } else if (value < 0) {
      setQuantity('0');
    } else if (checkIn && item != null) {
      const limit = item.quantity - item.checkedIn;
      if (value > limit) {
        setQuantity(`${limit}`);
      }
    }
  }, [checkIn, quantity, item, version]);

  const onSubmit = useCallback((e) => {
    if (e) {
      e.preventDefault();
    }
    let valid = true;
    if (quantity === '') {
      setQuantityError('Please enter a quantity')
      valid = false;
    }
    if (!comments && correction) {
      setCommentsError('Please enter some comments describing this activity');
      valid = false;
    }
    if (valid) {
      let actionQuantity = parseInt(quantity);
      actionQuantity = checkIn ? actionQuantity : 0 - actionQuantity;
      setLoading(true);
      (item == null
        ?
        ProductService.getInstance().logActivity(version, actionQuantity, comments, correction)
        :
        OrderService.getInstance().logActivity(item, actionQuantity, comments, correction))
        .then(updated => {
          setLoading(false);
          onSaved(updated);
        })
        .catch(() => {
          setLoading(false);
          setErrorOpen(true);
        });
    }
  }, [quantity, comments, version, item, checkIn, correction, onSaved]);

  useImperativeHandle(ref, () => ({
    saveActivity() {
      if (!loading) {
        onSubmit();
      }
    }
  }));

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress /></div>;
  }

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      {loadingOverlay}
      <div className={classes.pane}>
        <TextField
          fullWidth
          name="quantity"
          value={quantity}
          onChange={event => setQuantity(event.target.value)}
          error={!!quantityError}
          helperText={quantityError}
          variant="outlined"
          margin="normal"
          required
          id="quantity"
          label="Quantity"
          type="number"
        />
        <TextField
          fullWidth
          name="comments"
          value={comments}
          onChange={event => setComments(event.target.value)}
          error={!!commentsError}
          helperText={commentsError}
          variant="outlined"
          margin="normal"
          required={correction}
          id="comments"
          label="Comments"
          multiline
          rows={6}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Save
        </Button>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem saving the activity
        </Alert>
      </Snackbar>
    </form>
  );
});

export default StockMovementForm;
