import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  no: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  yes: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

export default function ConfirmationDialog({open, title, message, noLabel, yesLabel, onClose}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} variant="contained" color="primary" className={classes.no}>
            {noLabel ? noLabel : 'No'}
          </Button>
          <Button onClick={() => onClose(true)} variant="contained" color="primary" className={classes.yes} autoFocus>
            {yesLabel ? yesLabel : 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
