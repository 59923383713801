import {useTheme} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  Breadcrumbs,
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  ErrorPopup,
  Title,
  useDetail,
  useDialogs,
  useErrors
} from '@raise.digital/management-ui';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Status from '../../../../components/Status';
import Text from '../../../../components/Text';
import routes from '../../../../routes';
import {statusColours} from '../../../../utils';

const Order = () => {
  const services = useContext(ServiceContext);
  const theme = useTheme();
  const user = useSelector(state => state['auth'].user);
  const returnURL = useSelector(state => state['customer'].orderListURL);
  const [userActions, setUserActions] = useState([]);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({order: 'Sorry the order could not be accessed'}), []));

  const {
    entity: item,
    setEntity: setItem,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.portal.getOrder,
    false,
    useCallback((order) => [
      {title: order.reference}
    ], []),
    useMemo(() => [{title: 'Order History', link: routes.customer.history.index}], []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('order', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['approve']);

  useEffect(() => {
    const actions = [];
    if (user && item) {
      if (!user.location) {
        if (!item.approved) {
          actions.push({
            label: 'Approve',
            colour: statusColours.approved,
            onClick: () => toggleDialog('approve', true)
          });
        }
      }
    }
    setUserActions(actions);
  }, [user, item, toggleDialog]);

  const handleApprove = useCallback((approved) => {
    if (approved) {
      setLoading(true);
      services.portal.approveOrder(item.id).then(updated => {
        setLoading(false);
        setItem(updated);
        history.push(returnURL ? returnURL : routes.customer.history.index);
      }).catch(() => setLoading(false));
    }
    toggleDialog('approve', false);
  }, [services, toggleDialog, setLoading, item, setItem, history, returnURL]);

  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={item ? item.reference : ''} loading={loading}/>
      {item ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Order Details"
              details={[
                {title: 'Reference', value: item.reference},
                {title: 'Ordered by', value: `${item.order.orderedBy.name} (${item.order.orderedBy.email})`},
                {title: 'User', value: `${item.order.user.firstName} ${item.order.user.lastName}`},
                {title: 'Date/Time Ordered', value: dateTime(item.created)},
                {title: 'Comments', value: item.order && item.order.comments ? <Text text={item.order.comments}/> : '-'}
              ]}
            />,

            <DetailPane
              title={item.name}
              details={JSON.parse(item.fields).map(field => ({title: field.Name, value: field.Value}))}
            />,

            <DetailPane
              title="Order Status"
              details={[
                {title: 'Status', value: <Status status={item.status}/>},
                ...(userActions.length > 0 ? [
                  {
                    title: 'Actions', value: (
                      <>
                        {userActions.map((action, index) => (
                          <Button
                            key={index}
                            variant="contained"
                            style={{
                              backgroundColor: action.colour,
                              color: theme.palette.getContrastText(action.colour),
                              marginBottom: theme.spacing(1),
                              marginRight: theme.spacing(1)
                            }}
                            onClick={action.onClick}>{action.label}</Button>
                        ))}
                      </>
                    )
                  }
                ] : []),
                ...(item.approved ? [
                  {title: 'Date/Time Approved', value: dateTime(item.approved)},
                  {
                    title: 'Approved by',
                    value: item.approvedBy ? `${item.approvedBy.firstName} ${item.approvedBy.lastName}` : 'System'
                  }
                ] : []),
                ...(item.delivered ? [
                  {title: 'Delivered To', value: item.deliveredTo ? item.deliveredTo : 'Unknown'}
                ] : [])
              ]}
              dialogs={[
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Approve Order"
                    message="Are you sure you want to approve this order?"
                    open={openDialogs['approve'] ?? false}
                    onClose={handleApprove}
                  />
                )
              ]}
            />

          ]}/>

        </>
      ) : null}
      <ErrorPopup errors={['order']} messages={errors}/>
    </>
  );
};

export default Order;
