import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {Message} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {
  ColouredLabel,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDetail,
  useDialogs,
  useErrors
} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import Orderable from '../../../components/Orderable';
import CustomerBrandingForm from '../../../forms/CustomerBrandingForm';
import PersonalisedProductForm from '../../../forms/PersonalisedProductForm';
import Table from '../../Users/Table';
import Wrapper from '../Wrapper';
import Categories from './Categories';
import Image from './Image';
import Locations from './Locations';

export default function Portal() {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({contact: 'Sorry the customer could not be accessed'}), []));

  const {
    entity: customer,
    setEntity: setCustomer,
    loadEntity: loadCustomer,
    crumbs,
    loading
  } = useDetail(
    useMemo(() => services.customer.getCustomer, [services]),
    false,
    useCallback((customer) => [
      {
        title: customer.name,
        link: reverse(routes.admin.customers.detail, {id: customer.id})
      },
      {title: 'Portal'}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('customer', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['branding']);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    services.customer.getPersonalisedProducts(customer).then(setProducts).catch(() => setProducts([]));
  }, [services, customer]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('branding', false);
    if (updated) {
      setCustomer(updated);
    } else if (customer?.id) {
      loadCustomer(customer.id);
    }
  }, [toggleDialog, setCustomer, loadCustomer, customer]);

  return (
    <Wrapper title="Portal" titleControls={customer ? [
      {
        title: 'Portal Support Messages',
        onClick: () => history.push(reverse(routes.admin.customers.portal.messages, {id: customer.id})),
        icon: <Message/>
      },
      {
        title: 'Portal Password Reminders',
        onClick: () => history.push(reverse(routes.admin.customers.portal.passwordReminders, {id: customer.id})),
        icon: <VerifiedUserIcon/>
      },
      {
        title: 'Portal Orders',
        onClick: () => history.push(reverse(routes.admin.customers.portal.orders.index, {id: customer.id})),
        icon: <LocalShippingIcon/>
      }
    ] : []} loading={loading} customer={customer} crumbs={crumbs} errors={errors}>
      {customer ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Portal Branding"
              actions={[
                {title: 'Edit Branding', icon: <EditIcon/>, onClick: () => toggleDialog('branding', true)}
              ]}
              details={[
                {title: 'Domain', value: customer.domain ?? '-'},
                {title: 'Email Suffix', value: customer.emailSuffix ?? '-'},
                {title: 'Email From Address', value: customer.emailSender ?? '-'},
                {title: 'Logo', value: <Image customer={customer} type="logo" width={200}/>},
                {title: 'Email Logo', value: <Image customer={customer} type="emailLogo" width={200}/>},
                {title: 'Icon', value: <Image customer={customer} type="icon" width={16}/>}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Branding"
                    open={openDialogs['branding'] ?? false}
                    onClose={() => toggleDialog('branding', false)}
                    render={(props) => (
                      <CustomerBrandingForm
                        {...props}
                        customer={customer}
                        onSaved={handleSaved}/>
                    )}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Portal Colours"
              actions={[
                {title: 'Edit Colours', icon: <EditIcon/>, onClick: () => toggleDialog('branding', true)}
              ]}
              details={[
                ['primary', 'main'],
                ['primary', 'contrast'],
                ['secondary', 'main'],
                ['secondary', 'contrast']
              ].map((colour) => {
                const variant = `${colour[1].charAt(0).toUpperCase()}${colour[1].slice(1)}`;
                const title = `${colour[0].charAt(0).toUpperCase()}${colour[0].slice(1)} Colour - ${variant}`;
                const value = customer[`${colour[0]}Colour${variant}`];
                return {
                  title,
                  value: value ? <Box paddingBottom={1}><ColouredLabel label={value} colour={value}/></Box> : '-'
                };
              })}
            />,
          ]}/>
          <Paper marginTop={2} component={Box}>
            <Categories customer={customer}/>
          </Paper>
          <Paper marginTop={2} component={Box} overflow="hidden">
            <Orderable
              title="Products"
              items={products.map(product => ({
                entity: product,
                attributes: [
                  [
                    product.name,
                    product.unavailable ? 'Unavailable' : '',
                    product.onePerOrder ? 'One Per Order' : '',
                    product.canReOrder ? 'Re-orderable' : '',
                    product.isHeld ? 'Held' : '',
                    product.exportTab ? 'Separate Export Tab' : ''
                  ].filter(n => !!n).join(' | '),
                  product.categories.map(c => c.name).join(', ')
                ]
              }))}
              onRenderNewForm={(props) => (
                <PersonalisedProductForm
                  {...props}
                  customer={customer}
                  product={{}}/>
              )}
              editRoute={(selected) => reverse(routes.admin.customers.portal.product, {
                customerID: customer.id,
                id: selected.id
              })}
              onReorder={(reordered) => services.customer.reorderPersonalisedProducts(customer, reordered)}
              onDelete={(selected) => services.customer.deletePersonalisedProduct(selected)}
              onSaved={(updated) => setProducts(updated)}
            />
          </Paper>
          <Paper marginTop={2} component={Box}>
            <Locations customer={customer}/>
          </Paper>
          <Paper marginTop={2} component={Box}>
            <Table title="Administrators" customer={customer}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
}
