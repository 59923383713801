import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {Breadcrumbs, DataTable} from '@raise.digital/management-ui';
import * as React from 'react';
import {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import RoleForm from '../../forms/RoleForm';

const useStyles = makeStyles(() => ({
  permissions: {
    margin: 0,
    padding: 0,
  }
}));

const Roles = () => {
  const classes = useStyles();
  const services = useContext(ServiceContext);

  return (
    <>
      <Breadcrumbs crumbs={[{title: 'Administrators', link: routes.admin.administrators.index}, {title: 'Roles'}]}/>
      <Paper>
        <DataTable
          title="Roles"
          columns={[
            {title: 'Title', field: 'title', sorting: false},
            {
              title: 'Permissions', field: 'permissions', render: data => (
                <ul className={classes.permissions}>
                  {data.permissions.map((link, index) => <li
                    key={index}>{link.permission.group} - {link.permission.title}</li>)}
                </ul>
              ), sorting: false
            }
          ]}
          loadData={query => new Promise((resolve, reject) => {
            services.role.getTableRoles(query)
              .then(response => {
                resolve({
                  data: response.items, page: response.page - 1, totalCount: response.total
                });
              }).catch(() => {
              reject();
            });
          })}
          newForm={{
            title: 'Add New Role',
            render: (props) => <RoleForm role={{}} {...props}/>
          }}
          editForm={{
            render: (role, props) => <RoleForm role={role} {...props}/>
          }}
        />
      </Paper>
    </>
  );
}

export default Roles;
