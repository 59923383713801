import {IconButton} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/styles';
import React, {useCallback, useEffect, useState} from 'react';
import BorderedField from '../../../../components/BorderedField';

const useStyles = makeStyles(((theme) => {
  const border = `1px solid ${theme.palette.background.paper}`;
  return {
    conditions: {
      borderBottom: border,
      display: 'flex',
      flexDirection: 'column',
      listStyle: 'none',
      margin: `${theme.spacing(2)}px 0 0`,
      padding: 0,
      width: '100%',

      '& > li': {
        background: theme.palette.grey['100'],
        borderTop: border,
        display: 'flex'
      },
    },

    add: {
      background: theme.palette.grey['100'],
      borderTop: border,
      display: 'flex',
      margin: `${theme.spacing(2)}px 0 0`,
      width: '100%',
    },

    field: {
      alignItems: 'center',
      background: 'none',
      border: 'none',
      borderRight: border,
      display: 'flex',
      flex: 1,
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
    },

    equals: {
      alignItems: 'center',
      borderRight: border,
      display: 'flex',
      flex: '0 0 48px',
      justifyContent: 'center',
      width: '48px',
    },

    value: {
      alignItems: 'center',
      background: 'none',
      border: 'none',
      borderRight: border,
      display: 'flex',
      flex: 1,
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
    }
  };
}));

const Conditions = ({product, field, conditions, onUpdate}) => {
  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    setFields(product.fields.filter(f => f.id !== field.id));
  }, [product, field, conditions]);

  const handleRemove = useCallback((index) => {
    const updated = [...conditions];
    updated.splice(index, 1);
    onUpdate(updated);
  }, [conditions, onUpdate]);

  const handleAdd = useCallback(() => {
    const index = fields.findIndex(f => `${f.id}` === selectedField);
    if (index >= 0) {
      const toAdd = fields[index];
      onUpdate([
        ...conditions,
        {conditionFieldId: toAdd.id, conditionFieldName: toAdd.name, value}
      ]);
      setSelectedField('');
      setValue('');
    }
  }, [selectedField, value, fields, conditions, onUpdate]);

  return (
    <BorderedField label="Conditions">
      {conditions.length > 0 ? (
        <ul className={classes.conditions}>
          {conditions.map((condition, index) => (
            <li key={index}>
              <span className={classes.field}>{condition.conditionFieldName}</span>
              <span className={classes.equals}>=</span>
              <span className={classes.value}>{condition.value}</span>
              <IconButton onClick={() => handleRemove(index)}><Delete/></IconButton>
            </li>
          ))}
        </ul>
      ) : null}
      {fields.length > 0 ? (
        <div className={classes.add}>
          <select className={classes.field} value={selectedField} onChange={e => setSelectedField(e.target.value)}>
            <option value="">Select a Field</option>
            {fields.map((f, index) => <option key={index} value={`${f.id}`}>{f.name}</option>)}
          </select>
          <span className={classes.equals}>=</span>
          <input className={classes.value} type="text" value={value} onChange={e => setValue(e.target.value)}/>
          <IconButton onClick={handleAdd}><AddIcon/></IconButton>
        </div>
      ) : null}
    </BorderedField>
  );
};

export default Conditions;