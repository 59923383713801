import {Breadcrumbs, ErrorPopup, Title} from '@raise.digital/management-ui';
import React from 'react';
import routes from '../../../../routes';

const Wrapper = ({title, loading, titleControls, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading} controls={titleControls}/>
      {children}
      <ErrorPopup errors={['customer']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Customers', link: routes.admin.customers.index}
];

export default Wrapper;
