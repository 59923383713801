import {Box, InputAdornment, InputLabel, MenuItem} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MatSelect from '@material-ui/core/Select';
import {Panes, Select, TextField} from '@raise.digital/management-ui';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {ServiceContext} from '../../../../components/Services';
import withOrderItemsAndProducts from '../../hoc/withOrderItemsAndProducts';
import withPersonalisedProducts from '../../hoc/withPersonalisedProducts';

const Fields = (
  {
    product,
    item = {},
    onUpdateItem,
    prefix = '',
    multiplePanes = false,
    itemsWithProducts,
    children
  }
) => {
  const {/** @type function */ watch} = useFormContext();
  const services = useContext(ServiceContext);
  const user = useSelector(state => state['auth'].user);
  const locations = useSelector(state => state['customer'].locations);
  const [fields, setFields] = useState([]);
  const [initial, setInitial] = useState(null);
  const [pastOrders, setPastOrders] = useState([]);
  const [reorders, setReorders] = useState([]);
  const [reorder, setReorder] = useState('');

  const state = watch();

  const handleShouldDisplay = useCallback((field, values) => {
    let display = false;
    for (let condition of field.conditions) {
      if (values[`field_${condition.conditionFieldId}`] === condition.value) {
        display = true;
        break;
      }
    }
    return display;
  }, []);

  useEffect(() => {
    if (product && product.canReOrder) {
      services.portal.getOrders({filter: {product: product.name}}, 1, 20)
        .then(({items}) => setPastOrders(items))
        .catch(() => setPastOrders([]));
    }
  }, [services, product]);

  const formatPastOrder = useCallback((order) => {
    let values = JSON.parse(order.fields).filter(f => !!f.Value).splice(0, 3).map(f => f.Value).join(' - ');
    if (values.length > 60) {
      values = `${values.substr(0, 60)}...`;
    }
    return values;
  }, []);

  useEffect(() => {
    if (product && product.canReOrder) {
      setReorders([
        ...itemsWithProducts.filter(i => i.product.canReOrder).map((i, index) => ({
          reference: `Pending #${index + 1}`,
          name: i.Name,
          fields: JSON.stringify(i.Fields)
        })),
        ...pastOrders
      ].sort((a, b) => formatPastOrder(a).localeCompare(formatPastOrder(b))));
    }
  }, [product, pastOrders, itemsWithProducts, formatPastOrder]);

  useEffect(() => {
    if (Object.keys(item).length < 1) {
      setReorder('');
    }
  }, [item]);

  const handleReorder = useCallback((reference) => {
    const order = reorders.find(o => o.reference === reference);
    if (order) {
      const fields = JSON.parse(order.fields);
      const updatedItem = {};
      for (let field of fields) {
        const index = product.fields.findIndex(f => f.name === field.Name);
        if (index >= 0) {
          updatedItem[`field_${product.fields[index].id}`] = field.Value;
        }
      }
      onUpdateItem({fields: updatedItem});
    } else {
      onUpdateItem({fields: {}});
    }
    setReorder(reference);
  }, [reorders, onUpdateItem, product]);

  useEffect(() => {
    if (locations !== null) {
      const inputs = [];
      const values = {};
      const validate = (message, additional = () => true) => ((value) => {
        if (`${value ?? ''}`.trim().length < 1) {
          return message;
        } else {
          const extra = additional(value);
          if (extra !== true) {
            return extra;
          }
        }
        return true;
      });
      for (let field of product.fields) {
        const name = `field_${field.id}`;
        let shouldDisplay = () => true;
        if (field.conditions && field.conditions.length > 0) {
          shouldDisplay = (values) => handleShouldDisplay(field, values);
        }
        const fieldProps = {};
        if (field.suffix) {
          fieldProps.InputProps = {
            endAdornment: <InputAdornment position="end">{field.suffix}</InputAdornment>
          };
        }
        if (field.defaultValue) {
          values[name] = field.defaultValue;
        }
        if (field.restricted && user.location) {
          fieldProps.disabled = true;
        }
        const props = {
          name,
          label: field.name,
          fieldProps
        };
        switch (field.fieldType) {
          case 'Dropdown':
            inputs.push({
              Component: Select,
              shouldDisplay,
              rules: {validate: validate('Please select a value')},
              props: {
                ...props,
                options: field.options.map((option) => ({title: option.value, value: option.value}))
              }
            });
            break;
          case 'Address':
            if (locations.length === 1) {
              inputs.push({
                Component: TextField,
                shouldDisplay,
                rules: {validate: validate('Please enter an address')},
                props: {
                  ...props,
                  fieldProps: {
                    ...fieldProps,
                    multiline: true,
                    rows: 5,
                  }
                },
              });
              values[name] = locations[0].address;
            } else {
              inputs.push({
                Component: Select,
                shouldDisplay,
                rules: {validate: validate('Please select an address')},
                props: {
                  ...props,
                  options: locations.map((location) => ({title: location.name, value: location.address}))
                }
              });
            }
            break;
          case 'Long Text':
            inputs.push({
              Component: TextField,
              shouldDisplay,
              rules: {validate: validate('Please complete this field')},
              props: {
                ...props,
                fieldProps: {
                  ...fieldProps,
                  multiline: true,
                  rows: 5,
                }
              }
            });
            break;
          case 'UK Telephone Number':
            inputs.push({
              Component: TextField,
              shouldDisplay,
              rules: {
                required: 'Please complete this field',
                rules: {
                  validate: validate('Please complete this field', (value) => {
                    if (`${value}`.length === 11) {
                      return true;
                    } else {
                      return 'Please enter a valid UK telephone number (11 numbers only)';
                    }
                  })
                },
              },
              props: {...props, fieldProps: {type: 'number'}}
            });
            break;
          case 'Short Text':
          default:
            inputs.push({
              Component: TextField,
              shouldDisplay,
              rules: {validate: validate('Please complete this field')},
              props
            });
            break;
        }
      }
      setFields(inputs);
      setInitial({...item, ...(Object.keys(values).length > 0 ? values : {})});
    }
  }, [locations, item, product, user, handleShouldDisplay]);

  return fields.length > 0 && initial !== null ? (
    <Panes
      entity={initial}
      prefix={prefix}
      panes={[
        {
          title: '',
          fields: [
            ...(reorders.length > 0 ? [
              <Box marginBottom={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="reorder-label">Select an item to re-order</InputLabel>
                  <MatSelect
                    labelId="reorder-label"
                    value={reorder}
                    onChange={e => handleReorder(e.target.value)}
                    label="Select an item to re-order"
                  >
                    <MenuItem value={''}>Clear</MenuItem>
                    {reorders.map((order, index) => (
                      <MenuItem key={index} value={order.reference}>
                        {formatPastOrder(order)}
                      </MenuItem>
                    ))}
                  </MatSelect>
                </FormControl>
              </Box>
            ] : []),
            ...fields.map(({Component, shouldDisplay, rules, props}) => (
              <div style={shouldDisplay(state) ? {} : {display: 'none'}}>
                <Component prefix={prefix} {...props} {...shouldDisplay(state) ? {rules} : {}}/>
              </div>
            ))
          ]
        },
      ]}
      title={multiplePanes ? null : 'Item Details'}
    >{children}</Panes>
  ) : null;
};

export default withPersonalisedProducts(withOrderItemsAndProducts(Fields));
