import Box from '@material-ui/core/Box';
import {AccessHolder, Login} from '@raise.digital/management-ui';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import routes from '../../../routes';
import ForgotPassword from './ForgotPassword';

const PortalAccess = ({title, logo, logoWidth, company, actions, ...props}) => {
  return (
    <AccessHolder title={title} logo={logo} logoWidth={logoWidth} company={company}>
      <Box marginTop={4}>
        <Switch>
          <Route
            exact
            path={routes.login}
            render={routeProps => <Login {...props} {...routeProps} routes={routes} onLogin={actions.onLogin}/>}/>
          <Route
            exact
            path={routes.forgotPassword}
            render={routeProps => <ForgotPassword {...props} {...routeProps} />}/>
        </Switch>
      </Box>
    </AccessHolder>
  );
};

export default PortalAccess;