import {Paper} from '@material-ui/core';
import {Breadcrumbs} from '@raise.digital/management-ui';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setOrderListURL} from '../../../../store/actions/customer';
import Table from './Table';

const History = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state['auth'].user);
  return (
    <>
      <Breadcrumbs crumbs={[{title: 'Order History'}]}/>
      <Paper>
        <Table
          title={user.location ? 'Orders placed within the last 4 weeks' : 'Order History'}
          onGoToDetail={() => dispatch(setOrderListURL(null))}
        />
      </Paper>
    </>
  );
};

export default History;
