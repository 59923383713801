import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import {OrderService} from '../../../services/OrderService';
import OrderTable from '../components/OrderTable';

export default function Orders() {
  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="body2">Orders</Typography>
        </Breadcrumbs>
      </Box>
      <Paper>
        <OrderTable
          loadOrders={(query, additional) => OrderService.getInstance().getTableItems(query, additional)}
        />
      </Paper>
    </div>
  );
}
