import {API} from './API';
import * as qs from 'qs';

export class UserService {
  static getInstance() {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableUsers(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getUsers(query, page, pageSize);
  }

  async getUsers(query, page, pageSize) {
    return (await API.getConnection())
      .get('users', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveUser(data) {
    let method = 'post';
    let url = 'users';
    if (data.id) {
      method = 'put';
      url = `users/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async getPassword(user) {
    return (await API.getConnection())
      .get(`users/${user.id}/password`)
      .then((response) => response.data.password)
      .catch((error) => API.handleError(error));
  }
}
