import {IconButton, Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {ThumbDown, ThumbUp} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import {DataTable, dateTime, useDetail, useErrors} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo, useRef} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import Wrapper from '../Wrapper';

const useStyles = makeStyles((theme) => ({
  approved: {
    color: theme.palette.success.main
  },

  rejected: {
    color: theme.palette.error.main
  },

  approve: {
    fill: theme.palette.success.main
  },

  reject: {
    fill: theme.palette.error.main
  },
}));

export default function PasswordReminders() {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const {errors, toggleError} = useErrors(useMemo(() => ({customer: 'Sorry the customer could not be accessed'}), []));

  const {
    entity: customer,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    useMemo(() => services.customer.getCustomer, [services]),
    false,
    useCallback((customer) => [
      {
        title: customer.name,
        link: reverse(routes.admin.customers.detail, {id: customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: customer.id})
      },
      {title: 'Password Reminders'}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('customer', displayError), [toggleError])
  );

  const handleToggle = useCallback((reminder, approve) => {
    setLoading(true);
    services.customer.toggleReminder(customer, reminder, approve).then(() => {
      setLoading(false);
      tableRef.current.refresh();
    }).catch(() => setLoading(false));
  }, [services, customer, setLoading]);

  return (
    <Wrapper title="Password Reminders" loading={loading} customer={customer} crumbs={crumbs} errors={errors}>
      {customer ? (
        <>
          <Paper marginTop={2} component={Box}>
            <DataTable
              ref={tableRef}
              title=""
              options={{search: false, draggable: false}}
              columns={[
                {
                  title: 'Account',
                  field: 'account',
                  sorting: false,
                  render: data => `${data.user.firstName} ${data.user.lastName}`
                },
                {title: 'Name', field: 'name', sorting: false},
                {title: 'Email', field: 'email', sorting: false},
                {title: 'Phone', field: 'phone', sorting: false},
                {title: 'Date Requested', field: 'created', render: data => dateTime(data.created), sorting: false},
                {
                  title: 'Status', field: 'status', render: data => {
                    if (data.approved) {
                      return <span
                        className={classes.approved}><strong>Approved:</strong> {dateTime(data.approved)}</span>;
                    } else if (data.rejected) {
                      return <span
                        className={classes.rejected}><strong>Rejected:</strong> {dateTime(data.rejected)}</span>;
                    } else {
                      return (
                        <>
                          <IconButton onClick={() => handleToggle(data, true)}>
                            <ThumbUp className={classes.approve}/>
                          </IconButton>
                          <IconButton onClick={() => handleToggle(data, false)}>
                            <ThumbDown className={classes.reject}/>
                          </IconButton>
                        </>
                      );
                    }
                  }, sorting: false
                }
              ]}
              loadData={query => new Promise((resolve, reject) => {
                services.customer.getTableReminders(customer, query)
                  .then(response => {
                    resolve({
                      data: response.items, page: response.page - 1, totalCount: response.total
                    });
                  }).catch(() => {
                  reject();
                });
              })}
            />
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
}
