import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    position: 'relative',
  },
  pane: {
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  loading: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  checkbox: {
    flex: 1,
    marginLeft: 0,
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    display: 'none',
  },
}));

export default useStyles;
