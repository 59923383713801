import ArchiveIcon from '@material-ui/icons/Archive';
import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDetail,
  useDialogs,
  useErrors
} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import Status from '../../../../../components/Status';
import Text from '../../../../../components/Text';
import routes from '../../../../../routes';
import PODForm from '../../../forms/PODForm';
import Wrapper from '../Wrapper';

export default function Order() {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({contact: 'Sorry the order could not be accessed'}), []));

  const {
    entity: item,
    setEntity: setItem,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    useMemo(() => services.portalOrder.getOrder, [services]),
    false,
    useCallback((item) => [
      {
        title: item.order.location.customer.name,
        link: reverse(routes.admin.customers.detail, {id: item.order.location.customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: item.order.location.customer.id})
      },
      {
        title: 'Orders',
        link: reverse(routes.admin.customers.portal.orders.index, {id: item.order.location.customer.id})
      },
      {title: item.reference}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('order', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['pod', 'archive']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('pod', false);
    setItem(updated);
  }, [toggleDialog, setItem]);

  const handleArchive = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      services.portalOrder.archiveOrder(item).then(() => {
        setLoading(false);
        history.push(reverse(routes.admin.customers.portal.orders.index, {id: item.order.location.customer.id}));
      }).catch(() => setLoading(false));
    }
    toggleDialog('archive', false);
  }, [services, setLoading, toggleDialog, item, history]);

  return item ? (
    <Wrapper
      title={item.reference}
      loading={loading}
      customer={item.order.location.customer}
      crumbs={crumbs}
      errors={errors}
    >
      <DetailColumns columns={[

        <DetailPane
          title="Order Details"
          details={[
            {title: 'Reference', value: item.reference},
            {title: 'Ordered by', value: `${item.order.orderedBy.name} (${item.order.orderedBy.email})`},
            {title: 'User', value: `${item.order.user.firstName} ${item.order.user.lastName}`},
            {title: 'Date/Time Ordered', value: dateTime(item.created)},
            {title: 'Comments', value: item.order && item.order.comments ? <Text text={item.order.comments}/> : '-'}
          ]}
          actions={[
            {title: 'Archive Order', icon: <ArchiveIcon/>, onClick: () => toggleDialog('archive', true)}
          ]}
          dialogs={[
            (props) => (
              <ConfirmationDialog
                {...props}
                title="Archive Order"
                message="Are you sure you want to archive this order?"
                open={openDialogs['archive'] ?? false}
                onClose={handleArchive}
              />
            )
          ]}
        />,

        <DetailPane
          title={item.name}
          details={JSON.parse(item.fields).map(field => ({title: field.Name, value: field.Value}))}
        />,

        <DetailPane
          title="Order Status"
          details={[
            {title: 'Status', value: <Status status={item.status}/>},
            ...(item.approved ? [
              {title: 'Date/Time Approved', value: dateTime(item.approved)},
              {
                title: 'Approved by',
                value: item.approvedBy ? `${item.approvedBy.firstName} ${item.approvedBy.lastName}` : 'System'
              },
            ] : []),
            ...(item.exported ? [
              {title: 'Date/Time Exported', value: dateTime(item.exported)},
              ...(item.exportedBy ? [{
                title: 'Exported by', value: `${item.exportedBy.firstName} ${item.exportedBy.lastName}`
              }] : []),
            ] : []),
            ...(item.dispatched ? [
              {title: 'Date/Time Dispatched', value: dateTime(item.dispatched)},
              ...(item.dispatchedBy ? [{
                title: 'Dispatched by', value: `${item.dispatchedBy.firstName} ${item.dispatchedBy.lastName}`
              }] : []),
            ] : []),
            ...(item.delivered ? [
              {title: 'Date/Time Delivered', value: dateTime(item.delivered)},
              {title: 'Delivered to', value: item.deliveredTo ? item.deliveredTo : 'Unknown'},
            ] : [])
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Proof of Delivery"
                open={openDialogs['pod'] ?? false}
                onClose={() => toggleDialog('pod', false)}
                render={(props) => (
                  <PODForm
                    {...props}
                    item={item}
                    pod={{}}
                    onSaved={handleSaved}/>
                )}
              />
            )
          ]}
        />

      ]}/>

    </Wrapper>
  ) : null;
}
