export const SET_CUSTOMER = 'SET_CUSTOMER';

export default (
  state = {
    selected: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        selected: action.customer,
      };
    default:
  }
  return state;
};
