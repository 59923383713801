import React from 'react';
import {Panes, TextField} from '@raise.digital/management-ui';

export default function Fields({settings = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={settings}
      prefix={prefix}
      panes={[
        {
          title: 'Stock Settings',
          fields: [
            <TextField
              name="StockRecipients"
              prefix={prefix}
              label="Stock Warning Recipients"
            />,
          ]
        },
        {
          title: 'Mail Settings',
          fields: [
            <TextField
              name="Sender"
              prefix={prefix}
              label="Trigger Email Sender"
            />,
            <TextField
              name="PortalSupportRecipients"
              prefix={prefix}
              label="Portal Support Email Recipients"
            />,
            <TextField
              name="PortalOrderRecipients"
              prefix={prefix}
              label="Portal Order Email Recipients"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Settings'}
    >{children}</Panes>
  );
}
