import {BaseForm} from '@raise.digital/management-ui';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef, useState, useEffect, useCallback} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const FIELDS = [
  'primaryColourMain',
  'primaryColourContrast',
  'secondaryColourMain',
  'secondaryColourContrast',
  'domain',
  'emailSuffix',
  'emailSender'
];

// noinspection JSCheckFunctionSignatures
const CustomerBrandingForm = forwardRef(({customer, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  const logoRef = useRef(null);
  const emailLogoRef = useRef(null);
  const iconRef = useRef(null);
  const [entity, setEntity] = useState(null);

  useEffect(() => {
    const condensed = {};
    if (customer) {
      for (let field of FIELDS) {
        condensed[field] = customer[field];
      }
    }
    setEntity(condensed);
  }, [customer]);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const handleSave = useCallback((update) => {
    return services.customer.saveBranding({
      id: customer.id,
      ...update,
      logo: logoRef.current.getFile(),
      emailLogo: emailLogoRef.current.getFile(),
      icon: iconRef.current.getFile()
    });
  }, [services, customer]);

  return entity ? (
    <BaseForm
      ref={formRef}
      entity={entity}
      type="customer"
      fieldsComponent={(props) => <Fields {...props} logoRef={logoRef} emailLogoRef={emailLogoRef} iconRef={iconRef}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  ) : null;
});

export default CustomerBrandingForm;
