import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GridOnIcon from '@material-ui/icons/GridOn';
import Alert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import routes from '../../../routes';
import {ProductService} from '../../../services/ProductService';
import {setProduct} from '../../../store/actions/products';
import ProductForm from '../forms/ProductForm';
import ProductLabelsForm from '../forms/ProductLabelsForm';

const useStyles = makeStyles(theme => ({
  editPane: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  saveRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  },
  spacer: {
    flex: 1,
  }
}));

export default function Products({archive = false}) {
  const classes = useStyles();
  /** @type {({current: ProductForm})} */
  const newFormRef = useRef();
  /** @type {({current: ProductLabelsForm})} */
  const labelsFormRef = useRef();
  /** @type {({current: MaterialTable})} */
  const tableRef = useRef();
  const initialised = useRef(false);
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [labelOpen, setLabelOpen] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  const selectProduct = useCallback((selected) => {
    dispatch(setProduct(selected));
    history.push(reverse(routes.admin.products.detail, {id: selected.id}))
  }, [dispatch, history]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
    } else {
      if (tableRef.current) {
        tableRef.current.onQueryChange(null);
      }
    }
  }, [archive]);

  const handleSave = () => {
    newFormRef.current.saveProduct();
  };
  const handleSaved = saved => {
    setNewOpen(false);
    selectProduct(saved);
  };

  const handleGenerate = (all) => {
    labelsFormRef.current.generateLabels(all);
  };

  const handleGenerated = () => {
    setLabelOpen(false);
  }

  const handleExport = () => {
    setLoading(true);
    ProductService.getInstance().export()
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          {archive ? (
            <Link component={RouterLink} color="textPrimary" variant="body2"
                  to={`${routes.admin.products.index}`}>Products</Link>
          ) : (
            <Typography color="textPrimary" variant="body2">Products</Typography>
          )}
          {archive ? (
            <Typography color="textPrimary" variant="body2">Archive</Typography>
          ) : null}
        </Breadcrumbs>
      </Box>
      <Paper>
        <MaterialTable
          title={archive ? 'Product Archive' : 'Products'}
          tableRef={tableRef}
          isLoading={loading}
          options={{
            debounceInterval: 500,
            detailPanelType: 'single',
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [50, 100, 250],
          }}
          components={{
            Container: props => <Paper elevation={0} {...props} />,
          }}
          columns={[
            {title: 'Name', field: 'name', sorting: false},
            {title: 'Customer', field: 'customer', render: data => data.customer.name, sorting: false},
            {
              title: 'Location',
              field: 'location',
              render: data => data.location ? data.location.name : '-',
              sorting: false
            },
            {title: 'Quantity', field: 'quantity', sorting: false},
            {title: 'Buffer', field: 'buffer', sorting: false},
          ]}
          data={query => new Promise(resolve => {
            ProductService.getInstance().getTableProducts(query, archive ? {archived: '1'} : {})
              .then(response => {
                resolve({
                  data: response.items, page: response.page - 1, totalCount: response.total
                });
              }).catch(() => {
              setErrorOpen(true);
              resolve({data: [], page: 0, totalCount: 0});
            });
          })}
          onRowClick={(event, rowData) => selectProduct(rowData)}
          actions={archive ? [
            {
              icon: 'archive',
              tooltip: 'View Live Products',
              isFreeAction: true,
              onClick: () => history.push(reverse(routes.admin.products.index))
            },
          ] : [
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(null),
            },
            {
              icon: 'print',
              tooltip: 'Generate Labels',
              isFreeAction: true,
              onClick: () => setLabelOpen(true)
            },
            {
              icon: () => <GridOnIcon/>,
              tooltip: 'Export Product List',
              isFreeAction: true,
              onClick: handleExport
            },
            {
              icon: 'archive',
              tooltip: 'View Archived Products',
              isFreeAction: true,
              onClick: () => history.push(reverse(routes.admin.products.archive))
            },
            {
              icon: 'add',
              tooltip: 'Add New Product',
              isFreeAction: true,
              onClick: () => setNewOpen(true)
            }
          ]}
        />
      </Paper>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>
      <Dialog open={newOpen} onClose={() => setNewOpen(false)} fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">Add New Product</DialogTitle>
        <DialogContent>
          <ProductForm
            ref={newFormRef}
            new={true}
            onSaved={handleSaved}
            user={{}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={labelOpen} onClose={() => setLabelOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle id="form-dialog-title">Generate Product Labels</DialogTitle>
        <DialogContent>
          <ProductLabelsForm
            ref={labelsFormRef}
            onGenerated={handleGenerated}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleGenerate(true)} color="primary">
            All Products
          </Button>
          <div className={classes.spacer}/>
          <Button onClick={() => setLabelOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleGenerate(false)} color="primary">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
