import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {Title} from '@raise.digital/management-ui';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import routes from '../../../routes';
import {setOrderListURL} from '../../../store/actions/customer';
import Products from '../components/Products';
import Table from './History/Table';

const Home = () => {
  const user = useSelector(state => state['auth'].user);
  const loading = useSelector(state => state['general'].loading);
  const dispatch = useDispatch();
  return user.location ? (
    <>
      <Title title="Select a Product to Order" loading={loading}/>
      {loading ? (
        <Box padding={4} marginTop={2} textAlign="center" fontSize="1.4em">
          <p>Please wait, loading...</p>
        </Box>
      ) : null}
      <Products/>
    </>
  ) : (
    <>
      <Paper component={Box} marginTop={2}>
        <Table
          title="Recent Orders (Last 48 Hours)"
          status="Active Orders"
          onGoToDetail={() => dispatch(setOrderListURL(routes.customer.home))}
        />
      </Paper>
    </>
  );
};

export default Home;
