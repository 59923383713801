import React, {useEffect, useRef, useState} from 'react';
import Spinner from './Spinner';
import {makeStyles} from '@material-ui/core/styles';
import {ProductService} from '../../../services/ProductService';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  holder: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    maxWidth: 300,
    position: 'relative',
  },
  qr: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 0,
    padding: '0 0 100%',
    width: '100%',
  },
  spinner: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
  }
}));

export default function QRCode() {
  const PENDING = 1;
  const LOADED = 2;
  const FAILED = 3;

  const classes = useStyles();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const status = useRef(PENDING);
  const product = useSelector(state => state.products.selected);

  useEffect(() => {
    if (product && !data && !loading && status.current === PENDING) {
      setLoading(true);
      ProductService.getInstance().qr(product).then(file => {
        status.current = LOADED;
        setLoading(false);
        setData(file);
      }).catch(() => {
        status.current = FAILED;
        setLoading(false);
      });
    }
  }, [product, data, loading]);

  const styles = {};
  if (data) {
    styles.backgroundImage = `url(${data})`;
  }
  return (
    <div className={classes.container}>
      <div className={classes.holder}>
        <div className={classes.qr} style={styles} />
        {loading ? <Spinner className={classes.spinner} /> : null}
      </div>
    </div>
  )
}
