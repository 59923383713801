import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {reverse} from 'named-urls';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import routes from '../../../routes';
import withPersonalisedProducts from '../hoc/withPersonalisedProducts';

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
  },
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none'
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  onePerOrder: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    textTransform: 'uppercase',
    top: 0,

    '& span': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 16,
      color: theme.palette.secondary.contrastText,
      fontWeight: 600,
      padding: theme.spacing(2)
    },
  },
  imageHolder: {
    display: 'flex',
    padding: theme.spacing(1),
    height: '200px',
    width: '100%',
  },
  image: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    flex: 1,
  },
  body: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),

    '& h2': {
      margin: `0 0 ${theme.spacing(1)}px`,
      padding: 0,
    },

    '& p': {
      flex: 1,
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(3)}px`,
      padding: 0,
      textAlign: 'center',
    }
  }
}));

const Products = ({products}) => {
  const classes = useStyles();
  const history = useHistory();
  const items = useSelector(state => state['customer'].items);

  const canSelect = useCallback((product) => {
    if (product.onePerOrder) {
      return items.filter(i => i.ProductId === product.id).length === 0;
    }
    return true;
  }, [items]);

  return (
    <Box marginTop={2}>
      <Grid container spacing={2}>
        {products.map((product, index) => {
          const available = canSelect(product);
          return (
            <Grid key={index} className={classes.column} item xs={2}>
              <div className={classes.wrapper}>
                <Paper className={[classes.paper, !available || product.unavailable ? classes.disabled : ''].join(' ')}>
                  <div className={classes.imageHolder}>
                    <div className={classes.image}
                         style={product.sample ? {backgroundImage: `url(${product.sample})`} : {}}/>
                  </div>
                  <div className={classes.body}>
                    <h2>{product.name}</h2>
                    <p>{product.description}</p>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => history.push(reverse(routes.customer.order.product, {id: product.id}))}
                    >
                      Select
                    </Button>
                  </div>
                </Paper>
                {available ? null : <p className={classes.onePerOrder}><span>Only One Per Order</span></p>}
                {product.unavailable ? <p className={classes.onePerOrder}><span>Currently Unavailable</span></p> : null}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default withPersonalisedProducts(Products);
