export const SET_ITEMS = 'SET_CUSTOMER_ORDER_ITEMS';
export const SET_ORDER_LIST_URL = 'SET_ORDER_LIST_URL';
export const SET_LOCATIONS = 'SET_CUSTOMER_LOCATIONS';

export default (
  state = {
    items: [],
    locations: null,
    orderListURL: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        items: action.items,
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
      };
    case SET_ORDER_LIST_URL:
      return {
        ...state,
        orderListURL: action.url
      }
    default:
  }
  return state;
};
