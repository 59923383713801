import {makeStyles} from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(((theme) => ({
  container: {
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    position: 'relative'
  },

  label: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey['600'],
    left: theme.spacing(1),
    fontSize: '0.9em',
    margin: 0,
    padding: `0 ${theme.spacing(0.5)}px`,
    position: 'absolute',
    top: 0 - theme.spacing(1)
  }
})));

const BorderedField = ({label, children}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p className={classes.label}>{label}</p>
      {children}
    </div>
  );
};

export default BorderedField;