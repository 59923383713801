import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDropzone} from 'react-dropzone';
import ImageIcon from '@material-ui/icons/Image';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  title: {
    margin: `0 0 ${theme.spacing(1)}px`,
    padding: 0,
    textAlign: 'center',
  },
  imageHolder: {
    backgroundColor: theme.palette.background.paper,
    padding: '0 0 70%',
    position: 'relative',
    width: '100%',
  },
  image: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  dropzone: {
    border: `1px dashed ${theme.palette.primary.main}`,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    textAlign: 'center',

    '& p': {
      margin: 0,
      padding: 0,
    },

    '&:focus': {
      outline: 0,
    }
  },
  selected: {
    marginTop: theme.spacing(2),

    '& p': {
      fontWeight: 700,
      marginBottom: theme.spacing(1),
      wordBreak: 'break-all',
    },

    '& img': {
      height: 'auto',
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  }
}));

export default function ImageUpload({title, prompt, load, onUpload}) {
  const PENDING = 1;
  const LOADED = 2;
  const FAILED = 3;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState({});
  const [selectedName, setSelectedName] = useState('');
  const [selectedPreview, setSelectedPreview] = useState('');
  const status = useRef(PENDING);

  useEffect(() => {
    const handlePaste = (e) => {
      if (e && e.clipboardData.files && e.clipboardData.files.length > 0) {
        const file = e.clipboardData.files[0];
        if (['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].indexOf(file.type) >= 0) {
          setSelected(file);
          setSelectedName('Image from Clipboard');
        }
      }
    }
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    if (!data && !loading && status.current === PENDING) {
      setLoading(true);
      new Promise(resolve => load(resolve))
        .then(imageData => {
          status.current = LOADED;
          setData(imageData);
          setLoading(false);
        })
        .catch(() => {
          status.current = FAILED;
          setLoading(false)
        });
    }
  }, [load, data, loading]);

  useEffect(() => {
    setSelected({});
  }, [data]);

  useEffect(() => {
    if (selected && selected.name) {
      setSelectedPreview(URL.createObjectURL(selected));
    } else {
      setSelectedName('');
      setSelectedPreview('');
    }
  }, [selected]);

  const {getRootProps, getInputProps} = useDropzone(/** @type {Partial} */{
    accept: 'image/*',
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > 0) {
        setSelected(acceptedFiles[0]);
        setSelectedName(acceptedFiles[0].name);
      } else {
        setSelected({});
      }
    }
  });

  const handleUpload = (e) => {
    e.stopPropagation();
    if (selected) {
      setLoading(true);
      new Promise(resolve => onUpload(selected, resolve))
        .then(imageData => {
          setData(imageData);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }

  let displayImage = {};
  if (data) {
    displayImage.backgroundImage = `url(${data})`;
  }
  let spinner = null;
  if (loading) {
    displayImage.opacity = 0.4;
    spinner = <CircularProgress style={{
      bottom: 0,
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 2,
    }}/>;
  }
  let holding = null;
  if (!data) {
    holding = <ImageIcon
      color="primary"
      style={{
        bottom: 0,
        fontSize: 100,
        left: 0,
        margin: 'auto',
        opacity: 0.4,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1
      }} />
  }


  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <div className={classes.imageHolder}>
        <div className={classes.image} style={displayImage} />
        {spinner}
        {holding}
      </div>
      <div {...getRootProps({className: classes.dropzone, multiple: false})}>
        <input {...getInputProps()} />
        <p>{prompt}</p>
        {selectedName ?
          <div className={classes.selected}>
            <p>{selectedName}</p>
            {selectedPreview ? <img alt={selectedName} src={selectedPreview} /> : ''}
            <Button onClick={handleUpload} variant="contained" color="secondary">Upload</Button>
          </div> : null}
      </div>
    </div>
  );
}
