import React, {useCallback, useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import StockMovementForm from '../forms/StockMovementForm';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {reverse} from 'named-urls';
import routes from '../../../routes';
import {useHistory} from 'react-router-dom';
import {setProduct} from '../../../store/actions/products';
import {useDispatch} from 'react-redux';
import Reference from './Reference';
import {OrderService} from '../../../services/OrderService';
import LabelsForm from '../forms/LabelsForm';

const useStyles = makeStyles(theme => ({
  items: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  received: {
    color: theme.palette.success.main,
  },
}));

export default function OrderItems({order, onUpdate}) {
  const classes = useStyles();
  const history = useHistory();
  /** @type {({current: StockMovementForm})} */
  let activityFormRef = useRef();

  const [items, setItems] = useState([]);
  const [movementItem, setMovementItem] = useState(null);
  const [labelsOpen, setLabelsOpen] = useState(false);

  /** @type {({current: LabelsForm})} */
  let labelsFormRef = useRef();

  const handleGenerateLabels = () => {
    labelsFormRef.current.generateLabels();
  };

  const handleGenerate = (labels) => OrderService.getInstance().generateLabels(order, labels);

  const handleGenerated = () => {
    setLabelsOpen(false);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    setItems(order.items);
  }, [order]);

  const handleCheckIn = (item) => {
    setMovementItem(item);
  };

  const handleLogActivity = () => {
    activityFormRef.current.saveActivity();
  };
  const handleActivityLogged = updated => {
    setMovementItem(null);
    onUpdate(updated);
  };

  const selectItem = useCallback((selected) => {
    dispatch(setProduct(null));
    history.push(reverse(routes.admin.products.detail, { id: selected.productVersion.product.id }))
  }, [history, dispatch]);

  return (
    <div>
      <Paper className={classes.items}>
        <MaterialTable
          title="Products"
          options={{
            debounceInterval: 500,
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [50, 100, 250],
            search: false,
          }}
          components={{Container: props => <Paper elevation={0} {...props} />}}
          columns={[
            {title: 'Job Number', field: 'reference', render: data => <Reference reference={data.reference} />, sorting: false},
            {title: 'Product', field: 'product', render: data => data.productVersion.product.name, sorting: false},
            {title: 'Version', field: 'version', render: data => data.productVersion.code, sorting: false},
            {title: 'Location', field: 'location', render: data => data.productVersion.product.location ? data.productVersion.product.location.name : '-', sorting: false},
            {title: 'Quantity', field: 'quantity', render: data => Math.abs(data.quantity), sorting: false},
            {title: 'Packed In', field: 'packedIn', render: data => data.packedIn, sorting: false},
            {title: 'Checked-in', field: 'checkedIn', sorting: false},
            {
              title: '', field: 'actions', render: item => (
                <div onClick={e => e.stopPropagation()}>
                  {item.quantity - item.checkedIn > 0 ?
                    <IconButton onClick={() => handleCheckIn(item)}><CallReceivedIcon className={classes.received}/></IconButton> : null}
                </div>
              )
            }
          ]}
          data={items}
          onRowClick={(event, rowData) => selectItem(rowData)}
          actions={[
            {
              icon: 'print',
              tooltip: 'Generate Labels',
              isFreeAction: true,
              onClick: () => setLabelsOpen(true),
            }
          ]}
        />
      </Paper>
      <Dialog open={movementItem != null} onClose={() => setMovementItem(null)} fullWidth maxWidth="xs">
        <DialogTitle id="form-dialog-title">Check-in</DialogTitle>
        <DialogContent>
          <StockMovementForm
            ref={activityFormRef}
            onSaved={handleActivityLogged}
            version={movementItem ? movementItem.productVersion : null}
            item={movementItem}
            checkIn={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMovementItem(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogActivity} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={labelsOpen} onClose={() => setLabelsOpen(false)} fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">Generate Labels</DialogTitle>
        <DialogContent>
          <LabelsForm
            ref={labelsFormRef}
            order={order}
            onGenerate={handleGenerate}
            onGenerated={handleGenerated} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLabelsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleGenerateLabels} color="primary">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
