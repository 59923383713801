import EditIcon from '@material-ui/icons/Edit';
import {
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDetail,
  useDialogs,
  useErrors
} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import CustomerCategoryForm from '../../../forms/CustomerCategoryForm';
import Wrapper from '../Wrapper';

export default function Category() {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({category: 'Sorry the category could not be accessed'}), []));

  const {
    entity: category,
    setEntity: setCategory,
    loadEntity: loadCategory,
    crumbs,
    loading
  } = useDetail(
    services.customer.getCategory,
    false,
    useCallback((category) => [
      {
        title: category.customer.name,
        link: reverse(routes.admin.customers.detail, {id: category.customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: category.customer.id})
      },
      {title: category.name}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('category', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setCategory(updated);
    } else if (category?.id) {
      loadCategory(category.id);
    }
  }, [toggleDialog, setCategory, loadCategory, category]);

  return category ? (
    <Wrapper title={category.name} loading={loading} customer={category.customer} crumbs={crumbs} errors={errors}>
      <DetailColumns columns={[

        <DetailPane
          title="Category Details"
          actions={[
            {title: 'Edit Category', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)}
          ]}
          details={[
            {title: 'Name', value: category.name}
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Edit Category"
                open={openDialogs.edit ?? false}
                onClose={() => toggleDialog('edit', false)}
                render={(props) => (
                  <CustomerCategoryForm
                    {...props}
                    customer={category.customer}
                    category={category}
                    onSaved={handleSaved}/>
                )}
              />
            )
          ]}
        />,

        <DetailPane
          title="Admin Details"
          details={[
            {title: 'Created', value: dateTime(category.created)},
            {title: 'Last Updated', value: dateTime(category.updated)}
          ]}
        />
      ]}/>

      {/*<Paper marginTop={2} component={Box}>*/}
      {/*  <Table title="Users" location={category}/>*/}
      {/*</Paper>*/}

    </Wrapper>
  ) : null;
}
