import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React, {useCallback, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {ServiceContext} from '../../../components/Services';
import routes from '../../../routes';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const ForgotPassword = () => {
  const config = useSelector(state => state['general'].config);

  const classes = useStyles();
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [account, setAccount] = useState('');
  const [accountError, setAccountError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  let loadingBar = null;
  if (loading) {
    loadingBar = <LinearProgress className={classes.progress}/>;
  }

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    let valid = true;
    setAccountError('');
    setNameError('');
    setEmailError('');
    if (!account) {
      setAccountError('Please enter the email address for the account you are trying to access');
      valid = false;
    }
    if (!name) {
      setNameError('Please enter your name so we know who to contact');
      valid = false;
    }
    if (!email) {
      setEmailError('Please enter an email address so we can contact you');
      valid = false;
    } else if (email.includes('@')) {
      if (!email.includes('@zebraprintmanagement.co.uk') && !email.includes('@raise.digital')) {
        setEmailError(`Please use your company email address ending in ${config.emailSuffix}`);
        valid = false;
      }
    }
    if (valid) {
      setLoading(true);
      const complete = () => {
        setAccount('');
        setName('');
        setPhone('');
        setEmail('');
        setLoading(false);
        setDone(true);
      };
      services.portal.forgotPassword(
        account,
        name,
        phone,
        email.includes('@') ? email : `${email}${config.emailSuffix}`
      ).then(complete).catch(complete);
    }
  }, [services, config, account, name, phone, email]);

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      {done ? (
        <Alert severity="success">Thank you, someone will be in touch to let you back into the account</Alert>
      ) : (
        <Alert severity="info">Please complete the form below and someone will get in touch to let you into the
          account</Alert>
      )}
      <TextField
        name="account"
        id="account"
        label="Account Email Address"
        error={!!accountError}
        helperText={accountError ?? ''}
        value={account}
        onChange={event => setAccount(event.target.value)}
        autoComplete="email"
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name="name"
        id="name"
        label="Your Name"
        error={!!nameError}
        helperText={nameError ?? ''}
        value={name}
        onChange={event => setName(event.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name="phone"
        id="phone"
        label="Contact Telephone Number"
        value={phone}
        onChange={event => setPhone(event.target.value)}
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <TextField
        name="email"
        id="email"
        label="Email Address"
        error={!!emailError}
        helperText={emailError ?? ''}
        value={email}
        onChange={event => setEmail(event.target.value)}
        autoComplete="email"
        variant="outlined"
        margin="normal"
        InputProps={{endAdornment: <InputAdornment position="end">{config.emailSuffix}</InputAdornment>}}
        fullWidth
        required
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <Link component={RouterLink} to={`${routes.login}`} variant="body2">
        Log-in
      </Link>
      {loadingBar}
    </form>
  );
};

ForgotPassword.propTypes = {
  routes: PropTypes.object,
  onForgotPassword: PropTypes.func
};

export default ForgotPassword;
