import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ServiceContext} from '../../../components/Services';
import {setLoading} from '../../../store/actions/general';

const withPersonalisedProducts = (ListComponent) => {
  return (props) => {
    const services = useContext(ServiceContext);
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);

    useEffect(() => {
      dispatch(setLoading(true));
      services.portal.getPersonalisedProducts().then(async list => {
        const retrieved = [...list];
        for (let p = 0; p < retrieved.length; p++) {
          if (retrieved[p].hasSample) {
            retrieved[p].sample = await services.portal.getPersonalisedProductSample(retrieved[p]);
          }
        }
        setProducts(retrieved);
        dispatch(setLoading(false));
      }).catch(() => setLoading(false));
    }, [services, dispatch]);

    return <ListComponent products={products} {...props}/>;
  };
};

export default withPersonalisedProducts;