import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';
import {BaseForm} from '@raise.digital/management-ui';

const SettingsForm = forwardRef(({settings, onSaved, onError}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={settings}
      type="settings"
      fieldsComponent={Fields}
      onSave={(update) => services.settings.saveSettings(update).catch(error => {
        onError(error);
        return Promise.reject(error);
      })}
      onSaved={onSaved}
    />
  );
});

export default SettingsForm;
