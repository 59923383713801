import {Panes, TextField} from '@raise.digital/management-ui';
import React from 'react';

export default function Fields({customer = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={customer}
      prefix={prefix}
      panes={[
        {
          title: 'Customer Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Customer Details'}
    >{children}</Panes>
  );
}
