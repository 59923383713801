import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import {AccessHolder, Authenticated} from '@raise.digital/management-ui';
import * as React from 'react';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import zebraLogo from '../../assets/logo.svg';
import {usePermissions} from '../../hooks';
import routes from '../../routes';
import {logout} from '../../store/actions/auth';
import {setOrder} from '../../store/actions/orders';
import Customer from './screens/Customer';
import Portal from './screens/Customer/Portal';
import CustomerCategory from './screens/Customer/Portal/Category';
import Dispatch from './screens/Customer/Portal/Dispatch';
import CustomerLocation from './screens/Customer/Portal/Location';
import PortalMessages from './screens/Customer/Portal/Messages';
import PortalOrder from './screens/Customer/Portal/Order';
import PortalOrders from './screens/Customer/Portal/Orders';
import PortalPasswordReminders from './screens/Customer/Portal/PasswordReminders';
import PersonalisedProduct from './screens/Customer/Portal/PersonalisedProduct';
import Customers from './screens/Customers';
import Dashboard from './screens/Dashboard';
import Location from './screens/Location';
import Locations from './screens/Locations';
import ManageOrder from './screens/ManageOrder';
import Order from './screens/Order';
import OrderChanges from './screens/OrderChanges';
import Orders from './screens/Orders';
import Product from './screens/Product';
import ProductOrders from './screens/ProductOrders';
import Products from './screens/Products';
import Roles from './screens/Users/Roles';
import Settings from './screens/Settings';
import Users from './screens/Users';

const ResetOrder = ({children}) => {
  const dispatch = useDispatch();
  dispatch(setOrder(null));
  return children;
};


const HasPermissions = ({config, user, props}) => {
  const dispatch = useDispatch();

  const canCreateUsers = usePermissions(['admin::create-users']);
  const canCreateOrders = usePermissions(['ordering::create-orders']);
  const canChangeSettings = usePermissions(['admin::change-settings']);

  return (
    <Authenticated
      title={config.name}
      logo={!!config.logo ? `data:image/svg+xml;base64,${Buffer.from(
        config.logo,
        'binary',
      ).toString('base64')}` : zebraLogo}
      userName={user ? user.firstName : ''}
      nav={[
        {title: 'Dashboard', route: routes.admin.dashboard, icon: <DashboardIcon/>},
        {title: 'Customers', route: routes.admin.customers.index, icon: <BusinessIcon/>},
        {title: 'Locations', route: routes.admin.locations.index, icon: <AllInboxIcon/>},
        {title: 'Products', route: routes.admin.products.index, icon: <StorageIcon/>},
        {title: 'Orders', route: routes.admin.orders.index, icon: <LocalShippingIcon/>},
        ...(canCreateUsers ? [{
          title: 'Administrators',
          route: routes.admin.administrators.index,
          icon: <SupervisedUserCircleIcon/>
        }] : []),
        ...(canChangeSettings ? [{title: 'Settings', route: routes.admin.settings, icon: <SettingsIcon/>}] : [])
      ]}
      onLogout={() => dispatch(logout())}
    >
      <Switch>
        <Route
          exact
          path={routes.admin.dashboard}
          render={routeProps => <Dashboard {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.index}
          render={routeProps => <Customers {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.detail}
          render={routeProps => <Customer {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.index}
          render={routeProps => <Portal {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.category}
          render={routeProps => <CustomerCategory {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.location}
          render={routeProps => <CustomerLocation {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.product}
          render={routeProps => <PersonalisedProduct {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.orders.index}
          render={routeProps => <PortalOrders {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.orders.dispatch}
          render={routeProps => <Dispatch {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.orders.detail}
          render={routeProps => <PortalOrder {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.messages}
          render={routeProps => <PortalMessages {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.customers.portal.passwordReminders}
          render={routeProps => <PortalPasswordReminders {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.locations.index}
          render={routeProps => <Locations {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.locations.detail}
          render={routeProps => <Location {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.products.index}
          render={routeProps => <Products {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.products.archive}
          render={routeProps => <Products {...props} {...routeProps} archive={true}/>}/>
        <Route
          exact
          path={routes.admin.products.detail}
          render={routeProps => <Product {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.products.orders}
          render={routeProps => <ProductOrders {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.orders.index}
          render={routeProps => <Orders {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.orders.new}
          render={routeProps => canCreateOrders ?
            <ResetOrder><ManageOrder {...props} {...routeProps} /></ResetOrder> : null}/>
        <Route
          exact
          path={routes.admin.orders.detail}
          render={routeProps => <ResetOrder><Order {...props} {...routeProps} /></ResetOrder>}/>
        <Route
          exact
          path={routes.admin.orders.update}
          render={routeProps => (canCreateOrders ? <ManageOrder {...props} {...routeProps} /> : null)}/>
        <Route
          exact
          path={routes.admin.orders.changes}
          render={routeProps => <OrderChanges {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.admin.administrators.index}
          render={routeProps => canCreateUsers ? <Users {...props} {...routeProps} /> : null}/>
        <Route
          exact
          path={routes.admin.administrators.roles}
          render={routeProps => canCreateUsers ? <Roles {...props} {...routeProps} /> : null}/>
        <Route
          exact
          path={routes.admin.settings}
          render={routeProps => canChangeSettings ? <Settings {...props} {...routeProps} /> : null}/>
      </Switch>
    </Authenticated>
  );
};


const Admin = (props) => {
  const config = useSelector(state => state['general'].config);
  const user = useSelector(state => state['auth'].user);
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  return user.role ? <HasPermissions config={config} user={user} props={props}/> : (
    <AccessHolder
      title={config.name}
      logo={!!config.logo ? `data:image/svg+xml;base64,${Buffer.from(
        config.logo,
        'binary',
      ).toString('base64')}` : zebraLogo}
      company={config.name}
    >
      <Paper marginTop={6} paddingX={2} textAlign="center" component={Box} width={400}>
        <p>Sorry this area is for portal administrators only.</p>
        <Box display="flex" justifyContent="center" marginBottom={2} marginTop={2}>
          <Box>
            <Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button>
          </Box>
        </Box>
      </Paper>
    </AccessHolder>
  );
};

export default Admin;
