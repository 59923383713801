import {SET_TOKEN, SET_USER} from '../reducers/auth';
import moment from 'moment';
import {API} from '../../services/API';
import {ServiceProvider} from '../../services/ServiceProvider';

export function login(email, password) {
  return async (dispatch) => {
    return ServiceProvider.getServices()
      .auth.login(email, password)
      .then((response) => {
        const {accessToken, expiresIn} = response;
        dispatch(setToken(accessToken, expiresIn));
        dispatch(getUser());
      })
      .catch(() => Promise.reject());
  };
}

export function getUser() {
  return async (dispatch) => {
    if (localStorage.getItem('token')) {
      return ServiceProvider.getServices()
        .auth.me()
        .then((user) => dispatch(setUser(user)))
        .catch(() => {});
    } else {
      dispatch(setUser(null));
    }
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function setToken(token, expires) {
  localStorage.setItem('token', token);
  localStorage.setItem(
    'expires',
    moment().add(expires, 'seconds').toISOString(),
  );
  API.setToken(token);
  return {
    type: SET_TOKEN,
    token,
    expires,
  };
}

export function logout() {
  return (dispatch) => {
    ServiceProvider.getServices()
      .auth.logout()
      .then(() => {
        dispatch(reset());
      })
      .catch(() => {});
  };
}

export function reset() {
  return (dispatch) => {
    dispatch(setToken('', ''));
    dispatch(setUser(null));
  };
}

export function resetPassword(email, password, token) {
  return (dispatch) => {
    return ServiceProvider.getServices()
      .auth.resetPassword(email, password, token)
      .then((response) => {
        const {accessToken, expiresIn} = response;
        dispatch(setToken(accessToken, expiresIn));
        dispatch(getUser());
      })
      .catch(() => dispatch(reset()));
  };
}
