export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';

export default (
  state = {
    user: false,
    token: null,
    expires: 0
  },
  action,
) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        expires: action.expires
      };
    default:
  }
  return state;
};
