import {DataTable} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useContext, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import CustomerLocationForm from '../../../forms/CustomerLocationForm';


const Locations = ({customer}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToLocation = useCallback((location) => {
    history.push(reverse(routes.admin.customers.portal.location, {customerID: customer.id, id: location.id}));
  }, [history, customer]);

  return (
    <DataTable
      title="Locations"
      columns={[
        {title: 'Name', field: 'name', sorting: false},
        {title: 'Phone', field: 'phone', sorting: false},
        {
          title: 'Address',
          field: 'address',
          render: (location) => location.address ? location.address.split('\n').map((part, index) => (
            <span key={index}>{index !== 0 ? <br/> : null}{part}</span>
          )) : '-',
          sorting: false
        },
        {title: 'Categories', field: 'categories', sorting: false, render: location => location.categories?.length ? location.categories.map(c => c.name).join(', ') : '-'}
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.customer.getTableLocations(query, {customerId: customer.id})
          .then(response => {
            resolve({
              data: response.items, page: response.page - 1, totalCount: response.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToLocation}
      newForm={{
        title: 'Add New Location',
        render: (props) => {
          return <CustomerLocationForm customer={customer} location={{}} {...props}/>
        },
        onSaved: goToLocation
      }}
    />
  );
};

export default Locations;
