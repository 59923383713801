import React, {useRef} from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {reverse} from 'named-urls';
import routes from '../../../routes';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  activity: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  loading: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,

    '&::before': {
      backgroundColor: theme.palette.background.paper,
      bottom: 0,
      content: '" "',
      left: 0,
      opacity: 0.8,
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  created: {
    marginLeft: theme.spacing(1)
  },
  status: {
    alignItems: 'center',
    display: 'flex',
  },
}));

export default function ProductsTable({loadProducts, loading, field = null}) {
  const history = useHistory();
  const classes = useStyles();
  let productTableRef = useRef();

  let loadingOverlay = null
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress/></div>;
  }

  return (
    <div>
      <Paper className={classes.activity}>
        <MaterialTable
          title="Products"
          tableRef={productTableRef}
          options={{
            debounceInterval: 500,
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [50, 100, 250],
            search: false,
          }}
          components={{Container: props => <Paper elevation={0} {...props} />}}
          columns={[
            {title: 'Name', field: 'name', sorting: false},
            {
              title: 'Customer',
              field: 'customer',
              render: data => data.customer ? data.customer.name : '-',
              sorting: false
            },
            {
              title: 'Location',
              field: 'location',
              render: data => data.location ? data.location.name : '-',
              sorting: false
            },
            {title: 'Quantity', field: 'quantity', sorting: false},
            {title: 'Buffer', field: 'buffer', sorting: false}
          ]}
          onRowClick={(event, rowData) => {
            history.push(reverse(`${routes.admin.products.detail}`, {id: rowData.id}))
          }}
          data={query => new Promise(resolve => loadProducts(query, resolve))}
        />
        {loadingOverlay}
      </Paper>
    </div>
  );
}
