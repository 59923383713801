import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import routes from '../../../routes';
import {LocationService} from '../../../services/LocationService';
import {setLocation} from '../../../store/actions/locations';
import LocationForm from '../forms/LocationForm';

const useStyles = makeStyles(theme => ({
  editPane: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  saveRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  }
}));

export default function Locations({history}) {
  const classes = useStyles();
  /** @type {({current: LocationForm})} */
  let formRef = useRef();
  /** @type {({current: MaterialTable})} */
  let tableRef = useRef();
  const [errorOpen, setErrorOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const dispatch = useDispatch();

  const selectLocation = useCallback((selected) => {
    dispatch(setLocation(selected));
    history.push(reverse(routes.admin.locations.detail, {id: selected.id}))
  }, [dispatch, history]);

  const handleSave = () => {
    formRef.current.saveLocation();
  };
  const handleSaved = (saved) => {
    setNewOpen(false);
    selectLocation(saved);
  };

  return (
    <div>
      <Box paddingLeft={2} paddingBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="body2">Locations</Typography>
        </Breadcrumbs>
      </Box>
      <Paper className={classes.container}>
        <MaterialTable
          title="Locations"
          options={{
            debounceInterval: 500,
            detailPanelType: 'single',
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [50, 100, 250],
          }}
          tableRef={tableRef}
          components={{
            Container: props => <Paper elevation={0} {...props} />
          }}
          columns={[
            {title: 'Name', field: 'name', sorting: false},
          ]}
          data={query => new Promise(resolve => {
            LocationService.getInstance().getTableLocations(query)
              .then(response => {
                resolve({
                  data: response.items, page: response.page - 1, totalCount: response.total
                });
              }).catch(() => {
              setErrorOpen(true);
              resolve({data: [], page: 0, totalCount: 0});
            });
          })}
          onRowClick={(event, rowData) => selectLocation(rowData)}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(null),
            },
            {
              icon: 'add',
              tooltip: 'Add New Location',
              isFreeAction: true,
              onClick: () => setNewOpen(true)
            }
          ]}
        />
      </Paper>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>
      <Dialog open={newOpen} onClose={() => setNewOpen(false)} fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">Add New Location</DialogTitle>
        <DialogContent>
          <LocationForm
            ref={formRef}
            new={true}
            onSaved={handleSaved}
            user={{}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
