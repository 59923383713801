import {API} from './API';
import * as qs from 'qs';
import * as sanitise from 'sanitize-filename';

export class OrderService {
  static getInstance() {
    if (!OrderService.instance) {
      OrderService.instance = new OrderService();
    }
    return OrderService.instance;
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableOrders(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getOrders(query, page, pageSize);
  }

  async getOrders(query, page, pageSize) {
    return (await API.getConnection())
      .get('orders', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableItems(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getItems(query, page, pageSize);
  }

  async getItems(query, page, pageSize) {
    return (await API.getConnection())
      .get('orders/items', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getOrder(id) {
    return (await API.getConnection())
      .get(`orders/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveOrder(data) {
    let method = 'post';
    let url = 'orders';
    if (data.id) {
      method = 'put';
      url = `orders/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async archiveOrder(order) {
    return (await API.getConnection())
      .delete(`orders/${order.id}`)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {IOrderItem} item
   * @param {Number} quantity
   * @param {String} comments
   * @param {Boolean} correction
   * @returns {Promise<void|any|undefined>}
   */
  async logActivity(item, quantity, comments, correction = false) {
    return (await API.getConnection())
      .post(`orders/${item.orderId}/items/${item.id}`, {
        quantity,
        comments,
        correction,
      })
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {IOrder} order
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableChanges(order, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getChanges(order, query, page, pageSize);
  }

  async getChanges(order, query, page, pageSize) {
    return (await API.getConnection())
      .get(`orders/${order.id}/changes`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {IOrder} order
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableNotes(order, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getNotes(order, query, page, pageSize);
  }

  async getNotes(order, query, page, pageSize) {
    return (await API.getConnection())
      .get(`orders/${order.id}/notes`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {IOrder} order
   * @param {String} note
   * @returns {Promise<void|any|undefined>}
   */
  async saveNote(order, note) {
    return (await API.getConnection())
      .post(`orders/${order.id}/notes`, {comments: note})
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async generateLabels(order, labels) {
    return (await API.getConnection())
      .post(`orders/${order.id}/labels`, labels, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let name;
        if (order.items.length > 0) {
          name = order.items[0].productVersion.product.name;
        }
        if (!name) {
          name = order.reference;
        }
        link.setAttribute('download', `${sanitise(name)}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => API.handleError(error));
  }
}
