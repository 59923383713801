import {Box} from '@material-ui/core';
import React, {useState, useEffect, useContext} from 'react';
import {ServiceContext} from '../../../../../components/Services';

const Image = ({customer, type, width}) => {
  const services = useContext(ServiceContext);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (customer && customer[`has${type.charAt(0).toUpperCase()}${type.slice(1)}`]) {
      services.customer[type](customer).then(setImage).catch(() => {
      });
    } else {
      const path = customer[`${type}Path`];
      if (path) {
        setImage(`${window.location.origin}/Uploads/${path.replace('\\', '/')}`);
      } else {
        setImage(null);
      }
    }
  }, [services, customer, type]);

  return image ? (
    <Box marginBottom={1}><img style={{width}} alt={customer.name} src={image}/></Box>
  ) : '-';
};

export default Image;
