import {createTheme} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import React, {useEffect, useState, useContext, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import {Provider, useDispatch, useSelector, useStore} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import zebraIcon from '../assets/icon.ico';
import Services, {ServiceContext} from '../components/Services';
import routes from '../routes';
import {API} from '../services/API';
import {store} from '../store';
import {getUser} from '../store/actions/auth';
import {setConfig} from '../store/actions/general';
import Access from './access/Access';
import Admin from './admin/Admin';
import Customer from './customer/Customer';

function Themed({children}) {
  const services = useContext(ServiceContext);
  const dispatch = useDispatch();
  const config = useSelector(state => state['general'].config);
  const loadingRef = useRef(false);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    if (config) {
      setTheme(createTheme({
        palette: {
          primary: {
            main: config.primaryColourMain,
            contrastText: config.primaryColourContrast
          },
          secondary: {
            main: config.secondaryColourMain,
            contrastText: config.secondaryColourContrast
          },
        },
        typography: {
          fontFamily: [
            'Open Sans'
          ],
          fontSize: config.default ? 14 : 16
        }
      }));
    } else {
      setTheme(null);
      if (!loadingRef.current) {
        loadingRef.current = true;
        services.portal.getConfig()
          .then(retrieved => {
            loadingRef.current = false;
            dispatch(setConfig(retrieved))
          })
          .catch(() => {
            loadingRef.current = false;
          });
      }
    }
  }, [services, dispatch, config]);

  return theme ? (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  ) : null;
}

function Axios({children}) {
  const store = useStore();

  useEffect(() => {
    API.initialise(store);
  }, [store]);
  return <>{children}</>;
}

function AppHead() {
  const config = useSelector(state => state['general'].config);
  return (
    <Helmet>
      <title>{config.name}</title>
      <link rel="icon" type="image/png" sizes="32x32" href={!!config.icon ? `data:image/png;base64,${config.icon}` : zebraIcon}/>
    </Helmet>
  )
}

function App() {
  const user = useSelector(state => state.auth.user);
  const config = useSelector(state => state.general.config);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === false) {
      dispatch(getUser());
    }
  }, [user, dispatch]);

  let router = null;
  if (user !== false) {
    if (user) {
      router = (
        <Router>
          {config.default ? (
            <Switch>
              <Route path="/auth"><Redirect to={`${routes.admin.dashboard}`}/></Route>
              <Route path="/">
                <Admin/>
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path="/auth"><Redirect to={`${routes.customer.home}`}/></Route>
              <Route path="/">
                <Customer/>
              </Route>
            </Switch>
          )}
        </Router>
      );
    } else {
      router = (
        <Router>
          <Switch>
            <Route path="/auth"><Access/></Route>
            <Route path="/"><Redirect to={`${routes.login}`}/></Route>
          </Switch>
        </Router>
      )
    }
  }
  return (
    <Services>
      <Axios>
        <Themed>
          <AppHead/>
          {router}
        </Themed>
      </Axios>
    </Services>
  );
}

const app = document.getElementById('root')
if (app) {
  ReactDOM.render(
    <Provider store={store}><App/></Provider>, app);
}
