import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import {
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDetail,
  useDialogs,
  useErrors
} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import DraftHTML from '../../../../../components/DraftHTML';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import ImageUpload from '../../../components/ImageUpload';
import Orderable from '../../../components/Orderable';
import YesNo from '../../../components/YesNo';
import PersonalisedProductFieldForm from '../../../forms/PersonalisedProductFieldForm';
import PersonalisedProductForm from '../../../forms/PersonalisedProductForm';
import Wrapper from '../Wrapper';

const useStyles = makeStyles(theme => ({
  imagesHeader: {
    color: theme.palette.action.active,
    margin: theme.spacing(2),
  }
}));

const PersonalisedProduct = () => {
  const classes = useStyles();
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({product: 'Sorry the product could not be accessed'}), []));

  const {
    entity: product,
    setEntity: setProduct,
    loadEntity: loadProduct,
    crumbs,
    loading
  } = useDetail(
    services.customer.getPersonalisedProduct,
    false,
    useCallback((product) => [
      {
        title: product.customer.name,
        link: reverse(routes.admin.customers.detail, {id: product.customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: product.customer.id})
      },
      {title: product.name}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('product', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'newField', 'editField', 'deleteField']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    toggleDialog('newField', false);
    toggleDialog('editField', false);
    if (updated) {
      setProduct(updated);
    } else if (product?.id) {
      loadProduct(product.id);
    }
  }, [toggleDialog, setProduct, loadProduct, product]);

  const loadImage = async (image, resolve) => {
    if (product.hasSample) {
      services.customer.personalisedProductSample(product)
        .then(data => resolve(data))
        .catch(() => resolve(null));
    } else {
      resolve(null);
    }
  };

  const uploadImage = async (image, file, resolve) => {
    services.customer.uploadPersonalisedProductSample(product, file)
      .then(data => resolve(data))
      .catch(() => resolve(null));
  };

  return product ? (
    <Wrapper title={product.name} loading={loading} customer={product.customer} crumbs={crumbs} errors={errors}>
      <DetailColumns columns={[

        <DetailPane
          title="Product Details"
          actions={[
            {title: 'Edit Product', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)}
          ]}
          details={[
            {title: 'Name', value: product.name},
            {title: 'Description', value: product.description ?? '-'},
            {
              title: 'Help Text',
              value: <DraftHTML raw={product.helpText}/>
            },
            {title: 'Available?', value: <YesNo value={!product.unavailable}/>},
            {title: 'Requires Approval?', value: <YesNo value={product.requiresApproval}/>},
            {title: 'One Per Order?', value: <YesNo value={product.onePerOrder}/>},
            {title: 'Can Re-order?', value: <YesNo value={product.canReOrder}/>},
            {title: 'Is Held?', value: <YesNo value={product.isHeld}/>},
            {title: 'Separate Tab on Export?', value: <YesNo value={product.exportTab}/>},
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Edit Product"
                open={openDialogs.edit ?? false}
                onClose={() => toggleDialog('edit', false)}
                render={(props) => (
                  <PersonalisedProductForm
                    {...props}
                    customer={product.customer}
                    product={product}
                    onSaved={handleSaved}/>
                )}
              />
            )
          ]}
        />,

        <Paper component={Box} display="flex" flexDirection="column" flex={1}>
          <Typography className={classes.imagesHeader} variant="subtitle2">Image</Typography>
          <Box display="flex" padding={2}>
            {['Sample'].map(i =>
              <Box key={i} width="100%">
                <ImageUpload
                  title=""
                  prompt="Drop an image here or click to select one from your computer"
                  load={async resolve => loadImage(i.toLowerCase(), resolve)}
                  onUpload={async (file, resolve) => uploadImage(i.toLowerCase(), file, resolve)}
                />
              </Box>
            )}
          </Box>
        </Paper>,

        <DetailPane
          title="Admin Details"
          details={[
            {title: 'Categories', value: product.categories?.length ? product.categories.map(c => c.name).join(', ') : '-'},
            {title: 'Created', value: dateTime(product.created)},
            {title: 'Last Updated', value: dateTime(product.updated)}
          ]}
        />
      ]}/>

      <Paper marginTop={2} component={Box} overflow="hidden">
        <Orderable
          title="Fields"
          items={product.fields.map(field => ({entity: field, attributes: [field.name, `${field.fieldType}${field.formatter ? ` (${field.formatter})` : ''}`]}))}
          onRenderNewForm={(props) => (
            <PersonalisedProductFieldForm
              {...props}
              product={product}
              field={{}} />
          )}
          onRenderEditForm={(props, selected) => (
            <PersonalisedProductFieldForm
              {...props}
              product={product}
              field={selected}/>
          )}
          onReorder={(reordered) => services.customer.reorderPersonalisedProductFields(product, reordered)}
          onDelete={(selected) => services.customer.deletePersonalisedProductField(product, selected)}
          onSaved={handleSaved}
        />
      </Paper>

    </Wrapper>
  ) : null;
};

export default PersonalisedProduct;
