import {Box, Paper} from '@material-ui/core';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../components/Services';
import SettingsForm from '../forms/SettingsForm';
import {Settings as UISettings, Breadcrumbs, Title} from '@raise.digital/management-ui';

export default function Settings() {
  const services = useContext(ServiceContext);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Settings'}]}/>
      <Title title="Settings"/>
      <Paper marginTop={2} component={Box}>
        <UISettings
          getForm={useCallback((ref, settings, handleSaved, handleError) => (
            <SettingsForm ref={ref} settings={settings} onSaved={handleSaved} onError={handleError}/>
          ), [])}
          loadSettings={useCallback(() => {
            return services.settings.getSettings();
          }, [services])}
        />
      </Paper>
    </div>
  );
}
