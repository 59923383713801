import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {useCustomerCategories} from '../../../../hooks';
import Fields from './Fields';
import {BaseForm} from '@raise.digital/management-ui';

const CustomerLocationForm = forwardRef(({customer, location, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  const {categories, onSelectCategory} = useCustomerCategories(customer, location);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={location}
      type="location"
      fieldsComponent={(props) => <Fields {...props} categories={categories} onCategoryChange={onSelectCategory}/>}
      onSave={(update) => services.customer.saveLocation(customer, {...update, categories: categories.filter(c => c.selected).map(c => c.value)})}
      onSaved={onSaved}
    />
  );
});

export default CustomerLocationForm;
