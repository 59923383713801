import {reset, setToken, setUser} from '../store/actions/auth';
import moment from 'moment';

const axios = require('axios').default;

export class API {
  static initialise(store) {
    API.store = store;
  }

  static setToken(token) {
    if (API.connection) {
      API.connection.defaults.headers.common['Authorization'] =
        'Bearer ' + token;
    }
  }

  static async getConnection() {
    return new Promise(async (resolve) => {
      if (!API.connection) {
        API.connection = axios.create({
          baseURL: '/api/',
          timeout: 120000,
          responseType: 'json',
        });
        API.connection.interceptors.request.use(
          (config) => {
            return new Promise((resolve) => {
              const done = () => {
                config.headers.Authorization = `Bearer ${localStorage.getItem(
                  'token',
                )}`;
                resolve(config);
              };
              const exclusions = [
                'auth/login',
                'auth/refresh',
                'auth/logout',
                'auth/forgot-password',
                'auth/reset-password',
                'portal/config',
                'portal/forgot-password'
              ];
              if (exclusions.indexOf(config.url) < 0) {
                const expires = localStorage.getItem('expires');
                if (expires) {
                  if (moment(expires).isBefore()) {
                    API.connection
                      .post('auth/refresh')
                      .then((response) => {
                        const {accessToken, expiresIn} = response.data;
                        API.store.dispatch(setToken(accessToken, expiresIn));
                        done();
                      })
                      .catch(() => {
                        API.store.dispatch(reset());
                        done();
                      });
                  } else {
                    done();
                  }
                } else {
                  done();
                }
              } else {
                done();
              }
            });
          },
          (error) => Promise.reject(error),
        );
      }
      resolve(API.connection);
    });
  }

  static handleError(error) {
    if (error && error.response) {
      if (error.response.status === 403) {
        API.store.dispatch(setUser(null));
        localStorage.setItem('token', '');
      }
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }

  static getTableParams(params, additional = {}) {
    const query = {};
    const filters = additional;
    if (params.search) {
      filters.search = params.search;
    }
    params.filters.forEach((filter) => {
      filters[filter.column.field] = filter.value;
    });
    if (Object.keys(filters).length > 0) {
      query.filter = filters;
    }
    if (params.orderBy) {
      query.sort = `${params.orderDirection === 'desc' ? '-' : ''}${
        params.orderBy.field
      }`;
    }
    return [query, params.page + 1, params.pageSize];
  }
}
