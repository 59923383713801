export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function slugify(text) {
  return text.toLowerCase()
    .replace(/\s/g, '_')
    .replace(/[^a-z0-9_]*/g, '');
}

export const statusColours = {
  delivered: '#7a48db',
  dispatched: '#27ae60',
  inProduction: '#e59553',
  pending: '#ecbe62',
  processing: '#1e7ab3'
}

export function portalOrderStatus(status) {
  switch (status) {
    case 'Delivered':
      return statusColours.delivered;
    case 'Dispatched':
      return statusColours.dispatched;
    case 'In Production':
      return statusColours.inProduction;
    case 'Processing':
      return statusColours.processing;
    case 'Pending':
    default:
      return statusColours.pending;
  }
}