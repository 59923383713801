export const SET_PRODUCT = 'SET_PRODUCT';

export default (
  state = {
    selected: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...state,
        selected: action.product,
      };
    default:
  }
  return state;
};
