import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {useForm} from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {CircularProgress} from '@material-ui/core';
import {LocationService} from '../../../services/LocationService';
import useFormStyles from './styles';

const LocationForm = forwardRef(({location, onSaved}, ref) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const {register, handleSubmit, formState: {errors}, reset} = useForm();

  useEffect(() => {
    reset(location);
  }, [reset, location]);

  const onSubmit = data => {
    let update = {...data};
    if (location) {
      update = {...location, ...update};
    }
    setLoading(true);
    LocationService.getInstance().saveLocation(update).then(saved => {
      setLoading(false);
      onSaved(saved);
    }).catch(() => {
      setLoading(false);
      setErrorOpen(true);
    });
  };

  useImperativeHandle(ref, () => ({
    saveLocation() {
      if (!loading) {
        handleSubmit(onSubmit)();
      }
    }
  }));

  let loadingOverlay = null;
  if (loading) {
    loadingOverlay = <div className={classes.loading}><CircularProgress /></div>;
  }

  const {ref: nameRef, ...nameProps} = register('name', {
    required: 'Please enter a name'
  });

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      {loadingOverlay}
      <div className={classes.pane}>
        <TextField
          fullWidth
          inputRef={nameRef}
          {...nameProps}
          error={!!errors.name}
          helperText={!!errors.name ? errors.name.message : ''}
          variant="outlined"
          margin="normal"
          required
          id="name"
          label="Name"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Save
        </Button>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem saving the location
        </Alert>
      </Snackbar>
    </form>
  );
});

export default LocationForm;
