import {BaseForm} from '@raise.digital/management-ui';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const PlaceOrderForm = forwardRef(({order, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={order}
      type="order"
      fieldsComponent={props => <Fields {...props}/>}
      onSave={(update) => services.portal.placeOrder({
        ...update,
        location: Number.isFinite(update.location) ? update.location : parseInt(update.location)
      })}
      onSaved={onSaved}
    />
  );
});

export default PlaceOrderForm;
