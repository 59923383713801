import {Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {DataTable, dateTime, useDetail, useErrors} from '@raise.digital/management-ui';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../../../components/Services';
import routes from '../../../../../../routes';
import Wrapper from '../../Wrapper';
import Message from './Message';

export default function Messages() {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({customer: 'Sorry the customer could not be accessed'}), []));

  const {
    entity: customer,
    crumbs,
    loading
  } = useDetail(
    useMemo(() => services.customer.getCustomer, [services]),
    false,
    useCallback((customer) => [
      {
        title: customer.name,
        link: reverse(routes.admin.customers.detail, {id: customer.id})
      },
      {
        title: 'Portal',
        link: reverse(routes.admin.customers.portal.index, {id: customer.id})
      },
      {title: 'Messages'}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('customer', displayError), [toggleError])
  );

  return (
    <Wrapper title="Messages" loading={loading} customer={customer} crumbs={crumbs} errors={errors}>
      {customer ? (
        <>
          <Paper marginTop={2} component={Box}>
            <DataTable
              title=""
              options={{search: false, draggable: false}}
              columns={[
                {title: 'Name', field: 'name', sorting: false},
                {title: 'Email', field: 'email', sorting: false},
                {title: 'Phone', field: 'phone', sorting: false},
                {title: 'Date Sent', field: 'created', render: data => dateTime(data.created), sorting: false}
              ]}
              loadData={query => new Promise((resolve, reject) => {
                services.customer.getMessages(customer, query)
                  .then(response => {
                    resolve({
                      data: response.items, page: response.page - 1, totalCount: response.total
                    });
                  }).catch(() => {
                  reject();
                });
              })}
              tableProps={{
                detailPanel: [
                  {
                    render: rowData => <Message message={rowData}/>,
                    tooltip: 'View Message Details',
                  }
                ],
                onRowClick: (event, rowData, togglePanel) => togglePanel()
              }}
            />
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
}
