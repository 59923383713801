import {API} from './API';
import * as qs from 'qs';

export class LocationService {
  static getInstance() {
    if (!LocationService.instance) {
      LocationService.instance = new LocationService();
    }
    return LocationService.instance;
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableLocations(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getLocations(query, page, pageSize);
  }

  async getLocations(query, page, pageSize) {
    return (await API.getConnection())
      .get('locations', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getLocation(id) {
    return (await API.getConnection())
      .get(`locations/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {ILocation} location
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableProducts(location, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getProducts(location, query, page, pageSize);
  }

  async getProducts(location, query, page, pageSize) {
    return (await API.getConnection())
      .get(`locations/${location.id}/products`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveLocation(data) {
    let method = 'post';
    let url = 'locations';
    if (data.id) {
      method = 'put';
      url = `locations/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }
}
