import * as qs from 'qs';
import {API} from './API';

export class PortalOrderService {
  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableOrders(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getOrders(query, page, pageSize);
  }

  async getOrders(query, page, pageSize) {
    return (await API.getConnection())
      .get('portal-orders', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async export(query) {
    return (await API.getConnection())
      .get('portal-orders', {
        params: {export: 'true', filter: query},
        paramsSerializer: (params) => qs.stringify(params),
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Orders.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => API.handleError(error));
  }

  async getOrder(id) {
    return (await API.getConnection())
      .get(`portal-orders/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveOrder(data) {
    let method = 'post';
    let url = 'portal-orders';
    if (data.id) {
      method = 'put';
      url = `portal-orders/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async archiveOrder(order) {
    return (await API.getConnection())
      .delete(`portal-orders/${order.id}`)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async deliverOrder(order, name) {
    return (await API.getConnection())
      .post(`portal-orders/${order.id}/delivered`, {name})
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async deliverOrders(name, references) {
    return (await API.getConnection())
      .post('portal-orders/delivered', {name, references})
      .then(() => true)
      .catch((error) => API.handleError(error));
  }

  async dispatchOrders(ids) {
    return (await API.getConnection())
      .post('portal-orders/dispatch', ids)
      .then(() => true)
      .catch((error) => API.handleError(error));
  }
}
