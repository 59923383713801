import {API} from './API';
import * as qs from 'qs';

export class ProductService {
  static getInstance() {
    if (!ProductService.instance) {
      ProductService.instance = new ProductService();
    }
    return ProductService.instance;
  }

  /**
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableProducts(params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getProducts(query, page, pageSize);
  }

  async getProducts(query, page, pageSize) {
    return (await API.getConnection())
      .get('products', {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async getProduct(id) {
    return (await API.getConnection())
      .get(`products/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async qr(product) {
    return (await API.getConnection())
      .get(`products/${product.id}/qr`, {responseType: 'arraybuffer'})
      .then((response) => {
        return `data:image/png;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  async sample(product) {
    return (await API.getConnection())
      .get(`products/${product.id}/sample`, {responseType: 'arraybuffer'})
      .then((response) => {
        return `data:image/png;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  async uploadSample(product, file) {
    const body = new FormData();
    body.append('file', file);
    return (await API.getConnection())
      .post(`products/${product.id}/sample`, body, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return `data:image/jpeg;base64,${Buffer.from(
          response.data,
          'binary',
        ).toString('base64')}`;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {IProduct} product
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableActivity(product, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getActivity(product, query, page, pageSize);
  }

  async getActivity(product, query, page, pageSize) {
    return (await API.getConnection())
      .get(`products/${product.id}/activity`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {IProduct} product
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableOrders(product, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getOrders(product, query, page, pageSize);
  }

  async getOrders(product, query, page, pageSize) {
    return (await API.getConnection())
      .get(`products/${product.id}/orders`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  /**
   * @param {IProduct} product
   * @param {{}} params
   * @param {{}} additional
   * @returns {Promise<IPaginatedList>}
   */
  async getTableItems(product, params, additional = {}) {
    const [query, page, pageSize] = API.getTableParams(params, additional);
    return this.getItems(product, query, page, pageSize);
  }

  async getItems(product, query, page, pageSize) {
    return (await API.getConnection())
      .get(`products/${product.id}/items`, {
        params: {...query, ...{page, pageSize}},
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => API.handleError(error));
  }

  async saveProduct(data) {
    let method = 'post';
    let url = 'products';
    if (data.id) {
      method = 'put';
      url = `products/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async archiveProduct(product) {
    return (await API.getConnection())
      .delete(`products/${product.id}`)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async logActivity(version, quantity, comments, correction = false) {
    return (await API.getConnection())
      .post(`products/${version.productId}/versions/${version.id}/activity`, {
        quantity,
        comments,
        correction,
      })
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async newVersion(product, code) {
    return (await API.getConnection())
      .post(`products/${product.id}/versions`, {code})
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async obsoleteVersion(product, version) {
    return (await API.getConnection())
      .put(`products/${product.id}/versions/${version.id}`)
      .then((response) => response.data)
      .catch((error) => API.handleError(error));
  }

  async generateLabels(productIds) {
    return (await API.getConnection())
      .post('products/labels', productIds, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ProductLabels.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => API.handleError(error));
  }

  async export() {
    return (await API.getConnection())
      .get('products/export', {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Products.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => API.handleError(error));
  }
}
