import {BaseForm} from '@raise.digital/management-ui';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const PODForm = forwardRef(({item = null, pod, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  const [references, setReferences] = useState([]);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const handleSave = useCallback(({name}) => {
    if (item) {
      return services.portalOrder.deliverOrder(item, name);
    } else {
      return services.portalOrder.deliverOrders(name, references);
    }
  }, [services, item, references]);

  return (
    <BaseForm
      ref={formRef}
      entity={pod}
      type="pod"
      fieldsComponent={props => (
        <Fields references={references} onReferenceUpdate={setReferences} multiple={item == null} {...props}/>
      )}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default PODForm;
