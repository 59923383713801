import Box from '@material-ui/core/Box';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {Authenticated} from '@raise.digital/management-ui';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import zebraLogo from '../../assets/logo.svg';
import {ServiceContext} from '../../components/Services';
import routes from '../../routes';
import {logout} from '../../store/actions/auth';
import {setItems, setLocations} from '../../store/actions/customer';
import History from './screens/History';
import HistoryOrder from './screens/History/Order';
import Home from './screens/Home';
import Order from './screens/Order';
import Product from './screens/Product';
import Support from './screens/Support';

const Customer = (props) => {
  const services = useContext(ServiceContext);
  const user = useSelector(state => state['auth'].user);
  const config = useSelector(state => state['general'].config);
  const items = useSelector(state => state['customer'].items);
  const dispatch = useDispatch();

  const [nav, setNav] = useState([]);

  useEffect(() => {
    services.portal.getItems().then(retrieved => dispatch(setItems(retrieved)));
    services.portal.getLocations().then(retrieved => dispatch(setLocations(retrieved)));
  }, [services, dispatch]);

  useEffect(() => {
    setNav([
      {title: 'Home', route: routes.customer.home, icon: <HomeIcon/>},
      {
        title: (items.length > 0) ? `Place Order (${items.length})` : 'Place Order',
        route: routes.customer.order.index,
        icon: <LocalShippingIcon/>
      },
      {title: 'Orders', route: routes.customer.history.index, icon: <EventNoteIcon/>},
      {title: 'Support', route: routes.customer.support, icon: <HelpIcon/>}
    ]);
  }, [items]);

  return (
    <Authenticated
      title={config.name}
      logo={!!config.logo ? `data:image/svg+xml;base64,${Buffer.from(
        config.logo,
        'binary',
      ).toString('base64')}` : zebraLogo}
      userName={user ? user.firstName : ''}
      toolbarContent={
        <>
          <p>
            {user.location ? (
              <>
                <strong>{user.location.name}</strong>
                &nbsp;&nbsp;-&nbsp;&nbsp;
                <span>{user.location.address.replace('\n', ' ')}</span>
              </>
            ) : (
              <strong>{user.customer ? (`${user.customer.name} Admin`) : 'Zebra Admin'}</strong>
            )}
          </p>
          <Box flex={1}/>
        </>
      }
      nav={nav}
      onLogout={() => dispatch(logout())}
    >
      <Switch>
        <Route
          exact
          path={routes.customer.home}
          render={routeProps => <Home {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.customer.order.index}
          render={routeProps => <Order {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.customer.order.product}
          render={routeProps => <Product {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.customer.order.line}
          render={routeProps => <Product {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.customer.history.index}
          render={routeProps => <History {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.customer.history.detail}
          render={routeProps => <HistoryOrder {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.customer.support}
          render={routeProps => <Support {...props} {...routeProps} />}/>
      </Switch>
    </Authenticated>
  );
};

export default Customer;
