import {API} from './API';

export class SettingsService {
  static getInstance() {
    if (!SettingsService.instance) {
      SettingsService.instance = new SettingsService();
    }
    return SettingsService.instance;
  }

  async getSettings() {
    return (await API.getConnection())
      .get('settings')
      .then((response) => this.combineSettings(response.data))
      .catch((error) => API.handleError(error));
  }

  async saveSettings(settings) {
    return (await API.getConnection())
      .put(
        'settings',
        Object.keys(settings).map((key) => ({key, value: settings[key]})),
      )
      .then((response) => this.combineSettings(response.data))
      .catch((error) => API.handleError(error));
  }

  combineSettings(data) {
    const settings = {};
    data.forEach((setting) => (settings[setting.key] = setting.value));
    return settings;
  }
}
