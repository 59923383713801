import MomentUtils from '@date-io/moment';
import {Toolbar} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import {reverse} from 'named-urls';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import routes from '../../../routes';
import {CustomerService} from '../../../services/CustomerService';
import {LocationService} from '../../../services/LocationService';
import {OrderService} from '../../../services/OrderService';
import {setOrder} from '../../../store/actions/orders';
import {useOrder} from '../../../hooks';
import Breadcrumbs from '../components/Breadcrumbs';
import Spinner from '../components/Spinner';
import ProductField from '../forms/ProductField';
import RelationAutocomplete from '../forms/RelationAutocomplete';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  details: {
    marginTop: theme.spacing(1)
  },
  detailsColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsPane: {
    flexGrow: 1,
  },
  header: {
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 0`
  },
  form: {
    display: 'flex',
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  floating: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),

    '& > *': {
      marginLeft: theme.spacing(2)
    },

    '& :first-child': {
      marginLeft: 0
    },
  },
  floatingButtonIcon: {
    marginRight: theme.spacing(1),
  },

  orderItems: {
    display: 'flex',
    flexDirection: 'column',
  },

  newOrderItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },

  orderItem: {
    borderColor: theme.palette.background.default,
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`
  },

  orderItemTabsRow: {
    display: 'flex',
  },

  orderItemTabs: {
    flex: 3,
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },

  orderItemSpacer: {
    flex: 1,
  },

  orderItemContainer: {
    display: 'flex',
  },

  orderItemQuantity: {
    flex: 1,
  },

  orderItemDetails: {
    display: 'flex',
    flex: 3,
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },

  orderItemDetailsPane: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    maxWidth: '49%',

    '&:last-child': {
      marginRight: 0
    },
  },
}));

const ExistingOrderProduct = forwardRef(({item, index, onUpdate}, ref) => {
  const classes = useStyles();
  const [error, setError] = useState('');

  const validate = (field, value) => {
    if (field === 'productId') {
      setError('');
      if (!value) {
        setError('Please select a product');
        return false;
      }
    }
    return true;
  }

  useImperativeHandle(ref, () => ({
    validate() {
      return validate('productId', item.productId);
    }
  }));

  const handleChanges = changes => {
    Object.keys(changes).forEach(k => {
      validate(k, changes[k]);
    })
    onUpdate(changes);
  }

  return (
    <div className={classes.orderItemDetailsPane}>
      <ProductField
        id={`product-${index}`}
        required={true}
        label="Product"
        value={item.productId ? {value: item.productId, name: item.productName, packedIn: item.packedIn} : null}
        onChange={selected => {
          handleChanges({productId: selected.value, productName: selected.name, packedIn: selected.packedIn});
        }}
        error={error}
      />
      <TextField
        name="packedIn"
        value={item.packedIn}
        onChange={e => handleChanges({packedIn: e.target.value})}
        variant="outlined"
        margin="normal"
        id={`packedIn-${index}`}
        label="Packed In Quantity"
        type="number"
      />
    </div>
  );
});

const NewOrderProduct = forwardRef(({item, index, onUpdate}, ref) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});

  const formRelationOption = (relation) => ({name: relation.name, value: relation.id});

  const validate = (field, value, list) => {
    const errorList = {...list};
    switch (field) {
      case 'name':
        errorList.name = !!value ? '' : 'Please enter a name';
        break;
      case 'versionCode':
        errorList.versionCode = !!value ? '' : 'Please enter a code to identify the first version';
        break;
      case 'customerId':
        errorList.customerId = !!value ? '' : 'Please select a customer';
        break;
      default:
        errorList[field] = '';
        break;
    }
    return errorList
  };

  useImperativeHandle(ref, () => ({
    validate() {
      let errorList = {...errors};
      ['name', 'versionCode', 'customerId'].forEach(field => errorList = validate(field, item.product[field], errorList));
      setErrors(errorList);
      return Object.values(errorList).filter(e => !!e).length < 1;
    }
  }));

  const handleChanges = (changes) => {
    Object.keys(changes).forEach(k => {
      setErrors(validate(k, changes[k], {...errors}));
    });
    onUpdate(changes);
  }

  return (
    <>
      <div className={classes.orderItemDetailsPane}>
        <TextField
          fullWidth
          name="name"
          value={item.product.name}
          onChange={e => handleChanges({name: e.target.value})}
          error={!!errors.name}
          helperText={!!errors.name ? errors.name : ''}
          variant="outlined"
          margin="normal"
          required
          id={`name-${index}`}
          label="Name"
        />
        <TextField
          fullWidth
          name="versionCode"
          value={item.product.versionCode}
          onChange={e => handleChanges({versionCode: e.target.value})}
          error={!!errors.versionCode}
          helperText={!!errors.versionCode ? errors.versionCode : ''}
          variant="outlined"
          margin="normal"
          required
          id={`versionCode-${index}`}
          label="Version Code"
        />
        <RelationAutocomplete
          id={`customer-${index}`}
          required={true}
          label="Customer"
          loadOptions={query => (
            CustomerService.getInstance().getCustomers({filter: {search: query}}, 1, 50)
              .then(results => results.items.map(p => formRelationOption(p)))
              .catch(() => [])
          )}
          value={item.product.customerId ? {name: item.product.customerName, value: item.product.customerId} : null}
          onChange={selected => handleChanges({
            customerId: selected ? selected.value : null,
            customerName: selected ? selected.name : null
          })}
          error={errors.customerId}
        />
        <TextField
          name="buffer"
          value={item.product.buffer}
          onChange={e => handleChanges({buffer: e.target.value})}
          variant="outlined"
          margin="normal"
          id={`buffer-${index}`}
          label="Buffer"
          type="number"
        />
        <TextField
          name="packedIn"
          value={item.product.packedIn}
          onChange={e => handleChanges({packedIn: e.target.value})}
          variant="outlined"
          margin="normal"
          id={`packedIn-${index}`}
          label="Packed In Quantity"
          type="number"
        />
        <TextField
          name="qr"
          value={item.product.qr}
          onChange={e => handleChanges({qr: e.target.value})}
          variant="outlined"
          margin="normal"
          id={`qr-${index}`}
          label="QR Code ID"
        />
      </div>
      <div className={classes.orderItemDetailsPane}>
        <RelationAutocomplete
          id={`location-${index}`}
          label="Location"
          onQuery={query => handleChanges({locationQuery: query})}
          loadOptions={query => (
            LocationService.getInstance().getLocations({filter: {search: query}}, 1, 50)
              .then(results => results.items.map(p => formRelationOption(p)))
              .catch(() => [])
          )}
          value={item.product.locationId ? {name: item.product.locationName, value: item.product.locationId} : null}
          onChange={selected => handleChanges({
            locationId: selected ? selected.value : null,
            locationName: selected ? selected.name : null
          })}
        />
        <TextField
          name="description"
          value={item.product.description}
          onChange={e => handleChanges({description: e.target.value})}
          variant="outlined"
          multiline
          rows={6}
          margin="normal"
          id={`description-${index}`}
          label="Description"
        />
      </div>
    </>
  )
});

const OrderItem = forwardRef(({item, index, total, onUpdate, onDelete}, ref) => {
  const classes = useStyles();
  const [referenceError, setReferenceError] = useState('');
  const [quantityError, setQuantityError] = useState('');
  const [mode, setMode] = useState('existing');
  /** @type {({current: NewOrderProduct | ExistingOrderProduct})} */
  const productRef = useRef();

  const validate = (field, value) => {
    if (field === 'reference') {
      setReferenceError('');
      if (!value) {
        setReferenceError('Please enter a job number');
        return false;
      }
    } else if (field === 'quantity') {
      setQuantityError('');
      if (!value || value < 1) {
        setQuantityError('Please enter a quantity');
        return false;
      }
    }
    return true;
  }

  useImperativeHandle(ref, () => ({
    validate() {
      const [referenceValid, quantityValid, productValid] = [
        validate('reference', item.reference),
        validate('quantity', item.quantity),
        productRef.current.validate()
      ];
      return referenceValid && quantityValid && productValid;
    }
  }));

  const handleUpdates = changes => {
    const updated = {...item};
    Object.keys(changes).forEach(k => {
      const value = changes[k];
      if (k === 'quantity') {
        validate('quantity', value);
        updated.quantity = value;
      } else if (k === 'reference') {
        validate('reference', value);
        updated.reference = value;
      } else if (k === 'packedIn') {
        updated.packedIn = value;
      } else if (k === 'productId') {
        updated.productId = value;
      } else if (k === 'productName') {
        updated.productName = value;
      } else {
        updated.product[k] = value;
      }
    });
    onUpdate(index, updated);
  };

  return (
    <div className={classes.orderItem}>
      <div className={classes.orderItemTabsRow}>
        <div className={classes.orderItemSpacer}>
          {total > 1 ? <IconButton onClick={() => onDelete(index)}><DeleteIcon/></IconButton> : null}
        </div>
        <div className={classes.orderItemTabs}>
          <Tabs
            value={mode}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, selected) => setMode(selected)}
          >
            <Tab label="Existing Product" value="existing"/>
            <Tab label="New Product" value="new"/>
          </Tabs>
        </div>
      </div>
      <div className={classes.orderItemContainer}>
        <div className={classes.orderItemQuantity}>
          <TextField
            fullWidth
            name={`reference-${index}`}
            value={item.reference}
            onChange={e => handleUpdates({reference: e.target.value})}
            error={!!referenceError}
            helperText={!!referenceError ? referenceError : ''}
            variant="outlined"
            margin="normal"
            id="reference"
            label="Job Number"
            required
          />
          <TextField
            name={`quantity-${index}`}
            fullWidth
            value={item.quantity}
            onChange={e => handleUpdates({quantity: e.target.value})}
            error={!!quantityError}
            helperText={!!quantityError ? quantityError : ''}
            variant="outlined"
            margin="normal"
            id="quantity"
            label="Quantity"
            type="number"
          />
        </div>
        <div className={classes.orderItemDetails}>
          {mode === 'new' ?
            <NewOrderProduct
              ref={productRef}
              item={item}
              index={index}
              onUpdate={handleUpdates}/> :
            <ExistingOrderProduct
              ref={productRef}
              item={item}
              index={index}
              onUpdate={handleUpdates}/>
          }
        </div>
      </div>
    </div>
  );
});

export default function ManageOrder({history, match}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [due, setDue] = useState(null);
  const [dueError, setDueError] = useState('');
  const [notes, setNotes] = useState('');
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const order = useOrder(match, loading, setLoading, setErrorOpen);

  const itemRefs = useRef({});

  const newLine = useCallback((item = null) => {
    return {
      id: item ? item.id : 0,
      reference: item ? item.reference : '',
      quantity: item ? item.quantity : 1,
      productId: item ? item.productVersion.product.id : null,
      productName: item ? item.productVersion.product.name : null,
      packedIn: item ? item.packedIn : 0,
      product: {
        id: '',
        name: '',
        versionCode: '',
        customerId: null,
        customerName: null,
        buffer: 0,
        qr: '',
        locationId: null,
        locationName: null,
        locationQuery: '',
        description: ''
      }
    };
  }, []);

  useEffect(() => {
    const orderItems = [];
    if (order) {
      setDue(moment(order.due));
      for (let item of order.items) {
        orderItems.push(newLine(item));
      }
    }
    if (orderItems.length < 1) {
      setItems([newLine()]);
    } else {
      setItems(orderItems);
    }
  }, [order, newLine]);

  const allocateReference = (ref, index) => {
    const references = {...itemRefs.current};
    references[`${index}`] = ref;
    itemRefs.current = references;
  }

  const handleUpdate = (index, item) => {
    const updated = [...items];
    updated[index] = item;
    setItems(updated);
  };

  const handleDelete = index => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  }

  const validateDue = (value) => {
    setDueError('');
    if (!value) {
      setDueError('Please select a due date');
      return false;
    }
    return true;
  }

  const handleDue = value => {
    setDue(value);
    validateDue(value);
  }

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let valid = validateDue(due);

    Object.values(itemRefs.current).forEach(r => {
      if (r && !r.validate()) {
        valid = false;
      }
    });
    if (valid) {
      setSaving(true);
      const data = {
        id: order ? order.id : 0,
        due: moment(due).format('YYYY-MM-DD'),
        notes,
        items: items.map(i => {
          const item = {
            id: i.id,
            reference: i.reference,
            quantity: parseInt(i.quantity),
            productId: null,
            productName: '',
            packedIn: parseInt(i.packedIn),
            product: null
          };
          if (i.productId) {
            item.productId = i.productId;
          } else {
            item.product = {
              ...i.product,
              buffer: i.product.buffer ? parseInt(i.product.buffer) : 0,
              packedIn: parseInt(i.packedIn),
              customerId: i.product.customerId ? parseInt(i.product.customerId) : null,
              locationId: i.product.locationId ? parseInt(i.product.locationId) : null
            };
          }
          return item;
        })
      };
      OrderService.getInstance().saveOrder(data).then(created => {
        setSaving(false);
        dispatch(setOrder(null));
        history.push(reverse(`${routes.admin.orders.detail}`, {id: created.id}));
      }).catch(() => {
        setSaving(false);
      });
    }
  };

  let controls = null;
  if (loading) {
    controls = <CircularProgress/>;
  }

  let title;
  let crumbs = [{title: 'Orders', link: routes.admin.orders.index}];
  if (order) {
    title = 'Update Order';
    crumbs = [
      ...crumbs,
      {title: order.reference, link: reverse(routes.admin.orders.detail, {id: order.id})},
      {title: 'Update', link: ''}
    ];
  } else {
    title = 'Create New Order';
    crumbs.push({title: 'Create New Order', link: ''});
  }

  return (
    <div>
      <Breadcrumbs crumbs={crumbs}/>
      <Box paddingBottom={2}>
        <Paper>
          <Toolbar>
            <Typography className={classes.title} component="h2" variant="h5">{title}</Typography>
            {controls}
          </Toolbar>
        </Paper>
      </Box>
      <Box paddingBottom={3}>
        <Paper>
          <Grid container spacing={2}>
            <Grid className={classes.detailsColumn} item xs={12}>
              <div className={classes.detailsPane}>
                <Typography className={classes.header} variant="subtitle2">Core Details</Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <div className={classes.orderItemDetailsPane}>
                      <KeyboardDatePicker
                        fullWidth
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        margin="normal"
                        id="due"
                        label="Due Date"
                        value={due}
                        onChange={handleDue}
                        error={!!dueError}
                        helperText={!!dueError ? dueError : ''}
                        required
                      />
                    </div>
                    <div className={classes.orderItemDetailsPane}>
                      <TextField
                        name="notes"
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        variant="outlined"
                        multiline
                        rows={6}
                        margin="normal"
                        id="notes"
                        label="Notes"
                      />
                    </div>
                  </form>
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box paddingBottom={3}>
        <Paper>
          <Grid container spacing={2}>
            <Grid className={classes.detailsColumn} item xs={12}>
              <div className={classes.detailsPane}>
                <Typography className={classes.header} variant="subtitle2">Products</Typography>
              </div>
              <div className={classes.orderItems}>
                {items ? items.map((item, index) =>
                  <OrderItem
                    key={index}
                    ref={r => allocateReference(r, index)}
                    item={item}
                    index={index}
                    total={items.length}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}/>) : null}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {!loading ? <div className={classes.floating}>
        <Fab color="secondary" variant="extended" onClick={handleSubmit}>
          {saving ? <Spinner className={classes.floatingButtonIcon}/> :
            <SaveIcon className={classes.floatingButtonIcon}/>}
          Save
        </Fab>
      </div> : null}
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Sorry there was a problem saving the order
        </Alert>
      </Snackbar>
    </div>
  );
}
