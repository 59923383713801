import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'}
  },
  spinning: {
    animationName: '$spin',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));

export default function Spinner({className}) {
  const classes = useStyles();
  return <AutorenewIcon className={`${className} ${classes.spinning}`}/>;
}
