import React from 'react';
import {ProductService} from '../../../services/ProductService';
import RelationAutocomplete from './RelationAutocomplete';

const ProductField = (
  {
    id = 'product',
    required = false,
    label = 'Product',
    value,
    onChange,
    error,
    fieldRef = null
  }
) => {
  const formOption = (p) => ({
    value: p.id,
    name: p.customer ? `${p.name} (${p.customer.name})` : p.name,
    packedIn: p.packedIn
  });

  return (
    <RelationAutocomplete
      id={id}
      ref={fieldRef}
      required={required}
      label={label}
      loadOptions={query => (
        ProductService.getInstance().getProducts({filter: {search: query}}, 1, 50)
          .then(results => results.items.map(p => formOption(p)))
          .catch(() => [])
      )}
      value={value}
      onChange={onChange}
      error={error}
    />
  );
};

export default ProductField;
